import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { ModalContainerComponent } from 'angular-bootstrap-md';
import { Observable } from 'rxjs';
import { addFeePayments, deleteFeePayments, getFeePayments, updateFeePayments } from 'src/app/shared/actions/fee-payments/adminData.fee-payments.action';
import { AdminDataService } from 'src/app/shared/services/admin-services/admin-data.service';
import { AdminDataState } from 'src/app/shared/state/adminData.state';
import { AuthState } from 'src/app/shared/state/auth.state';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-fee-payments',
  templateUrl: './fee-payments.component.html',
  styleUrls: ['./fee-payments.component.scss']
})
export class FeePaymentsComponent implements OnInit {

 
  
  preloader = false;
  _loader = false;
  _submitButton = true;
  AdminUser =<any>{}
  AdminData =<any>{}
  userDetails = <any> {}
  FeePaymentsData = <any> {
    
  }
  newData = <any> {
    
  }
updateData = <any> {}
  
  DataToDelete = <any> {}
  
  @Select(AuthState.admin)
  User$!: Observable<any>;
  @Select(AdminDataState.adminData)
  Data$!: Observable<any>;
  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  validatingForm!: FormGroup;

  displayedColumns: string[] = ['id', 'tnx_id', 'feetype','amount','view'];
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('edit', { static: true }) edit!: ModalContainerComponent;
  @ViewChild('add', { static: true }) add!: ModalContainerComponent;
  constructor(
    private _store: Store,
    private adminService: AdminDataService,
    private http: HttpClient
  ) {
    this.User$.subscribe(res =>{
      this.AdminUser = res
      console.log(this.AdminUser)
      //  this. getAllFeePayments()
    })
   
  }

  
  ngOnInit(): void {
   
   
   
    // throw new Error('Method not implemented.');
    // this.validatingForm = new FormGroup({
    //   name: new FormControl(null, [Validators.required]),
    //   faculty_id: new FormControl(null, [Validators.required]),
      
    //    }/*, { updateOn: 'submit' }*/);
    


       
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
   setTimeout(()=>{
    if(this.AdminUser != null){
      this.Data$.subscribe(res =>{
        
        this.AdminData = res.fee_payments.response
        // console.log(this.AdminData.FeePayments.response)
        
       
  
  if(this.AdminData){
      this.dataSource = new MatTableDataSource(this.AdminData)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } 
      })
     }
   },0)
  }
  

  get input(){
    return this.validatingForm.controls;
  }


  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  FeePaymentsType = <any> [
      "male","female"
  ]

  getAllFeePayments(){
    this.preloader = true;
    // this._loader = true;
    const URL = 'api/payment'
    this._store
    .dispatch(new getFeePayments(this.AdminUser,URL)) 
  .subscribe(
  res=> {
  console.log(res.adminData.fee_payments.response)
  
  if(res.adminData.fee_payments.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.fee_payments.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.dataSource = new MatTableDataSource(res.adminData.fee_payments.response)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // this.dataSource = res.adminData.FeePayments.response
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  
  }


  

  editFeePayments(data:any){
    console.log(data)
    this.FeePaymentsData = data

  }


}
