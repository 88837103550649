<!-- <button mdbBtn color="default" class="waves-light float-right" mdbWavesEffect>create Student</button> -->
<mat-form-field>
  <div class="overlay" *ngIf="preloader">
    <span   class="spinner-border spinner-border-xl text-primary preloader" role="status" aria-hidden="true"></span>

</div>

    
    <mat-label>Search Table</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input style="width: 50%;">
  </mat-form-field>
  
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      
  
      <!-- S/N Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> S/N </th>
        <td mat-cell *matCellDef="let row; let i = index;"> {{i + 1}} </td>
      </ng-container>
  
     
  
      <!-- Code Column -->
      <ng-container matColumnDef="mat_no">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mat. No.</th>
        <td mat-cell *matCellDef="let row"> {{row.mat_no}} </td>
      </ng-container>

       <!-- Name Column -->
       <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
        <td mat-cell *matCellDef="let row"> {{row.first_name}} </td>
      </ng-container>
  
      <!-- Date Created Column -->
      <ng-container matColumnDef="mid_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Middle Name </th>
        <td mat-cell *matCellDef="let row" > {{row.mid_name}} </td>
      </ng-container>

       <!-- last name Column -->
       <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
        <td mat-cell *matCellDef="let row" > {{row.last_name}} </td>
      </ng-container>

      <!-- Status Column -->
      <!-- <ng-container matColumnDef="dept">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Addmission Status </th>
        <td mat-cell *matCellDef="let row" > {{row.adm}} </td>
      </ng-container> -->

       <!-- Edit Column -->
       <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
        <td mat-cell *matCellDef="let row" >  <mdb-icon (click)="editStudent(row);" fas icon="edit" class="prefix blue-text"></mdb-icon> </td>
      </ng-container>

       <!-- Delete Column -->
       <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete</th>
        <td mat-cell *matCellDef="let row" >  <mdb-icon (click)="confirmDelete(row)" fas icon="trash" class="prefix red-text"></mdb-icon> </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>

    </table>
  
    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>

 
  