<div class="container my-5 pb-5">
    <div class="row">
       

        <div class="col-md-12">
            <div class="card">
                <div class="d-flex flex-row justify-content-around orange lighten-2 py-4">
                    <a class="" >StudentData Bio-Data </a>
                    <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg" alt="" height="70" class="rounded-circle z-depth-0">
                    <a class="waves-light" mdbWavesEffect>Edit Profile</a>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4">Full Name</div>
                    <div class="col-4">  {{ StudentData.last_name }} {{ StudentData.mid_name }} {{ StudentData.first_name }} </div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4">Matriculation No</div>
                    <div class="col-4">{{ StudentData.mat_no }}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4">Department</div>
                    <div class="col-4">{{ StudentData?.dept?.name || "null" }}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4">Faculty</div>
                    <div class="col-4">{{ StudentData?.dept?.faculty.name }}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4">Hostel Allocation</div>
                    <div class="col-4">{{ StudentData.hostel || "null"}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4">Level</div>
                    <div class="col-4">{{ StudentData?.level?.name }}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4">Admitted Session</div>
                    <div class="col-4">{{ StudentData?.session?.start_year }}/{{ StudentData?.session?.end_year }}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4">Phone Number</div>
                    <div class="col-4">{{ StudentData.phone }}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4">State of Origin</div>
                    <div class="col-4">{{ StudentData.state }}</div>
                </div>
            </div>
        </div>
    </div>
</div>