


<div class="container my-5 pb-5">
    <div class="row">
        

        <div class="col-md-12">
            <div class="card">

                <form class="form-layout">
                    <div class="d-flex justify-content-center pr-5 pb-3">
                        <p class="title pb-3 pt-5">Hostel Application</p>
                    </div>

                    <mat-radio-group aria-label="Select an option" class="d-flex justify-content-around py-3">
                        <mat-radio-button value="1">Female</mat-radio-button>
                        <mat-radio-button value="2">Male</mat-radio-button>
                    </mat-radio-group>
        
                    <div class="space-around">        
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Hostel</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let hostel of hostels;" value="">{{ hostel }}</mat-option>
                            </mat-select>
                        </mat-form-field> 
                    </div>
        
                    <div class="space-around">        
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Block</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let block of blocks;" value="">{{ block }}</mat-option>                        
                            </mat-select>
                        </mat-form-field> 
                    </div>
        
                    <div class="space-around">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Room</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let room of rooms;" value="">{{ room }}</mat-option>                        
                            </mat-select>
                        </mat-form-field> 
                    </div>

                    <div class="space-around">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Available Space</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let space of spaces;" value="">{{ space }}</mat-option>                        
                            </mat-select>
                        </mat-form-field> 
                    </div>
        
                    <div class="py-4">
                        <button mat-raised-button color="primary" class="full-width py-2" (click)="submitHostelApplication();">Submit</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>


