import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicantGuard implements CanActivate {

  
  constructor(
    public _authService: AuthService,
    public _router: Router,
    public _store: Store
  ){ }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const token = !!this._store.selectSnapshot(
        state => state.auth.token
      );

      const role = this._store.selectSnapshot(
        state => state.auth.role 
      );
  
      if ((token && (role != "applicant"))) {
        this._router.navigate(['home'])
        return false;
      }
      else if(!token) {
        this._router.navigate(['home'])
        return false;
      }

  
      return true;
    }
  
}
