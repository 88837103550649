export interface UtmeStateModel {
    // utme: any;
    utmeUser: any | null;
    
  }
  
  export class getUtmeUser {
    static readonly type = '[Utme] getUtmeUser';
    constructor(public payload: { reg_no: string;}) {}
  }
  
  