import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {addHostel, addPayment, getFeeType, getHostel, getHostelAllocation, getPayments, getStudent, getStudentCourses, registerStudentCourses } from "../actions/studentData.action";

import {tap} from 'rxjs/operators';
import { StudentDataService } from '../services/student-services/student-data.service';
import { StudentDataStateModel } from '../models/studentData.model';



@State<StudentDataStateModel>({
    name: 'studentData',
    defaults: {
      studentDetails: null,  
      studentCourses: null,  
      level: null,  
      feetype: null,  
      studentPayments: null,  
      hostel: null,  
      hostelAllocation: null,  

     
    }
  })
  @Injectable()
  export class StudentDataState {
    @Selector()
    static levelList(state: StudentDataStateModel): string | null {
      return state.level;
    }
    @Selector()
    static StudentData(state: StudentDataStateModel) {
      return state.studentDetails
    }

    @Selector()
    static AllStudentData(state: StudentDataStateModel) {
      return state
    }

    @Selector()
    static feetype(state: StudentDataStateModel) {
      return state.feetype
    }

    @Selector()
    static paymentDetails(state: StudentDataStateModel) {
      return state.studentPayments
    }

    

    @Selector()
  static getFeeAmount(state: StudentDataStateModel) {
    return (type: any) => {
      return state.feetype.response[type]; 
      // return state.filter(s => s.indexOf('panda') > -1).filter(s => s.indexOf(type) > -1);
    };
  }

  @Selector()
  static getHostelCategory(state: StudentDataStateModel) {
    return (type: any) => {
      // return state.hostel.response[type]; 
      return state.hostel.response.filter((s:any) => s.type == type);
    };
  }

  @Selector()
  static getHostelNames(state: StudentDataStateModel) {
    return (type: any) => {
      // return state.hostel.response
      return state.hostel.response.filter((s:any) => s.name == type);
    };
  }

   
    
    constructor(
        private studentDataService: StudentDataService,
        private _router: Router) {}



// STUDENT ACTIONS
@Action(getStudent)
getStudent(ctx: StateContext<StudentDataStateModel>, action: getStudent) {
  return this.studentDataService.getAllStudentData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        studentDetails: result,
        
      });
    })
  );
}


// STUDENT COURSES
@Action(getStudentCourses)
getCourses(ctx: StateContext<StudentDataStateModel>, action: getStudentCourses) {
  return this.studentDataService.getStudentCourses(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        studentCourses: result,
        
      });
    })
  );
}

// REGISTERED COURSES
@Action(registerStudentCourses)
registerCourses(ctx: StateContext<StudentDataStateModel>, action: registerStudentCourses) {
  return this.studentDataService.registerStudentCourses(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        // studentCourses: result,
        
      });
    })
  );
}



// FEES-TYPE ACTIONS
@Action(getFeeType)
getFeeType(ctx: StateContext<StudentDataStateModel>, action: getFeeType) {
  return this.studentDataService.getAllStudentData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        feetype: result,
        
      });
    })
  );
}


// PAYMENT ACTIONS
@Action(getPayments)
getPayments(ctx: StateContext<StudentDataStateModel>, action: getPayments) {
  return this.studentDataService.getAllStudentData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        studentPayments: result,
        
      });
    })
  );
}
 

@Action(addPayment)
addPayment(ctx: StateContext<StudentDataStateModel>, action: addPayment) {
  return this.studentDataService.getAllStudentData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        studentPayments: result,
        
      });
    })
  );
}

// HOSTEL ACTIONS

@Action(getHostel)
getHostel(ctx: StateContext<StudentDataStateModel>, action: getHostel) {
  return this.studentDataService.getAllStudentData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        hostel: result,
        
      });
    })
  );
}

@Action(getHostelAllocation)
getHostelAllocation(ctx: StateContext<StudentDataStateModel>, action: getHostelAllocation) {
  return this.studentDataService.getAllStudentData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        hostelAllocation: result,
        
      });
    })
  );
}

@Action(addHostel)
addHostel(ctx: StateContext<StudentDataStateModel>, action: addHostel) {
  return this.studentDataService.getAllStudentData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        hostelAllocation: result,
        
      });
    })
  );
}



  }

