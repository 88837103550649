
<div class="container p-5">
    <mat-card class="card-layout">
<input type="file" class="file-input"
       (change)="onFileSelected1($event)" #fileUpload1 multiple>

<div class="file-upload">

   {{fileName1 || "Click here to upload SSCE."}}

    <button mat-mini-fab color="primary" class="upload-btn"
      (click)="fileUpload1.click()">
        <mat-icon>attach_file</mat-icon>
    </button>
</div>
    </mat-card>
</div>

<div class="container p-5">
    <mat-card class="card-layout">
<input type="file" class="file-input"
       (change)="onFileSelected2($event)" #fileUpload2 multiple>

<div class="file-upload">

   {{fileName2 || "Click here to upload UTME Result."}}

    <button mat-mini-fab color="primary" class="upload-btn"
      (click)="fileUpload2.click()">
        <mat-icon>attach_file</mat-icon>
    </button>
</div>
    </mat-card>
</div>

<div class="container p-5">
    <mat-card class="card-layout">
<input type="file" class="file-input"
       (change)="onFileSelected3($event)" #fileUpload3 multiple>

<div class="file-upload">

   {{fileName3 || "Click here to upload FSLC."}}

    <button mat-mini-fab color="primary" class="upload-btn"
      (click)="fileUpload3.click()">
        <mat-icon>attach_file</mat-icon>
    </button>
</div>



</mat-card>
</div>
<div>
    <button (click)="uploadCredentials()" color="primary" class="col-4 offset-4 p-2 mb-2 rounded btn-primary">
        
        <span *ngIf="_submitButton">Upload Certificates</span>
                    <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-white" role="status" aria-hidden="true"></span>
                <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-secondary" role="status" aria-hidden="true"></span>
                <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-success" role="status" aria-hidden="true"></span>
       
    </button>
</div>

<!-- <div class="space-around mb-5">        
    <div class="full-width">
        <label class="upload-label d-block">Upload Receipt</label>
        <input type="file" accept="image/*" class="file-input" id="file">
    </div> 
</div> -->