<h3 mat-dialog-title class="sub-title text-center">Make Hostel Fee Payment</h3>

<mat-divider></mat-divider>

<div mat-dialog-content>
    <div class="row">
        <p class="py-3 col-6 sub-title">Mat No: {{payloadData.mat_no}}</p>
        <p class="py-3 col-6">Session: {{payloadData.session}}</p>
        <p class="py-3 col-6">{{payloadData.email}}</p>
    </div>

    <p class="text-right">Pay <span class="amount-color">NGN {{payloadData.amount}}</span></p>

    <p class="text-center">Enter card Details</p>

    <mat-form-field>
        <mat-label>Enter Card Number</mat-label>
        <!-- <input matInput [(ngModel)]="data.animal"> -->
        <input matInput number appearance="outline" placeholder="0000 0000 0000 0000">
    </mat-form-field>

    <div>
        <mat-form-field class="pr-2">
            <mat-label>Expiry Date</mat-label>
            <!-- <input matInput [(ngModel)]="data.animal"> -->
            <input matInput number appearance="outline" placeholder="MM/YY">
        </mat-form-field>

        <mat-form-field>
            <mat-label>CVV</mat-label>
            <!-- <input matInput [(ngModel)]="data.animal"> -->
            <input matInput number appearance="outline" placeholder="123">
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" (click)="applyForHostel();">Pay NGN
        {{payloadData.amount}}</button>
    <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
    <!-- <button mat-button mat-raised-button cdkFocusInitial>Ok</button> -->
</div>