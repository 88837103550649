<header>
    <app-admin-dashboard-sidebar></app-admin-dashboard-sidebar>
    <app-admin-dashboard-navbar></app-admin-dashboard-navbar>
</header>


<main>
    <router-outlet></router-outlet>
</main>
<app-admin-dashboard-footer></app-admin-dashboard-footer>
