import { Router } from "@angular/router";
import { StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { addLevel, deleteLevel, getLevel, updateLevel } from "../../actions/level/adminData.level.action";
import { AdminDataStateModel } from "../../models/adminData.model";
import { AdminDataService } from "../../services/admin-services/admin-data.service";



export class Level {

    constructor(
        private adminDataService: AdminDataService,
        private _router: Router) {}

        
    getlevel(ctx: StateContext<AdminDataStateModel>, action: getLevel) {
        return this.adminDataService.getAllAdminData(action.payload,action.subUrl).pipe(
          tap((result: {
          //    name: string,
          //    date: string
           }) => {
            ctx.patchState({
              level: result,
              
            });
          })
        );
      }


      updatelevel(ctx: StateContext<AdminDataStateModel>, action: updateLevel) {
        return this.adminDataService.updateAdminData(action.payload,action.subUrl).pipe(
          tap((result: {
          //    name: string,
          //    date: string
           }) => {
            ctx.patchState({
              level: result,
              
            });
          })
        );
      }

      addlevel(ctx: StateContext<AdminDataStateModel>, action: addLevel) {
        return this.adminDataService.addAdminData(action.payload,action.subUrl).pipe(
          tap((result: {
          //    name: string,
          //    date: string
           }) => {
            ctx.patchState({
              level: result,
              
            });
          })
        );
      }

      deletelevel(ctx: StateContext<AdminDataStateModel>, action: deleteLevel) {
        return this.adminDataService.deleteAdminData(action.payload,action.subUrl).pipe(
          tap((result: {
          //    name: string,
          //    date: string
           }) => {
            ctx.patchState({
              level: result,
              
            });
          })
        );
      }

      
}