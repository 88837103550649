<!-- Sidebar -->
<div class="sidebar-fixed sidebar-fixed-md position-fixed">

    <a class="logo-wrapper-custom waves-effect">
        <img src="assets/images/logo/erewa-logo.png" class="img-fluid" alt="">
        <span class="logo-text">Tony G. Erewa</span>
    </a>

    <div class="list-group list-group-flush">

        <a *ngFor="let link of LinkItems" [routerLink]="'/dashboard/'+link.url" routerLinkActive="active"
            class="list-group-item list-group-item-action waves-effect">
            <mdb-icon fas icon="{{link.icon}}" class="mr-3"></mdb-icon>{{link.name}}

        </a>


    </div>

</div>
<!-- Sidebar -->

<!-- side bar main wrapper -->
<div class="side-nav-bar" [ngClass]="{ 'side-nav-bar-collapsed': !(showSideNav | async) }">

    <!-- overlay -->
    <div class="side-nav-bar-overlay"
        [ngStyle]="{ 'transition': 'background-color ' + duration + 's, visibility ' + duration + 's' }"
        [ngClass]="{ 'side-nav-bar-overlay-collapsed': !(showSideNav | async) }" (click)="onSidebarClose()"></div>

    <!-- side bar-->
    <div class="side-nav-bar-menu-container" [ngStyle]="getSideNavBarStyle((showSideNav | async) || false)">

        <!-- close button -->
        <div><span class="material-icons side-nav-bar-close" (click)="onSidebarClose()">clear</span></div>

        <!-- side bar content -->
        <div class="side-nav-bar-content-container">
            <!-- Sidebar -->
            <div class="sidebar-fixed position-fixed">

                <a class="logo-wrapper waves-effect">
                    <img src="assets/images/logo/erewa-logo.png" class="img-fluid" alt="">
                    <span>Tony G. Erewa College of Education</span>
                </a>

                <div class="list-group list-group-flush">

                    <a *ngFor="let link of LinkItems" [routerLink]="'/dashboard/'+link.url" routerLinkActive="active"
                        class="list-group-item list-group-item-action waves-effect">
                        <mdb-icon fas icon="{{link.icon}}" class="mr-3"></mdb-icon>{{link.name}}

                    </a>


                </div>

            </div>
            <!-- Sidebar -->

        </div>
    </div>
</div>