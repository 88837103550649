import { getStudent, getFeeType, getPayments, getHostel } from './../../shared/actions/studentData.action';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/shared/state/auth.state';
import { StudentDataState } from 'src/app/shared/state/studentData.state';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements AfterViewInit, OnInit {

  preloader = false;
  _loader = false;
  _submitButton = true;
  StudentUser =<any>{}
  StudentData =<any>{}

  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  @Select(AuthState.student)
  User$!: Observable<any>;

  @Select(StudentDataState.StudentData)
  Data$!: Observable<any>;

  @Select(AuthState.isAuthenticated)
  loggedin$!: Observable<any>;
  isAuth = <boolean> false

  constructor( private _store: Store,) {

    this.loggedin$.subscribe(res =>{
      // console.log(res)
      this.isAuth = res
      // console.log(this.isAuth)

        
      })
    this.User$.subscribe(res =>{
      this.StudentUser = res
      
      console.log(this.StudentUser)
      this.getProfile();
      
    })
   }

  ngOnInit(): void {

    // this.Data$.subscribe(res =>{
    //   this.StudentData = res.response
      
    //   console.log(this.StudentData)
      
      
    // })
    
    
  }

  ngAfterViewInit(){
    
  }

  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  getProfile(){
    if(this.isAuth == true){
    this.preloader = true;
    // this._loader = true;
    const STUDENT_URL = 'api/student/profile';
    const FEE_TYPE_URL = 'api/fees';
    const PAYMENTS_URL = 'api/payment/show';
    const HOSTEL_URL = 'api/hostel'
    
   
    this._store
    .dispatch([
      new getStudent(this.StudentUser,STUDENT_URL),
      new getFeeType(this.StudentUser,FEE_TYPE_URL),
      new getPayments(this.StudentUser,PAYMENTS_URL),
      new getHostel(this.StudentUser, HOSTEL_URL),
      
    ]) 
  .subscribe(
  res=> {
  // console.log(res.adminData.course_category.response)
  console.log(res[0].studentData.studentDetails.response)
  
  if(res[0].studentData.studentDetails.message){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res[0].studentData.studentDetails.message + ", Login again!"
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
   this.StudentData = res[0].studentData.studentDetails.response 
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `${err.statusText} : ${err.error.message}, Login Again!`
    // this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
}
  
  }
 


}
