// LEVEL CRUD
export class getLevel {
    static readonly type = '[adminData] get Level';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateLevel {
    static readonly type = '[adminData] update Level';
    constructor(
      public payload: { role: string; name: string; level_id:string},
      public subUrl: string
      ) {}
  }

  export class deleteLevel {
    static readonly type = '[adminData] delete Level';
    constructor(
      public payload: { role: string; level_id: string;},
      public subUrl: string
      ) {}
  }
  export class addLevel {
    static readonly type = '[adminData] add Level';
    constructor(
      public payload: { role: string; name: string;},
      public subUrl: string
      ) {}
  }
