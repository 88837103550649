import { ScreeningService } from '../../../shared/services/utme/screening.service';
import { ScreeningComponent } from '../../screening/screening.component';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UtmeState } from 'src/app/shared/state/utme.state';
import { AuthState } from 'src/app/shared/state/auth.state';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent implements OnInit {
  preloader = false;
  _loader = false;
  _submitButton = true;
   Applicant =<any>{}
  userPayload = <any> {
    
  }
  userDetails = <any> {}
  @Select(AuthState.applicant)
  User$!: Observable<any>;

  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  // utmeUser$: Observable<UtmeStateModel>
 
  form = new FormGroup({
    reg_no: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    first_name: new FormControl('', Validators.required),
    mid_name: new FormControl(),
    dob: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
    course: new FormControl('', Validators.required)
  });

  get last_name() {
    return this.form.get('last_name');
  }

  get email() {
    return this.form.get('email');
  }

  constructor(
    public getApplicantService: ScreeningService,
    private _router: Router, 
    public ApplyService: ScreeningService,
    // public dialogRef: MatDialogRef<ScreeningPaymentComponent>,
    private store: Store
    ) { }
      


  ngOnInit(): void {
    this.User$.subscribe(res =>{
      this.Applicant = res
      console.log(this.Applicant)
      this.getApplicant()
    })
    
  }

  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }


  courses = [
    'Accounting',
    'Architecture',
    'Agricultural Science',
    'Anthropology',
    'Banking and Finance',
    'Business Administration',
    'Chemistry',
    'Civil Engineering',
    'Computer Science',
    'Computer Engineering',
  ];

  processApplication(){
    this._router.navigateByUrl('upload-Olevel');
  }

  submitApplicationForm(){
    this._submitButton = false;
    this._loader = true;

    let form_input = {
      reg_no: this.form.get('reg_no')?.value,
      role: this.form.get('reg_no')?.value,
      last_name: this.form.get('last_name')?.value,
      first_name: this.form.get('first_name')?.value,
      mid_name: this.form.get('mid_name')?.value,
      dob: this.form.get('dob')?.value,
      country: this.form.get('country')?.value,
      state: this.form.get('state')?.value,
      city: this.form.get('city')?.value,
      email: this.form.get('email')?.value,
      phone: this.form.get('phone')?.value,
      course: this.form.get('course')?.value
    }
        // console.log(form_input.reg_no)
        form_input.role = this.Applicant.role
      this.ApplyService.updateApplicant(form_input)
      .subscribe(
        res=>{
          
          console.log(res)
          if(res.message == "Authentication Failed!"){
          
            this.alert.title = "Record Not Found!"
            this.alert.message = res.message
            this.alert.icon = "error"
    
            this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
            this._loader = false;
            this._submitButton = true;
           }else {
            // this.dialog.open(ScreeningPaymentComponent, {
            //   height: '400px',
            //   width: '450px',
            // }); 
            
            this._loader = false;
            this._submitButton = true;
            this._router.navigateByUrl('upload-Olevel'); 
          }
        },
        err => {console.log(err)
        
          console.log(err)
          this.alert.title = "Server Error!"
          this.alert.message = `There was an error processing this request`
          this.alert.icon = "error"
  
          this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
          this._loader = false;
            this._submitButton = true;
        }
      )
    
    // this.http.post('http://localhost:5000/api/application/create-applicant', form_input).subscribe(
    //   (response) => {
    //     if(response != null){
    //       Swal.fire('Application Successful', 'Your application was sent successfully!', 'success');
    //     }else {
    //       Swal.fire('Bad Request', 'User has already applied for screening.', 'error');
    //     }  
        
    //   },
    //   (error) => Swal.fire('Server Error', 'There was an error processing this request.', 'error')
    // );
  }

  getApplicant(){
    this.preloader = true;
    // this._loader = true;
    this.userPayload.user_id = this.Applicant.user_id
    this.userPayload.role = this.Applicant.role
this.getApplicantService.getApplicant(this.userPayload)
.subscribe(
  res=> {
console.log(res)

if(res.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.message
  this.alert.icon = "error"

  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
 }else {
  this.userDetails = res.response
  this.preloader = false;
 }

  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"

    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
)
    


  }

}

