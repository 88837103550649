import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hostel-clearance',
  templateUrl: './hostel-clearance.component.html',
  styleUrls: ['./hostel-clearance.component.scss']
})
export class HostelClearanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }
  
}
