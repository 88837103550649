import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

let Url = ""
if (isDevMode()) {
   Url = environment.apiUrl;
} else {
  console.log('💪 Production!');
}

@Injectable({
  providedIn: 'root'
})
export class AdminDataService {
  private APIUrl =  Url

  constructor(
    private http: HttpClient,
    private _router: Router,
    
    ) { }

  getAllAdminData(data: any, subUrl: any){
    
    return this.http.post<any>(this.APIUrl + subUrl,data)
  }

  updateAdminData(data: any, subUrl: any){
    return this.http.post<any>(this.APIUrl + subUrl,data)
  }

  addAdminData(data: any, subUrl: any){
    return this.http.post<any>(this.APIUrl + subUrl,data)
  }
  
  deleteAdminData(data: any, subUrl: any){
    return this.http.post<any>(this.APIUrl + subUrl,data)
  }

}
