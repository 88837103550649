<!-- Footer -->
<footer class="page-footer font-small blue darken-4 pt-4">

  <!-- Footer Links -->
  <div class="container text-center text-md-left">

    <!-- Grid row -->
    <div class="row">
      <!-- Grid column -->
      <div class="col-md-3 mx-auto">
        <!-- Links -->
        <img src="assets/images/logo/mu-logo.png" height="70" alt="">
        <h5 class="font-weight-bold text-uppercase mt-3 mb-4">Tony G. Erewa College of Education</h5>
      </div>
      <!-- Grid column -->
      <hr class="clearfix w-100 d-md-none">
      <!-- Grid column -->
      <div class="col-md-3 mx-auto">

        <!-- Links -->
        <h5 class="font-weight-bold text-uppercase mt-3 mb-4 orange-text">Features</h5>

        <ul class="list-unstyled">
          <li class="py-2">
            <a href="#!">Portal</a>
          </li>
          <li class="py-2">
            <a href="#!">Transcript</a>
          </li>
          <li class="py-2">
            <a href="#!">Admission</a>
          </li>
          <li class="py-2">
            <a href="#!">Application</a>
          </li>
        </ul>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none">

      <!-- Grid column -->
      <div class="col-md-3 mx-auto">

        <!-- Links -->
        <h5 class="font-weight-bold text-uppercase mt-3 mb-4 orange-text">Links</h5>

        <ul class="list-unstyled">
          <li class="">
            <a href="#!" class="">Portal</a>
          </li>
          <li class="py-2">
            <a href="#!" class="">Transcript</a>
          </li>
          <li class="py-2">
            <a href="#!" class="">Admission</a>
          </li>
          <li class="py-2">
            <a href="#!" class="">Application</a>
          </li>
        </ul>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none">

      <!-- Grid column -->
      <div class="col-md-3 mx-auto">

        <!-- Links -->
        <h5 class="font-weight-bold text-uppercase mt-3 mb-4 orange-text">Contact</h5>

        <ul class="list-unstyled">
          <li>
            <a href="#!">35, Ajose Adeogun Street, Utako, Abuja</a>
          </li>
        </ul>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2006 - {{test | date: 'yyyy'}}
    <a href="#"> Tony G. Erewa College of Education.</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->