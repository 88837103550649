<!--Main Navigation-->


 
<ul class="nav justify-content-end white lighten-4 top-nav mr-1" style="box-shadow: 0px 1px #888888; position: relative;">
    <li class="nav header-menu justify-content-start" style="position: absolute; left:0;">
        <span (click)="toggleSideNav()" class="menu-icon material-icons">menu</span>
    </li> 
   
    <!-- <li class="nav-item">
        <a class="nav-link active" [routerLink]="[ '/transcript']">E-Portal</a>
    </li>
     -->
    <li class="nav-item" dropdown>
        <a dropdownToggle mdbWavesEffect class="nav-link active" mdbWavesEffect>
            Hi, user <img src="assets/images/logo/mu-logo.png" style="width: 50px; height: 50px;" class="img-fluid" alt=""></a> 
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="[ '/dashboard-transcript/profile']">Profile</a>
            <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="[ '/dashboard-transcript/settings']">settings</a>
           
            <div class="divider dropdown-divider"></div>
            <a class="dropdown-item waves-light" mdbWavesEffect type="button" (click)="LogOut()">Logout</a>
        </div>
    </li>
    
</ul>




<!--Main Navigation-->