<!-- <app-layout></app-layout> -->

<!-- Jumbotron -->
<mdb-card
    style="background-image: url('assets/images/portal/portal-bg.png'); background-repeat: no-repeat; background-size: cover;">
    <div class="text-white text-center rgba-stylish-strong py-5 px-4">
        <div class="py-5">
            <!-- Content -->
            <p class="card-title my-4 py-2 welcome">Welcome To</p>
            <p class="card-body mb-4 pb-2 px-md-5 mx-md-5 mup"> Tony G. Erewa College of Education</p>
        </div>
    </div>
</mdb-card>
<!-- Jumbotron -->

<div class="container py-4">
    <div class="row py-4">
        <div class="col mx-2">
            <mdb-card class="p-2">
                <!--Card content-->
                <mdb-card-body>

                    <!--Title-->
                    <mdb-card-title>
                        <h4 class="title">2021/2022 Post UTME</h4>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text>
                        <p class="text-orange y-padding">2021/2022 Tony G. Erewa College of Education Post UTME
                            registration is on going
                        </p>
                    </mdb-card-text>

                    <a routerLink="/screening" mdbBtn color="link" class="text-typography">Click Here <span> to Apply
                        </span></a>
                </mdb-card-body>
            </mdb-card>
        </div>

        <!-- <div class="col mx-2">
                                    <mdb-card class="p-2"> -->
                <!--Card content-->
                <!-- <mdb-card-body> -->

                    <!--Title-->
                    <!-- <mdb-card-title>
                        <h4 class="title">2020/2021 Post Graduate</h4>
                    </mdb-card-title> -->

                    <!--Text-->
                    <!-- <mdb-card-text>
                        <p class="text-orange y-padding">2020/2021 Tony G. Erewa College of Education Post-graduate
                            registration is on
                            going</p>
                    </mdb-card-text>

                    <a href="#" mdbBtn color="link" class="text-typography">Click Here <span> to Apply </span></a>
                </mdb-card-body>
            </mdb-card>
        </div> -->
    </div>
</div>

<div class="container my-5">
    <mdb-card class="mb-4">
        <!--Card content-->
        <mdb-card-body>

            <!--Title-->
            <mdb-card-title>
                <h4 class="container enquiry-text-typography">Enquiries</h4>
            </mdb-card-title>

            <!--Text-->
            <mdb-card-text>
                <p class="container">All enquiries or problems will be addressed via the following online channels: </p>
                <p class="container">Email: enquiry@tonygerewacoe.com.ng</p>
            </mdb-card-text>
        </mdb-card-body>
    </mdb-card>
</div>

<app-portalfooter class="mt-5"></app-portalfooter>