export interface AuthStateModel {
    token: string | null;
    user_id: any | null;
    message: string | null;
    role: string | null;
    userinfo: any | null;
  }
  
  export class Login {
    static readonly type = '[Auth] Login';
    constructor(public payload: { email: string; password: string }) {}
  }

  export class applicantLogin {
    static readonly type = '[Auth] applicantLogin';
    constructor(public payload: { email: string; password: string }) {}
  }
  
  export class Logout {
    static readonly type = '[Auth] Logout';
  }