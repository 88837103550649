import { RegisteredCoursesComponent } from './dashboard/registered-courses/registered-courses.component';
import { FeePaymentsComponent } from './admin-dashboard/fee-payments/fee-payments.component';
import { FeesComponent } from './admin-dashboard/fees/fees.component';
import { HostelComponent } from './admin-dashboard/hostel/hostel.component';
import { StudentGuard } from './Middleware/student.guard';
import { CourseCategoryComponent } from './admin-dashboard/course-category/course-category.component';
import { SemesterComponent } from './admin-dashboard/semester/semester.component';
import { StaffLoginComponent } from './main-pages/staff-login/staff-login.component';
import { UtmeComponent } from './admin-dashboard/utme/utme.component';
import { StudentsComponent } from './admin-dashboard/students/students.component';
import { FacultyComponent } from './admin-dashboard/faculty/faculty.component';
import { SessionComponent } from './admin-dashboard/session/session.component';
import { DepartmentsComponent } from './admin-dashboard/departments/departments.component';
import { CoursesComponent } from './admin-dashboard/courses/courses.component';
import { LevelComponent } from './admin-dashboard/level/level.component';
import { ApplicantsComponent } from './admin-dashboard/applicants/applicants.component';
import { AdminDashboardComponent } from './layouts/admin-dashboard/admin-dashboard.component';
import { ApplicantGuard } from './Middleware/applicant.guard';
import { StatusComponent } from './application/prospective/status/status.component';
import { CheckerComponent } from './application/prospective/checker/checker.component';
import { CertUploadComponent } from './application/prospective/cert-upload/cert-upload.component';
import { ApplicantDetailsComponent } from './application/applicant-details/applicant-details.component';
import { CourseRegistrationComponent } from './dashboard/course-registration/course-registration.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { TestComponent } from './test/test.component';
import { LoginComponent } from './main-pages/login/login.component';

import { GuestGuard } from './Middleware/guest.guard';
import { AuthGuard } from './Middleware/auth.guard';
import { ClearanceApplicationComponent } from './dashboard/clearance/clearance-application/clearance-application.component';
import { ResultRequestComponent } from './dashboard/results/result-request/result-request.component';
import { HostelFormComponent } from './dashboard/hostel/hostel-form/hostel-form.component';
import { HostelApplicationComponent } from './dashboard/hostel/hostel-application/hostel-application.component';

import { HostelPreviewComponent } from './dashboard/hostel/hostel-preview/hostel-preview.component';
import { CoursesSelectedComponent } from './dashboard/courses-selected/courses-selected.component';
import { CourseFormComponent } from './dashboard/course-form/course-form.component';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './layouts/main/main.component';

import { HomepageComponent } from './main-pages/home/homepage.component';




import { HostelPrintoutComponent } from './dashboard/hostel/hostel-printout/hostel-printout.component';
import { DisplayResultsComponent } from './dashboard/results/display-results/display-results.component';
import { SchoolFeePaymentComponent } from './dashboard/school-fee-payment/school-fee-payment.component';
import { HostelFeePaymentComponent } from './dashboard/hostel/hostel-fee-payment/hostel-fee-payment.component';
import { ClearanceAvailableComponent } from './dashboard/clearance/clearance-available/clearance-available.component';
import { HostelClearanceComponent } from './dashboard/clearance/hostel-clearance/hostel-clearance.component';
import { ScreeningComponent } from './application/screening/screening.component';
import { ApplicationUploadComponent } from './application/prospective/application-upload/application-upload.component';
import { ApplicationFormComponent } from './application/prospective/application-form/application-form.component';
import { AdminGuard } from './Middleware/admin.guard';
import { AdminComponent } from './admin-dashboard/admin/admin.component';

const routes: Routes = [
  
  // MAIN PAGES
  {
    path: '',
    component: MainComponent,
    
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
      path: 'home',
      component: HomepageComponent,
      canActivate: [GuestGuard],
      },
      {
      
      path: 'login',
      component: LoginComponent,
      canActivate: [GuestGuard],
     },
     {
      
      path: 'staff-login',
      component: StaffLoginComponent,
      canActivate: [GuestGuard],
     },
     {
      
      path: 'test',
      component: TestComponent,
      canActivate: [GuestGuard],
     },
      {
      path: 'screening',
      component: ScreeningComponent,
      canActivate: [GuestGuard],
    },
    {
      path: 'test',
      component: TestComponent,
      canActivate: [GuestGuard],
    },
   
      {
        path: 'applicant-details',
        component: ApplicantDetailsComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'upload-credentials',
        component: CertUploadComponent,
        canActivate: [ApplicantGuard],
      },
      {
        path: 'checker',
        component: CheckerComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'status-page',
        component: StatusComponent,
        canActivate: [ApplicantGuard],
      },

      {
        path: 'application-form',
        component: ApplicationFormComponent,
        canActivate: [ApplicantGuard],
      },
      {
        path: 'upload-Olevel',
        component: ApplicationUploadComponent,
        canActivate: [ApplicantGuard],
      },

     
    
    
    
  ]
  },

  // STUDENT

  {
    path: 'dashboard',
    component: DashboardComponent,
     canActivate: [StudentGuard],
    children: [
      {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full',
      },
      
      {
      
      path: 'profile',
      component: ProfileComponent,
      
     },
     {
      
      path: 'course-registration',
      component: CourseRegistrationComponent,
      
     },
     {
      
      path: 'registered-courses',
      component: RegisteredCoursesComponent,
      
     },
     {
      path: 'course-registration-preview',
      component: CoursesSelectedComponent,
    },
    {
      path: 'select-courses',
      component: CourseFormComponent,
    },
    {
      path: 'school-fees-payment',
      component: SchoolFeePaymentComponent,
    },
   
    
    {
      path: 'hostel-application-form',
      component: HostelFormComponent,
    },
    {
      path: 'hostel-application',
      component: HostelApplicationComponent,
    },
    {
      path: 'hostel-fee-payment',
      component: HostelFeePaymentComponent,
    },
    {
      path: 'hostel-application-preview',
      component: HostelPreviewComponent,
    },
    {
      path: 'hostel-application-printout',
      component: HostelPrintoutComponent,
    },
    {
      path: 'check-results',
      component: ResultRequestComponent,
    },
    {
      path: 'display-results',
      component: DisplayResultsComponent,
    },
    {
      path: 'clearance-application',
      component: ClearanceApplicationComponent,
    },
    {
      path: 'clearance-available',
      component: ClearanceAvailableComponent,
    },
    {
      path: 'hostel-clearance',
      component: HostelClearanceComponent,
    }
    ]
  },

  // ADMIN

  {
    path: 'admin-dashboard',
    component: AdminDashboardComponent,
     canActivate: [AdminGuard],
    children: [
      {
        path: '',
        redirectTo: 'admin',
        pathMatch: 'full',
      },
      {
      
        path: 'admin',
        component: AdminComponent,
        
       },
       {
      
        path: 'applicants',
        component: ApplicantsComponent,
        
       },
       {
      
        path: 'level',
        component: LevelComponent,
        
       },
       {
      
        path: 'courses',
        component: CoursesComponent,
        
       },
       {
      
        path: 'departments',
        component: DepartmentsComponent,
        
       },
       {
      
        path: 'session',
        component: SessionComponent,
        
       },
       {
      
        path: 'faculties',
        component: FacultyComponent,
        
       },
       {
      
        path: 'students',
        component: StudentsComponent,
        
       },
       {
      
        path: 'utme',
        component: UtmeComponent,
        
       },
       {
      
        path: 'semester',
        component: SemesterComponent,
        
       },
       {
      
        path: 'course-category',
        component: CourseCategoryComponent,
        
       },
       {
      
        path: 'hostel',
        component: HostelComponent,
        
       },
       {
      
        path: 'fees',
        component: FeesComponent,
        
       },
       {
      
        path: 'payment-management',
        component: FeePaymentsComponent,
        
       },
    ]
  },
  
  
  
  
];

@NgModule({
  imports: [CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })],
  //   RouterModule.forRoot(routes)],
    // exports: [RouterModule]
    //     useHash: false
    // }),
  //   RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
