import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})


export class TestComponent implements OnInit {
  gallery =[
    {
      
    }
  ]
  gallerystate = false
  LoginData = {
    email:  <any> "",
    password: <any> ""  
    
   }
  constructor() { }

  ngOnInit(): void {
  }

  showstate(){
    this.gallerystate = true
    console.log(this.gallerystate)
  }



  data = [
    'red', 
    'red', 
    'red', 
    'yellow', 
    'yellow', 
    'blue',
    'blue',
    'yellow', 
    'yellow', 
    'blue',
    'blue',
    'blue',
    'blue'
  ]
  groupSize: Number = 2
  hot = <any>  [
    "a","b"
  ]
  result = <any> []
  result_total = <any> 0
  
 
    countOccurrences(arr:any){
     return  arr.reduce((prev:any, curr:any) => (prev[curr] = ++prev[curr] || 1, prev), {});
   }
//   console.log(this.hot);

  changedData(val:any){
    this.result = this.countOccurrences(this.data)

    for (let value in this.result) {
     let x =  Math.floor(Number(this.result[value]) / Number(val));
     let y = value
      console.log(y,x);
     this.result_total += x;
    //  this.result_total = Math.floor(x) + Math.floor(x);
  }
    console.log(this.result_total)
    // alert(this.countOccurrences(this.data))
//     alert(this.data)
  }

}
