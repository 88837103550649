// FEE-PAYMENTS CRUD
export class getFeePayments {
    static readonly type = '[adminData] get FeePayments';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateFeePayments {
    static readonly type = '[adminData] update FeePayments';
    constructor(
      public payload: { role: string; name: string; feePayments_id:string},
      public subUrl: string
      ) {}
  }

  export class deleteFeePayments {
    static readonly type = '[adminData] delete FeePayments';
    constructor(
      public payload: { role: string; feePayments_id: string;},
      public subUrl: string
      ) {}
  }
  export class addFeePayments {
    static readonly type = '[adminData] add FeePayments';
    constructor(
      public payload: { role: string; name: string;},
      public subUrl: string
      ) {}
  }
