
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { ModalContainerComponent } from 'angular-bootstrap-md';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminDataService } from 'src/app/shared/services/admin-services/admin-data.service';
import { AuthState } from 'src/app/shared/state/auth.state';
import { StudentDataState } from 'src/app/shared/state/studentData.state';
import { HostelPaymentComponent } from '../../hostel-payment/hostel-payment.component';

@Component({
  selector: 'app-hostel-application',
  templateUrl: './hostel-application.component.html',
  styleUrls: ['./hostel-application.component.scss']
})
export class HostelApplicationComponent implements AfterViewInit, OnInit {
  // hostelData = <any>[];
 
 preloader = false;
  _loader = false;
  _submitButton = true;
  StudentUser =<any>{}
  StudentData =<any>{}
  userDetails = <any> {}
  HostelData = <any> {
    
  }
  hostelNames = <any>[]
  hostelDetails = <any>[]
  newData = <any> {
    
  }
updateData = <any> {}
  
  DataToDelete = <any> {}
  
  @Select(AuthState.student)
  User$!: Observable<any>;
  @Select(StudentDataState.AllStudentData)
  Data$!: Observable<any>;


  
  selectedHostel$!:Observable<any>;
  loadPaymentDetails$!: Observable<any>;
  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  validatingForm!: FormGroup;

  displayedColumns: string[] = ['id', 'name','rooms','space','total-space','category'];
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('edit', { static: true }) edit!: ModalContainerComponent;
  @ViewChild('add', { static: true }) add!: ModalContainerComponent;
  constructor(
    private _store: Store,
    private adminService: AdminDataService,
    private http: HttpClient,
    public dialog: MatDialog,
  ) {
    this.User$.subscribe(res =>{
      this.StudentUser = res
      console.log(this.StudentUser)
      //  this. getAllHostel()
    })
   
  }

  
  ngOnInit(): void {
   
  
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
   setTimeout(()=>{
    if(this.StudentUser != null){
      this.Data$.subscribe(res =>{
        console.log(res)
        this.StudentData = res.hostel.response
        this.userDetails = res.studentDetails
        
        
        
       
  
  if(this.StudentData){
      this.dataSource = new MatTableDataSource(this.StudentData)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } 
      })
     }
   },0)
  }

  hostelType = ["male","female"];

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  


  getHostelCategoty(newVal:any){
    this.selectedHostel$ = this._store.select(StudentDataState.getHostelCategory)
    .pipe(map(filterFn => filterFn(newVal)));

this.selectedHostel$.subscribe(user => {
  // this.searchText = user.name;
  this.hostelNames= user;
  // this.newData.id = user._id;
console.log(user)
})


     
    
  }

  getHostelNames(newVal:any){
    this.selectedHostel$ = this._store.select(StudentDataState.getHostelNames)
    .pipe(map(filterFn => filterFn(newVal)));

this.selectedHostel$.subscribe(user => {
  // this.searchText = user.name;
  this.hostelDetails = user[0].rooms;
  this.newData.amount = user[0].amount;
  // this.newData.id = user._id;
console.log(user)
})


     
    
  }


  addRoom(){
    console.log(this.newData,this.userDetails.response)
    

  }

  apply(){
    console.log(this.newData,this.userDetails.response)
    this.add.hide()
    let data: any =  {
      mat_no : this.userDetails.response.mat_no,
      name: this.userDetails.response.first_name + " " + this.userDetails.response.last_name,
      session: this.userDetails.response.session.start_year + "/"+ this.userDetails.response.session.end_year,
      dept: this.userDetails.response.dept.name,
      level: this.userDetails.response.level.name,
      amount: this.newData.amount,
      hostel_name: this.newData.name,
      hostel_type: this.newData.hostel_type,
      room_number: parseInt(this.newData.room),
      email:  this.userDetails.response.email,
      // space: this.newData.space,
      
    }
    this.dialog.open(HostelPaymentComponent, {
        height: '450px',
        width: '450px',
        data: data
      }); 
  }

}
