
import { ScreeningPaymentComponent } from './../../application/screening-payment/screening-payment.component';
import { ScreeningComponent } from './../../application/screening/screening.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './../../main-pages/login/login.component';

import { AppModule } from './../../app.module';
import { MainComponent } from './main.component';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HomepageComponent } from 'src/app/main-pages/home/homepage.component';
import { MainHeaderComponent } from 'src/app/shared/component/main-header/main-header.component';
import { MainFooterComponent } from 'src/app/shared/component/main-footer/main-footer.component';
import { RouterModule } from '@angular/router';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { TestComponent } from 'src/app/test/test.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { ApplicantDetailsComponent } from 'src/app/application/applicant-details/applicant-details.component';
import { ApplicationUploadComponent } from 'src/app/application/prospective/application-upload/application-upload.component';
import { CertUploadComponent } from 'src/app/application/prospective/cert-upload/cert-upload.component';
import { ApplicationFormComponent } from 'src/app/application/prospective/application-form/application-form.component';
import { CheckerComponent } from 'src/app/application/prospective/checker/checker.component';
import { StatusComponent } from 'src/app/application/prospective/status/status.component';
import { StaffLoginComponent } from 'src/app/main-pages/staff-login/staff-login.component';



@NgModule({
  declarations: [
    MainComponent,
    HomepageComponent,
    LoginComponent,
    StaffLoginComponent,
    MainHeaderComponent,
    MainFooterComponent,
    TestComponent,
    ScreeningComponent,
    ScreeningPaymentComponent,
    ApplicationUploadComponent,
    ApplicationFormComponent,
    CertUploadComponent,
    ApplicantDetailsComponent,
    CheckerComponent,
    StatusComponent,
  
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(), 
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule,   
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class MainModule { }
