// FEES CRUD
export class getFees {
    static readonly type = '[adminData] get Fees';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateFees {
    static readonly type = '[adminData] update Fees';
    constructor(
      public payload: { role: string; name: string; fees_id:string},
      public subUrl: string
      ) {}
  }

  export class deleteFees {
    static readonly type = '[adminData] delete Fees';
    constructor(
      public payload: { role: string; fees_id: string;},
      public subUrl: string
      ) {}
  }
  export class addFees {
    static readonly type = '[adminData] add Fees';
    constructor(
      public payload: { role: string; name: string;},
      public subUrl: string
      ) {}
  }
