<button mdbBtn color="default" class="waves-light float-right" (click)="add.show()" mdbWavesEffect>create Course</button>
<mat-form-field>
  <div class="overlay" *ngIf="preloader">
    <span   class="spinner-border spinner-border-xl text-primary preloader" role="status" aria-hidden="true"></span>

</div>

    
    <mat-label>Search Table</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input style="width: 50%;">
  </mat-form-field>
  
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      
  
      <!-- S/N Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> S/N </th>
        <td mat-cell *matCellDef="let row; let i = index;"> {{i + 1}} </td>
      </ng-container>
  
     
  
      <!-- Code Column -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
        <td mat-cell *matCellDef="let row"> {{row.code}} </td>
      </ng-container>

       <!-- Name Column -->
       <ng-container matColumnDef="Title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let row"> {{row.title}} </td>
      </ng-container>
  
      <!-- Date Created Column -->
      <ng-container matColumnDef="Category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
        <td mat-cell *matCellDef="let row" > {{row.course_category.name}} </td>
      </ng-container>

       <!-- Unit Column -->
       <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Units </th>
        <td mat-cell *matCellDef="let row" > {{row.unit}} </td>
      </ng-container>

       <!-- Edit Column -->
       <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
        <td mat-cell *matCellDef="let row" > <mdb-icon (click)="edit.show(); editCourse(row);" fas icon="edit" class="prefix blue-text"></mdb-icon> </td>
      </ng-container>

       <!-- Delete Column -->
       <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete</th>
        <td mat-cell *matCellDef="let row" >  <mdb-icon (click)="confirmDelete(row)" fas icon="trash" class="prefix red-text"></mdb-icon> </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>

    </table>
  
    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>

  <div mdbModal #edit="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Edit Course</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="edit.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">

        <div class="md-form mb-5">
          <form [formGroup]="validatingForm">
            <div class="md-form md-outline form-lg"> 
              <input  [(ngModel)]="newData.title"  mdbValidation type="text" class="form-control py-2" formControlName="title" mdbInput>
              <label for="title">Course Name</label>
  
              <!-- <mdb-error *ngIf="input.name  && input.name.invalid && (input.name.dirty || input.name.touched)">
                <span *ngIf="input.name.errors!.required">name is required</span>
                <span *ngIf="input.name.errors!.name">This name address is not valid</span>
              </mdb-error>
              <mdb-success *ngIf="input.name  && input.name.valid && (input.name.dirty || input.name.touched)">Email valid</mdb-success> -->
              </div>
  
              <div class="md-form md-outline form-lg"> 
                <input  [(ngModel)]="newData.code"  mdbValidation type="text" class="form-control py-2" formControlName="code" mdbInput>
                <label for="code">Course Code</label>
    
                </div>
  
                <div class="md-form md-outline form-lg"> 
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Course Unit</mat-label>
                    <mat-select matNativeControl [(ngModel)]="newData.unit" formControlName="unit">
                        <mat-option  value="{{newData.unit}}">{{newData.unit}}</mat-option>                        
                        <mat-option *ngFor="let course_unit of course_units;" [value]="course_unit.value">{{course_unit.unit}}</mat-option>                        
                    </mat-select>
                </mat-form-field> 
      
                  </div>
  
                  <div class="md-form md-outline form-lg"> 
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-label>Course Category</mat-label>
                      <mat-select  [(ngModel)]="newData.course_category._id" formControlName="course_category_id">
                                            
                          <mat-option *ngFor="let cat of course_category;" value="{{cat._id}}">{{cat.name}}</mat-option>                        
                      </mat-select>
                  </mat-form-field> 
        
                    </div>
  
                   
                   
                   
                   
                   
                    <div class="md-form md-outline form-lg"> 
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Department</mat-label>
                        <mat-select  [(ngModel)]="newData.dept" formControlName="dept_id" >
                            <mat-option  value="">value</mat-option>                        
                            <mat-option *ngFor="let dept of departments;" value="{{dept._id}}">{{dept.name}}</mat-option>                        
                        </mat-select>
                    </mat-form-field> 
          
                      </div>
  
                      <div class="md-form md-outline form-lg"> 
                        <mat-form-field appearance="outline" class="full-width" >
                          <mat-label>Level</mat-label>
                          <mat-select  [(ngModel)]="newData.level" formControlName="level_id" >
                              <!-- <mat-option  value="">value</mat-option>                         -->
                              <mat-option *ngFor="let level of levels;" value="{{level._id}}">{{level.name}}</mat-option>                        
                          </mat-select>
                      </mat-form-field> 
            
                        </div>
  
                    
            </form>
        </div>
        <!-- <div  class="d-flex flex-row justify-content-center my-4">
            <div class="col-4">{{.name}}</div>
            
        </div> -->


      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="default" class="waves-light" (click)="updateCourse()" mdbWavesEffect>Update</button>
      </div>
      
    </div>
  </div>
</div>

<div mdbModal #add="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Add Course</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="add.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">

        <div class="md-form mb-5">
          <form [formGroup]="validatingForm">
          <div class="md-form md-outline form-lg"> 
            <input  [(ngModel)]="newData.title"  mdbValidation type="text" class="form-control py-2" formControlName="title" mdbInput>
            <label for="title">Course Name</label>

            <!-- <mdb-error *ngIf="input.name  && input.name.invalid && (input.name.dirty || input.name.touched)">
              <span *ngIf="input.name.errors!.required">name is required</span>
              <span *ngIf="input.name.errors!.name">This name address is not valid</span>
            </mdb-error>
            <mdb-success *ngIf="input.name  && input.name.valid && (input.name.dirty || input.name.touched)">Email valid</mdb-success> -->
            </div>

            <div class="md-form md-outline form-lg"> 
              <input  [(ngModel)]="newData.code"  mdbValidation type="text" class="form-control py-2" formControlName="code" mdbInput>
              <label for="code">Course Code</label>
  
              </div>

              <div class="md-form md-outline form-lg"> 
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Course Unit</mat-label>
                  <mat-select  [(ngModel)]="newData.unit" formControlName="unit">
                                           
                      <mat-option *ngFor="let course_unit of course_units;" value="{{course_unit.value}}">{{course_unit.unit}}</mat-option>                        
                  </mat-select>
              </mat-form-field> 
    
                </div>

                <div class="md-form md-outline form-lg"> 
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Course Category</mat-label>
                    <mat-select  [(ngModel)]="newData.course_category_id" formControlName="course_category_id">
                        <!-- <mat-option  value="">value</mat-option>                         -->
                        <mat-option *ngFor="let cat of course_category;" value="{{cat._id}}">{{cat.name}}</mat-option>                        
                    </mat-select>
                </mat-form-field> 
      
                  </div>

                  <div class="md-form md-outline form-lg"> 
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-label>Department</mat-label>
                      <mat-select  [(ngModel)]="newData.dept_id"  formControlName="dept_id">
                          <!-- <mat-option  value="">value</mat-option>                         -->
                          <mat-option *ngFor="let dept of departments;" value="{{dept._id}}">{{dept.name}}</mat-option>                        
                      </mat-select>
                  </mat-form-field> 
        
                    </div>

                    <div class="md-form md-outline form-lg"> 
                      <mat-form-field appearance="outline" class="full-width" >
                        <mat-label>Level</mat-label>
                        <mat-select  [(ngModel)]="newData.level_id" formControlName="level_id" >
                            <!-- <mat-option  value="">value</mat-option>                         -->
                            <mat-option *ngFor="let level of levels;" value="{{level._id}}">{{level.name}}</mat-option>                        
                        </mat-select>
                    </mat-form-field> 
          
                      </div>

                  
          </form>
        </div>
        <!-- <div  class="d-flex flex-row justify-content-center my-4">
            <div class="col-4">{{.name}}</div>
            
        </div> -->


      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="default" class="waves-light" (click)="addCourse()" mdbWavesEffect>add</button>
      </div>
      
    </div>
  </div>
</div>
  