import { ScreeningService } from './../../shared/services/utme/screening.service';
import { getUtmeUser, UtmeStateModel } from './../../shared/actions/utme.action';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { UtmeState } from 'src/app/shared/state/utme.state';
import { Select, Store } from '@ngxs/store';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-screening-payment',
  templateUrl: './screening-payment.component.html',
  styleUrls: ['./screening-payment.component.scss']
})
export class ScreeningPaymentComponent implements OnInit {
  Applicant =<any>{}
  ApplicantPayload =<any>{}
  @Select(UtmeState.utmeUser)
  utmeUser$!: Observable<any>;


  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }
  // utmeUser$: Observable<UtmeStateModel>
  constructor(
    private router: Router, 
    public dialogRef: MatDialogRef<ScreeningPaymentComponent>,
    private store: Store,
    private _screeningService: ScreeningService
    ) { 
      // this.utmeUser$ = this.store.select(state => state.utmeUser);
      // // console.log(this.utmeUser$)
      // this.utmeUser$.subscribe(res => console.log(res));
    }

  ngOnInit(): void {
    // console.log(this.utmeUser$)
    this.utmeUser$.subscribe(res =>{
      this.Applicant = res.response
      console.log(this.Applicant._id)
    })
  }


  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  proceedToApplication() {
    this.ApplicantPayload.reg_no = this.Applicant.reg_no
    this.ApplicantPayload.last_name = this.Applicant.last_name
    this.ApplicantPayload.first_name = this.Applicant.first_name
    this.ApplicantPayload.mid_name = this.Applicant.mid_name
    this.ApplicantPayload.dob = this.Applicant.dob
    this.ApplicantPayload.email = this.Applicant.email

    this._screeningService.applyApplicant(this.ApplicantPayload)
    .subscribe(res =>{
      
      if(res.message == "An instance already exist!"){
          
        this.alert.title = "Duplicate Error!"
        this.alert.message = res.message
        this.alert.icon = "error"
        
        this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
      
        
        }else {
          this.alert.title = "Application Successful!"
        this.alert.message = res.message
        this.alert.icon = "success"
        
        this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
     

        }
    },
    err=>{
      console.log(err)
    })
    console.log(this.ApplicantPayload)

    
    // this.router.navigateByUrl('/application-form'); 
    // this.dialogRef.close();   
  }
  
}
