


<div class="container my-5 pb-5">
    <div class="row">
        

        <div class="col-md-12">
            <div class="card">

                <form class="form-layout">
                    <div class="container">
                        <div class="d-flex justify-content-center pr-5 pb-2">
                            <p class="pb-3 pt-5"><span class="text-center instructions">Instructions:</span> Carefully select your outstanding courses. Check the button against any of your outstanding course(s) and click submit button to register selected courses.</p>
                        </div>
                    </div>

                    <div class="container px-4">
                        <div class="d-flex justify-content-between">
                            <p class="title">Engineering</p>
                            <p class="title">First Semester</p>
                            <p class="title">2020/2021</p>
                        </div>
                    </div>

                    <div class="mt-3 py-4 title text-center">Core Courses</div>

                    <table mat-table [dataSource]="coreCourses" class="mat-elevation-z2">

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <!-- <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox> -->
                            </th>
                            <td mat-cell *matCellDef="let core">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(core) : null"
                                    [checked]="selection.isSelected(core)"
                                    [aria-label]="checkboxLabel(core)">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                    
                        <!-- Position Column -->
                        <ng-container matColumnDef="title">
                          <th mat-header-cell *matHeaderCellDef> Course Title </th>
                          <td mat-cell *matCellDef="let core"> {{core.title}} </td>
                        </ng-container>
                    
                        <!-- Name Column -->
                        <ng-container matColumnDef="code">
                          <th mat-header-cell *matHeaderCellDef> Course Code </th>
                          <td mat-cell *matCellDef="let core"> {{core.code}} </td>
                        </ng-container>
                    
                        <!-- Weight Column -->
                        <ng-container matColumnDef="unit">
                          <th mat-header-cell *matHeaderCellDef> Unit </th>
                          <td mat-cell *matCellDef="let core"> {{core.unit}} </td>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let core; columns: displayedColumns;"
                            (click)="selection.toggle(core)">
                        </tr>
                    </table>

                    <div class="mt-3 py-4 title text-center">Restricted Courses</div>

                    <table mat-table [dataSource]="restrictedCourses" class="mat-elevation-z2">

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <!-- <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox> -->
                            </th>
                            <td mat-cell *matCellDef="let restricted">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(restricted) : null"
                                    [checked]="selection.isSelected(restricted)"
                                    [aria-label]="checkboxLabel(restricted)">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                    
                        <!-- Position Column -->
                        <ng-container matColumnDef="title">
                          <th mat-header-cell *matHeaderCellDef> Course Title </th>
                          <td mat-cell *matCellDef="let restricted"> {{restricted.title}} </td>
                        </ng-container>
                    
                        <!-- Name Column -->
                        <ng-container matColumnDef="code">
                          <th mat-header-cell *matHeaderCellDef> Course Code </th>
                          <td mat-cell *matCellDef="let restricted"> {{restricted.code}} </td>
                        </ng-container>
                    
                        <!-- Weight Column -->
                        <ng-container matColumnDef="unit">
                          <th mat-header-cell *matHeaderCellDef> Unit </th>
                          <td mat-cell *matCellDef="let restricted"> {{restricted.unit}} </td>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            (click)="selection.toggle(row)">
                        </tr>
                    </table>

                    <div class="mt-3 py-4 title text-center">Elective Courses</div>

                    <table mat-table [dataSource]="electiveCourses" class="mat-elevation-z2">

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <!-- <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox> -->
                            </th>
                            <td mat-cell *matCellDef="let elective">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(elective) : null"
                                    [checked]="selection.isSelected(elective)"
                                    [aria-label]="checkboxLabel(elective)">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                    
                        <!-- Position Column -->
                        <ng-container matColumnDef="title">
                          <th mat-header-cell *matHeaderCellDef> Course Title </th>
                          <td mat-cell *matCellDef="let elective"> {{elective.title}} </td>
                        </ng-container>
                    
                        <!-- Name Column -->
                        <ng-container matColumnDef="code">
                          <th mat-header-cell *matHeaderCellDef> Course Code </th>
                          <td mat-cell *matCellDef="let elective"> {{elective.code}} </td>
                        </ng-container>
                    
                        <!-- Weight Column -->
                        <ng-container matColumnDef="unit">
                          <th mat-header-cell *matHeaderCellDef> Unit </th>
                          <td mat-cell *matCellDef="let elective"> {{elective.unit}} </td>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            (click)="selection.toggle(row)">
                        </tr>
                    </table>

                    <div class="d-flex justify-content-end px-5 py-3">
                        <p>Total Unit: {{ totalUnit }} </p>
                    </div>
                    
        
                    <div class="p-4 d-flex justify-content-end">
                        <button mat-raised-button color="primary" class="py-2 px-4" (click)="register();" >Proceed</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>


