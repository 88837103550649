



<div class="container my-5 pb-5">
    <div class="row">
        

        <div class="col-md-12">
            <div class="card">

                <form class="form-layout">
                    <div class="d-flex justify-content-center pr-5 pb-3">
                        <p class="title pb-3 pt-5">Hostel Fee Payment</p>
                    </div>

                    <div class="d-flex justify-content-between pb-3">
                        <p class="">Fee Type: Hostel Fee</p>
                        <p class="">Department: Civil Engineering</p>
                        <!-- <p class="">Session: 2019/2020</p> -->
                        <p class="">Amount: NGN 10000</p>
                    </div>

                    <mat-card class="inner-card-layout mb-5">
                        <div class="d-flex justify-content-between px-5">
                            <p class="py-3 sub-title">Session: 2019/2020</p>
                            <p class="py-3">mail@mail.com</p>
                        </div>
                    
                        <p class="text-right pr-5">Pay <span class="amount-color">NGN 10000</span></p>
                    
                        <p class="text-center">Enter card Details</p>
                    
                        <mat-form-field>
                            <mat-label>Enter Card Number</mat-label>
                            <!-- <input matInput [(ngModel)]="data.animal"> -->
                            <input matInput number appearance="outline" placeholder="0000 0000 0000 0000">
                        </mat-form-field>
                    
                        <div class="d-flex justify-content-between">
                            <mat-form-field class="">
                                <mat-label>Expiry Date</mat-label>
                                <!-- <input matInput [(ngModel)]="data.animal"> -->
                                <input matInput number appearance="outline" placeholder="MM/YY">
                            </mat-form-field>
                    
                            <mat-form-field>
                                <mat-label>CVV</mat-label>
                                <!-- <input matInput [(ngModel)]="data.animal"> -->
                                <input matInput number appearance="outline" placeholder="123">
                            </mat-form-field> 
                        </div> 
                        
                        <div class="py-4">
                            <button mat-raised-button color="primary" class="full-width py-2" (click)="processPayment();">Pay NGN {{ amount }}</button>
                        </div>
                    </mat-card>
        
                </form>

            </div>
        </div>
    </div>
</div>



