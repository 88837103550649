import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
let Url = ""
if (isDevMode()) {
   Url = environment.apiUrl;
} else {
  console.log('💪 Production!');
}
@Injectable({
  providedIn: 'root'
})
export class ScreeningService {

  private APIUrl =  Url
     
  constructor(
    private http: HttpClient,
    private _router: Router,
    
    ) { }

  checkStatus(data: any){
    return this.http.post<any>(this.APIUrl  + 'api/utme/show', data)
  }

  getApplicant(data: any){
    return this.http.post<any>(this.APIUrl  + 'api/applicant/show', data)
  }

  applyApplicant(data: any){
    return this.http.post<any>(this.APIUrl  + 'api/applicant/store', data)
  }

  updateApplicant(data: any){
    return this.http.post<any>(this.APIUrl  + 'api/applicant/update', data)
  }
  uploadResult(data: any){
    return this.http.post<any>(this.APIUrl  + 'api/applicant/store-results', data)
  }
  uploadCert(data: any){
    return this.http.post<any>(this.APIUrl  + 'api/applicant/files-upload', data)
  }
}
