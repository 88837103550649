<div class="row mt-3 ml-3 mr-3">
    <div class="col-lg-3 col-md-6 mb-4">
      <div class="card">
        <div class="card-body">
          <p class="text-uppercase small mb-2">
            <strong>Faculties</strong>
          </p>
          <h5 class="mb-0">
            <strong>{{AdminData.faculty.response.length}}</strong>
            
          </h5>

          <hr />

          <p class="text-uppercase text-muted small mb-2">
            <mat-icon aria-hidden="false" aria-label="Example home icon">account_balance</mat-icon>

          </p>
          <!-- <h5 class="text-muted mb-0">11 467</h5> -->
        </div>
      </div>
      <!-- Card -->
    </div>

    <div class="col-lg-3 col-md-6 mb-4">
        <div class="card">
          <div class="card-body">
            <p class="text-uppercase small mb-2">
              <strong>Levels</strong>
            </p>
            <h5 class="mb-0">
              <strong>{{AdminData.level.response.length}}</strong>
              
            </h5>
  
            <hr />
  
            <p class="text-uppercase text-muted small mb-2">
              <mat-icon aria-hidden="false" aria-label="Example home icon">trending_up</mat-icon>
  
            </p>
           
          </div>
        </div>
        <!-- Card -->
      </div>

      <div class="col-lg-3 col-md-6 mb-4">
        <div class="card">
          <div class="card-body">
            <p class="text-uppercase small mb-2">
              <strong>Semester</strong>
            </p>
            <h5 class="mb-0">
              <strong>{{AdminData.semester.response.length}}</strong>
             
            </h5>
  
            <hr />
  
            <p class="text-uppercase text-muted small mb-2">
              <mat-icon aria-hidden="false" aria-label="Example home icon">model_training</mat-icon>
            </p>
           
          </div>
        </div>
        <!-- Card -->
      </div>

      <div class="col-lg-3 col-md-6 mb-4">
        <div class="card">
          <div class="card-body">
            <p class="text-uppercase small mb-2">
              <strong>Course Categories</strong>
            </p>
            <h5 class="mb-0">
              <strong>{{AdminData.course_category.response.length}}</strong>
              
            </h5>
  
            <hr />
  
            <p class="text-uppercase text-muted small mb-2">
              <mat-icon aria-hidden="false" aria-label="Example home icon">subject</mat-icon>
            </p>
            
          </div>
        </div>
        <!-- Card -->
      </div>

      <div class="col-lg-3 col-md-6 mb-4">
        <div class="card">
          <div class="card-body">
            <p class="text-uppercase small mb-2">
              <strong>STUDENTS</strong>
            </p>
            <h5 class="mb-0">
              <strong>14 567</strong>
              <small class="text-success ms-2">
                <i class="fas fa-arrow-up fa-sm pe-1"></i>13,48%</small>
            </h5>
  
            <hr />
  
            <p class="text-uppercase text-muted small mb-2">
              Previous period
            </p>
            <h5 class="text-muted mb-0">11 467</h5>
          </div>
        </div>
        <!-- Card -->
      </div>

      <div class="col-lg-3 col-md-6 mb-4">
        <div class="card">
          <div class="card-body">
            <p class="text-uppercase small mb-2">
              <strong>STUDENTS</strong>
            </p>
            <h5 class="mb-0">
              <strong>14 567</strong>
              <small class="text-success ms-2">
                <i class="fas fa-arrow-up fa-sm pe-1"></i>13,48%</small>
            </h5>
  
            <hr />
  
            <p class="text-uppercase text-muted small mb-2">
              Previous period
            </p>
            <h5 class="text-muted mb-0">11 467</h5>
          </div>
        </div>
        <!-- Card -->
      </div>

      <div class="col-lg-3 col-md-6 mb-4">
        <div class="card">
          <div class="card-body">
            <p class="text-uppercase small mb-2">
              <strong>STUDENTS</strong>
            </p>
            <h5 class="mb-0">
              <strong>14 567</strong>
              <small class="text-success ms-2">
                <i class="fas fa-arrow-up fa-sm pe-1"></i>13,48%</small>
            </h5>
  
            <hr />
  
            <p class="text-uppercase text-muted small mb-2">
              Previous period
            </p>
            <h5 class="text-muted mb-0">11 467</h5>
          </div>
        </div>
        <!-- Card -->
      </div>

      <div class="col-lg-3 col-md-6 mb-4">
        <div class="card">
          <div class="card-body">
            <p class="text-uppercase small mb-2">
              <strong>STUDENTS</strong>
            </p>
            <h5 class="mb-0">
              <strong>14 567</strong>
              <small class="text-success ms-2">
                <i class="fas fa-arrow-up fa-sm pe-1"></i>13,48%</small>
            </h5>
  
            <hr />
  
            <p class="text-uppercase text-muted small mb-2">
              Previous period
            </p>
            <h5 class="text-muted mb-0">11 467</h5>
          </div>
        </div>
        <!-- Card -->
      </div>
</div>