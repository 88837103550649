


<div class="container my-5 pb-5">
    <div class="row">
      
        <div class="col-md-9">
            <div class="card">

                <form class="form-layout">
                    
                    <div class="d-flex flex-row justify-content-center my-4">
                        <div class="col-4">Full Name</div>
                        <div class="col-4">Emmanuel Jude Ayomide</div>
                    </div>

                    <div class="d-flex flex-row justify-content-center my-4">
                        <div class="col-4">Department</div>
                        <div class="col-4">Chemistry</div>
                    </div>
                    
                    <div class="container px-4">
                        <div class="d-flex flex-row justify-content-between my-4">
                            <div class="col-4">First Semester</div>
                            <div class="col-4">2019/2020 Session</div>
                        </div>
                    </div>
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
                    
                        <!-- code Column -->
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef> Course Code </th>
                            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                        </ng-container>
                        
                        <!-- title Column -->
                        <ng-container matColumnDef="title">
                          <th mat-header-cell *matHeaderCellDef> Course Title </th>
                          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
                        </ng-container>
                    
                        <!-- unit Column -->
                        <ng-container matColumnDef="unit">
                          <th mat-header-cell *matHeaderCellDef> Unit </th>
                          <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
                        </ng-container>

                        <!-- exam Column -->
                        <ng-container matColumnDef="exam">
                            <th mat-header-cell *matHeaderCellDef> Exam </th>
                            <td mat-cell *matCellDef="let element"> {{element.exam}} </td>
                        </ng-container>

                        <!-- grade Column -->
                        <ng-container matColumnDef="grade">
                            <th mat-header-cell *matHeaderCellDef> Grade </th>
                            <td mat-cell *matCellDef="let element"> {{element.grade}} </td>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <div class="d-flex justify-content-end px-5 py-3">
                        <p>Total Unit: total  </p>
                    </div>
                    
        
                    <div class="p-4 d-flex justify-content-end">
                        <button mat-raised-button color="gray" class="py-2 px-3" target="__blank">
                            <mat-icon aria-hidden="false" aria-label="print icon">print</mat-icon> <span class="px-1"></span> Print
                        </button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>



