<button mdbBtn color="default" class="waves-light float-right" (click)="add.show()" mdbWavesEffect>create Fees</button>
<mat-form-field>
  <div class="overlay" *ngIf="preloader">
    <span   class="spinner-border spinner-border-xl text-primary preloader" role="status" aria-hidden="true"></span>

</div>

    
    <mat-label>Search Table</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input style="width: 50%;">
  </mat-form-field>
  
  <div class="mat-elevation-z8" style="overflow-x:auto;">
    <table mat-table [dataSource]="dataSource" matSort>
      
  
      <!-- S/N Column -->
      <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header> S/N </th>
        <td mat-cell *matCellDef="let row; let i = index;"> {{i + 1}} </td>
      </ng-container>
  
     
  
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>

       <!-- Block Column -->
       <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount</th>
        
        <td mat-cell *matCellDef="let row;"> {{row?.amount |currency:"NGN":"symbol"}} </td>
      </ng-container>
  
       <!-- Edit Column -->
       <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
        <td mat-cell *matCellDef="let row" > <mdb-icon (click)="edit.show(); editFees(row);" fas icon="edit" class="prefix blue-text"></mdb-icon> </td>
      </ng-container>

       <!-- Delete Column -->
       <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete</th>
        <td mat-cell *matCellDef="let row" >  <mdb-icon (click)="confirmDelete(row)" fas icon="trash" class="prefix red-text"></mdb-icon> </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>

    </table>
  
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>

  <div mdbModal #edit="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Edit Fees</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="edit.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">

        <div class="md-form mb-5">
            <form >
                <div class="md-form md-outline form-lg">
                  <input  [(ngModel)]="FeesData.name"  mdbValidation type="text" class="form-control py-2" name="name" mdbInput>
                  <label for="name">Fees Name</label>
      
                  <!-- <mdb-error *ngIf="input.name  && input.name.invalid && (input.name.dirty || input.name.touched)">
                    <span *ngIf="input.name.errors!.required">name is required</span>
                    <span *ngIf="input.name.errors!.name">This name address is not valid</span>
                  </mdb-error>
                  <mdb-success *ngIf="input.name  && input.name.valid && (input.name.dirty || input.name.touched)">Email valid</mdb-success> -->
                  </div>

                
      
                  <div class="md-form md-outline form-lg">
                      <input [(ngModel)]="FeesData.amount" mdbValidation type="number" class="form-control py-2" name="amount" mdbInput>
                      <label for="name">Fees Amount</label>
                  
                  </div>
                  
                </form>
      
        </div>
        <!-- <div  class="d-flex flex-row justify-content-center my-4">
            <div class="col-4">{{.name}}</div>
            
        </div> -->


      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="default" class="waves-light" (click)="updateFees()" mdbWavesEffect>Update</button>
      </div>
      
    </div>
  </div>
</div>

<div mdbModal #add="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Add Fees</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="add.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">

        <div class="md-form mb-5">
          <form >
          <div class="md-form md-outline form-lg">
            <input  [(ngModel)]="newData.fee_name"  mdbValidation type="text" class="form-control py-2" name="name" mdbInput>
            <label for="name">Fees Name</label>

            <!-- <mdb-error *ngIf="input.name  && input.name.invalid && (input.name.dirty || input.name.touched)">
              <span *ngIf="input.name.errors!.required">name is required</span>
              <span *ngIf="input.name.errors!.name">This name address is not valid</span>
            </mdb-error>
            <mdb-success *ngIf="input.name  && input.name.valid && (input.name.dirty || input.name.touched)">Email valid</mdb-success> -->
            </div>

            <div class="md-form md-outline form-lg">
                <input [(ngModel)]="newData.fee_amount" mdbValidation type="number" class="form-control py-2" name="amount" mdbInput>
                <label for="name">Fees Amount</label>
            
            </div>
           
            
          </form>
        </div>


      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="default" class="waves-light" (click)="addFees()" mdbWavesEffect>add</button>
      </div>
      
    </div>
  </div>
</div>
  