import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { ModalContainerComponent } from 'angular-bootstrap-md';
import { Observable } from 'rxjs';
import { addFees, deleteFees, getFees, updateFees } from 'src/app/shared/actions/fees/adminData.fees.action';
import { AdminDataService } from 'src/app/shared/services/admin-services/admin-data.service';
import { AdminDataState } from 'src/app/shared/state/adminData.state';
import { AuthState } from 'src/app/shared/state/auth.state';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.scss']
})
export class FeesComponent implements OnInit {

  
  
  preloader = false;
  _loader = false;
  _submitButton = true;
  AdminUser =<any>{}
  AdminData =<any>{}
  userDetails = <any> {}
  FeesData = <any> {
    
  }
  newData = <any> {
    
  }
updateData = <any> {}
  
  DataToDelete = <any> {}
  
  @Select(AuthState.admin)
  User$!: Observable<any>;
  @Select(AdminDataState.adminData)
  Data$!: Observable<any>;
  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  validatingForm!: FormGroup;

  displayedColumns: string[] = ['id', 'name', 'amount','edit','delete'];
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('edit', { static: true }) edit!: ModalContainerComponent;
  @ViewChild('add', { static: true }) add!: ModalContainerComponent;
  constructor(
    private _store: Store,
    private adminService: AdminDataService,
    private http: HttpClient
  ) {
    this.User$.subscribe(res =>{
      this.AdminUser = res
      console.log(this.AdminUser)
      //  this. getAllFees()
    })
   
  }

  
  ngOnInit(): void {
   
   
   
    // throw new Error('Method not implemented.');
    // this.validatingForm = new FormGroup({
    //   name: new FormControl(null, [Validators.required]),
    //   faculty_id: new FormControl(null, [Validators.required]),
      
    //    }/*, { updateOn: 'submit' }*/);
    


       
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
   setTimeout(()=>{
    if(this.AdminUser != null){
      this.Data$.subscribe(res =>{
        
        this.AdminData = res.fees.response
        // console.log(this.AdminData.Fees.response)
        
       
  
  if(this.AdminData){
      this.dataSource = new MatTableDataSource(this.AdminData)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } 
      })
     }
   },0)
  }
  

  get input(){
    return this.validatingForm.controls;
  }


  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  FeesType = <any> [
      "male","female"
  ]

  getAllFees(){
    this.preloader = true;
    // this._loader = true;
    const URL = 'api/fees'
    this._store
    .dispatch(new getFees(this.AdminUser,URL)) 
  .subscribe(
  res=> {
  console.log(res.adminData.fees.response)
  
  if(res.adminData.fees.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.fees.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.dataSource = new MatTableDataSource(res.adminData.fees.response)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // this.dataSource = res.adminData.Fees.response
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  
  }


  updateFees(){
    
    this._loader = true;
    const URL = 'api/fees/update'
    this.updateData.role = this.AdminUser.role
    this.updateData.fee_type_id = this.FeesData._id
    this.updateData.fee_name = this.FeesData.name
    this.updateData.fee_amount = this.FeesData.amount
    
    
    
    this._store
    .dispatch(new updateFees(this.updateData,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.fees.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.fees.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.edit.hide();
    this.getAllFees()
    
    

    // this.dataSource = new MatTableDataSource(res.adminData.Fees.response)
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;

    // this.dataSource = res.adminData.Fees.response
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  
  }

  editFees(data:any){
    console.log(data)
    this.FeesData = data

  }

  confirmDelete(data: any){
    this.FeesData = data

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteFees()
        // Swal.fire(
        //   'Deleted!',
        //   'record has been deleted.',
        //   'success'
        // )
      }
    })
  

  }

  deleteFees(){
      this._loader = true;
    const URL = 'api/fees/delete'
    this.DataToDelete.role = this.AdminUser.role
    this.DataToDelete.fee_type_id = this.FeesData._id
    this._store
    .dispatch(new deleteFees(this.DataToDelete,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.fees.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.fees.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.alert.title = "Successfull!"
  this.alert.message = res.adminData.fees.message
  this.alert.icon = "success"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    
    this.getAllFees()
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
  }

  addFees(){
    this._loader = true;
    const URL = 'api/fees/store'
    this.newData.role = this.AdminUser.role
    // this.newData.name = this.FeesData._id
    this._store
    .dispatch(new addFees(this.newData,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.fees.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.fees.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.add.hide();
    this.getAllFees()
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )


  }

}
