
       


<mat-form-field>
  <div class="overlay" *ngIf="preloader">
    <span   class="spinner-border spinner-border-xl text-primary preloader" role="status" aria-hidden="true"></span>

</div>

    
    <mat-label>Search Table</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input style="width: 50%;">
  </mat-form-field>
  
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      
        
  
      

      <!-- Code Column -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
        <td mat-cell *matCellDef="let row;"> {{row.code}} </td>
      </ng-container>
  
     
  
      <!-- title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let row"> {{row.title}} </td>
      </ng-container>
  
      <!-- Category Column -->
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
        <td mat-cell *matCellDef="let row" > {{row.course_category.name}} </td>
      </ng-container>

      <!-- Units Column -->
      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit </th>
        <td mat-cell *matCellDef="let row" > {{row.unit}} </td>
      </ng-container>

      <!-- Elearning Column -->
      <ng-container matColumnDef="elearning">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Elearning </th>
        <td mat-cell *matCellDef="let row" >
            <span *ngFor="let elearn of elearningCourses;let i=index">
                <button  mat-raised-button color="accent" class="success" *ngIf="row.code == elearn.code;" (click)="goToElearning(elearn.code)">Available</button>
                <!-- <ng-template #elseBlock><button mat-raised-button disabled>Not Available</button></ng-template> -->
            </span>
            
            </td>
      </ng-container>


      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
     
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>

    </table>
  
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
  
   
   
  
  

  

  
    