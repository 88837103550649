<!-- <button mdbBtn color="default" class="waves-light float-right" mdbWavesEffect>create Applicant</button> -->
<mat-form-field>
  <div class="overlay" *ngIf="preloader">
    <span   class="spinner-border spinner-border-xl text-primary preloader" role="status" aria-hidden="true"></span>

</div>

    
    <mat-label>Search Table</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input style="width: 50%;">
  </mat-form-field>
  
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      
  
      <!-- S/N Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> S/N </th>
        <td mat-cell *matCellDef="let row; let i = index;"> {{i + 1}} </td>
      </ng-container>
  
     
  
      <!-- Code Column -->
      <ng-container matColumnDef="reg_no">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reg. No.</th>
        <td mat-cell *matCellDef="let row"> {{row.reg_no}} </td>
      </ng-container>

       <!-- Name Column -->
       <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
        <td mat-cell *matCellDef="let row"> {{row.first_name}} </td>
      </ng-container>
  
      <!-- Date Created Column -->
      <ng-container matColumnDef="mid_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Middle Name </th>
        <td mat-cell *matCellDef="let row" > {{row.mid_name}} </td>
      </ng-container>

       <!-- last name Column -->
       <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
        <td mat-cell *matCellDef="let row" > {{row.last_name}} </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Addmission Status </th>
        <td mat-cell *matCellDef="let row" > {{row.admission_status}} </td>
      </ng-container>

       <!-- Edit Column -->Processing
       <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action</th>
        <td mat-cell *matCellDef="let row" > <mdb-icon *ngIf="(isAddmited); else notaddmited" (click)="editApplicant(row); add.show();" fas icon="check" class="prefix green-text"></mdb-icon>
         <ng-template #notaddmited><mdb-icon (click)="editApplicant(row); add.show();" fas icon="times" class="prefix red-text"></mdb-icon></ng-template>
          </td>
      </ng-container>

       <!-- Delete Column -->
       <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete</th>
        <td mat-cell *matCellDef="let row" >  <mdb-icon (click)="confirmDelete(row)" fas icon="trash" class="prefix red-text"></mdb-icon> </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>

    </table>
  
    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>

  
<div mdbModal #add="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
 <div class="modal-header text-center">
   <h4 class="modal-title w-100 font-weight-bold">Admit Student</h4>
   <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="add.hide()">
     <span aria-hidden="true">&times;</span>
   </button>
 </div>
 <div class="modal-body mx-3">

   <div class="md-form mb-5">
     <form >
    
       <div class="md-form md-outline form-lg"> 
         <mat-form-field appearance="outline" class="full-width">
           <mat-label>Addmission Staus</mat-label>
           <mat-select  [(ngModel)]="newData.addmission_status" name="addmission_status">
                                 
               <mat-option *ngFor="let addmission_status of admit_status " value="{{addmission_status}}">{{addmission_status}}</mat-option>                        
           </mat-select>
       </mat-form-field> 

         </div>

         <div class="md-form md-outline form-lg"> 
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Level</mat-label>
            <mat-select  [(ngModel)]="newData.level_id" name="level">
                                  
                <mat-option *ngFor="let level of levels " value="{{level._id}}">{{level.name}}</mat-option>                        
            </mat-select>
        </mat-form-field> 
 
          </div>
          <div class="md-form md-outline form-lg"> 
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Session</mat-label>
              <mat-select  [(ngModel)]="newData.session_id" name="session">
                                    
                  <mat-option *ngFor="let session of sessions " value="{{session._id}}">{{session.start_year}}/{{session.end_year}}</mat-option>                        
              </mat-select>
          </mat-form-field> 
   
            </div>

            <div class="md-form md-outline form-lg"> 
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Department</mat-label>
                <mat-select  [(ngModel)]="newData.dept_id" name="dept">
                                      
                    <mat-option *ngFor="let dept of departments " value="{{dept._id}}">{{dept.name}}</mat-option>                        
                </mat-select>
            </mat-form-field> 
     
              </div>
       
     </form>
   </div>


 </div>
 <div class="modal-footer d-flex justify-content-center">
   <button mdbBtn color="default" class="waves-light" (click)="addStudent()" mdbWavesEffect>Addmit</button>
 </div>
 
</div>
</div>
</div>



 
  