import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { StateReset } from 'ngxs-reset-plugin';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { AdminDataState } from '../../state/adminData.state';
import { AuthState } from '../../state/auth.state';
import { StudentDataState } from '../../state/studentData.state';
import { UtmeState } from '../../state/utme.state';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
  options = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2' },
    { value: '3', label: 'Option 3' },
  ];
  @Select(AuthState.isAuthenticated)
  loggedin$!: Observable<any>;
  isAuth = <boolean> false
  constructor(
    
    private _auth: AuthService,
    private _store: Store,
    private _router: Router,
    private zone: NgZone
    ) { }

  ngOnInit(): void {

    this.loggedin$.subscribe(res =>{
      // console.log(res)
      this.isAuth = res
      // console.log(this.isAuth)

        
      })
  }

  LogOut(){
    // this._auth.LogOut()
    this._store.dispatch(
      // new StateClear()
      // new StateResetAll()
     new  StateReset(AuthState,UtmeState,AdminDataState,StudentDataState)

      
    );
     this._router.navigateByUrl('/');
    // this.zone.run(() => { this._router.navigate(['/']) });
  }

}
