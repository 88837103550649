import { getStudent, getStudentCourses } from './../../shared/actions/studentData.action';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/shared/state/auth.state';
import { StudentDataState } from 'src/app/shared/state/studentData.state';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-course-registration',
  templateUrl: './course-registration.component.html',
  styleUrls: ['./course-registration.component.scss']
})
export class CourseRegistrationComponent implements OnInit {
  form!: FormGroup;
  preloader = false;
  _loader = false;
  _submitButton = true;
  StudentUser =<any>{}
  StudentData =<any>{}
  Payload = <any> {}
  CourseDetails = <any> {}
  selectedCourses = <any>[]
  TotalUnit = <number> 0
  paymentDetails = <any>[]

  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  @Select(AuthState.student)
  User$!: Observable<any>;

  @Select(StudentDataState.StudentData)
  Data$!: Observable<any>;

  @Select(StudentDataState.paymentDetails)
  paymentDetails$!: Observable<any>;

  displayedColumns: string[] = ['select','code', 'title', 'category','unit'];
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  constructor( 
    private _store: Store,
    private fb: FormBuilder) {
    this.User$.subscribe(res =>{
      this.StudentUser = res
      
      console.log(this.StudentUser)
      
      
    })

    this.form = this.fb.group({
      checkArray: this.fb.array([])
    })

    this.loadPaymentsDetails()
   }

  ngOnInit(): void {

    this.Data$.subscribe(res =>{
      this.StudentData = res.response
      
      console.log(this.StudentData)      
      
    })
    this.getCourses();
    this.selectedCourses = new Array<any>();
  }

  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  loadPaymentsDetails(){
    
    this.paymentDetails$.subscribe(res =>{
        
      this.paymentDetails = res.response
      console.log(this.paymentDetails)
     
    })
  }

  getCourses(){
    this.preloader = true;
    // this._loader = true;
    const COURSES_URL = 'api/course/student-courses';
    this.Payload.dept_id = this.StudentData.dept._id
    this.Payload.level_id = this.StudentData.level._id
    this.Payload.role = this.StudentUser.role
   
    this._store
    .dispatch([
      new getStudentCourses(this.Payload,COURSES_URL),
      // new getCourseCategory(this.AdminUser,COURSE_CATEGORY_URL),
      // new getLevel(this.AdminUser,LEVEL_URL),
      // new getFaculty(this.AdminUser,FACULTY_URL),
      // new getSemester(this.AdminUser,SEMESTER_URL),
      // new getDepartment(this.AdminUser,DEPARTMENT_URL),
    ]) 
  .subscribe(
  res=> {
  console.log(res)
  console.log(res[0].studentData.studentCourses.response)
  
  if(res[0].studentData.studentCourses.message){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res[0].studentData.studentDetails.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.dataSource = new MatTableDataSource(res[0].studentData.studentCourses.response)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  
  }


  onCheckboxChange(e:any,data:any) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
  
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
      this.TotalUnit = this.TotalUnit + data.unit
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: AbstractControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          this.TotalUnit = this.TotalUnit - data.unit
          return;
        }
        i++;
      });
    }
    this.selectedCourses = checkArray.value
    console.log(checkArray.value)
    console.log(this.TotalUnit)
  }


  registerCourses(){

    if(this.TotalUnit >=8){
      this.alert.title = "Limit Exceeded!"
  this.alert.message = "Max. Limit unit total is 24, drop some course"
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)

    }
   else  if(this.TotalUnit < 3){
      this.alert.title = "Limit Exceeded!"
      this.alert.message = "Min. Limit unit total is 18, Add more course"
      this.alert.icon = "error"
      
      this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    
    }
    else{

      this.CourseDetails.student_id = this.StudentUser.user_id
      this.CourseDetails.uu_id = this.StudentData._id
      this.CourseDetails.role = this.StudentUser.role
    this.CourseDetails.courses_ids = this.selectedCourses
    this.CourseDetails.dept_id = this.StudentData.dept._id
    this.CourseDetails.level_id = this.StudentData.level._id
    // this.CourseDetails.semester_id = this.StudentData.semester_id
    this.CourseDetails.session_id = this.StudentData.session._id
    this.CourseDetails.total_unit = this.TotalUnit
    const COURSES_URL = 'api/register-course/store';

    // console.log(this.StudentData)
    // console.log(this.CourseDetails)

    this._store
    .dispatch([
      new getStudentCourses(this.CourseDetails,COURSES_URL),
      
    ]) 
  .subscribe(
  res=> {
  console.log(res)
  // console.log(res[0].studentData.studentCourses.response)
  
  if(res[0].studentData.studentCourses.message == "An instance already exist!"){
          
  this.alert.title = "Error!"
  this.alert.message = res[0].studentData.studentCourses.message
  this.alert.icon = ""
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }

  if(res[0].studentData.studentCourses.message == "An instance already exist!"){
          
    this.alert.title = "Error!"
    this.alert.message = res[0].studentData.studentCourses.message
    this.alert.icon = "error"
    
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
    
    }
  else {
    this.alert.title = "Successful!"
    this.alert.message = res[0].studentData.studentCourses.message
    this.alert.icon = "success"
    
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
    
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  




    }

    
  }
}
