<div class="row">
    <!-- side -->
    <div class="col-md-4" style="min-height: 100vh;">
        <p class="blue darken-2 h-100"></p>
    </div>

    <!-- main -->
    <div class="col-md-6 mx-auto">
        <div class="container py-4">
            <mdb-card class="text-center">
                <mdb-card-body>
                    <mdb-card-title>
                        <h2>Students/Staff Login</h2>
                        <h4 class="text-muted mb-2">Sign into your Account</h4>
                    </mdb-card-title>
                    <mdb-card-text class="px-4">
                        <form [formGroup]="validatingForm">
                            <div class="form-group">
                                <div class="md-form md-outline form-lg">
                                    <!--{{LoginData.email}}-->
                                    <input [(ngModel)]="LoginData.email" mdbValidation type="email"
                                        class="form-control py-2" formControlName="email" mdbInput>
                                    <label for="email">Email/Reg No</label>

                                    <mdb-error
                                        *ngIf="input.email  && input.email.invalid && (input.email.dirty || input.email.touched)">
                                        <span *ngIf="input.email.errors!.required">Email is required</span>
                                        <span *ngIf="input.email.errors!.email">This email address is not valid</span>
                                    </mdb-error>
                                    <mdb-success
                                        *ngIf="input.email  && input.email.valid && (input.email.dirty || input.email.touched)">
                                        Email valid</mdb-success>
                                </div>
                                <div class="my-5 md-form md-outline form-lg">
                                    <input mdbValidation [(ngModel)]="LoginData.password" name="password"
                                        type="password" id="password" class="form-control py-2" mdbInput
                                        formControlName="password">
                                    <label for="password">Password</label>
                                    <mdb-error
                                        *ngIf="input.password && input.password.invalid && (input.password.dirty || input.password.touched)">
                                        <span *ngIf="input.password.errors!.required">Password is required</span>
                                        <!-- <span *ngIf="input.password.errors!.pattern">must contain caps</span> -->
                                        <span *ngIf="input.password.errors!.minlength"> minimum character (8)</span>

                                    </mdb-error>
                                    <mdb-success
                                        *ngIf="input.password && input.password.valid && (input.password.dirty || input.password.touched)">
                                        Password valid</mdb-success>

                                </div>
                            </div>

                            <!-- <div class="md-form"> -->

                            <!-- <mdb-error *ngIf="input.invalid && (input.dirty || input.touched)">Input invalid</mdb-error> -->
                            <!-- <mdb-success *ngIf="input.valid && (input.dirty || input.touched)">Input valid</mdb-success> -->
                            <!-- </div> -->

                        </form>
                    </mdb-card-text>
                    <button (click)="LoginUser()" [disabled]="validatingForm.invalid" mdbBtn color="blue"
                        mdbWavesEffect>Login
                        <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-dark" role="status"
                            aria-hidden="true"></span>
                        <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-secondary" role="status"
                            aria-hidden="true"></span>
                        <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-success" role="status"
                            aria-hidden="true"></span>
                    </button>
                    <!-- <a href="#!" class="card-link">Clear</a> -->
                </mdb-card-body>
            </mdb-card>

            <div class="container mt-5 py-5">
                <p class="text-center">For any difficulty, <a href="">Click Here</a> or Call the Support Line at
                    08139833300</p>
                <p class="text-center">Tony G. Erewa College of Education</p>
            </div>
        </div>
    </div>

</div>