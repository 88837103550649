import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UtmeState } from 'src/app/shared/state/utme.state';
import { ScreeningPaymentComponent } from '../screening-payment/screening-payment.component';

@Component({
  selector: 'app-applicant-details',
  templateUrl: './applicant-details.component.html',
  styleUrls: ['./applicant-details.component.scss']
})
export class ApplicantDetailsComponent implements OnInit {
  Applicant =<any>{}
  @Select(UtmeState.utmeUser)
  utmeUser$!: Observable<any>;

  constructor(
    private router: Router, 
    public dialog: MatDialog,
    private store: Store
    ) { }
 
  

  ngOnInit(): void {
    this.utmeUser$.subscribe(res =>{
      this.Applicant = res.response
      console.log(this.Applicant._id)
    })
    
  }

  apply(){
    this.dialog.open(ScreeningPaymentComponent, {
        height: '400px',
        width: '450px',
      }); 
  }

  

}
