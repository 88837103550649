import { addPayment, getPayments, getStudent } from './../../shared/actions/studentData.action';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { AuthState } from 'src/app/shared/state/auth.state';
import { StudentDataState } from 'src/app/shared/state/studentData.state';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ModalContainerComponent } from 'angular-bootstrap-md';
import { map, startWith } from 'rxjs/operators';




@Component({
  selector: 'app-school-fee-payment',
  templateUrl: './school-fee-payment.component.html',
  styleUrls: ['./school-fee-payment.component.scss']
})
export class SchoolFeePaymentComponent implements AfterViewInit, OnInit {

  preloader = false;
  _loader = false;
  _submitButton = true;
  StudentUser =<any>{}
  StudentData =<any>{}
  feetypes =<any>[]
  paymentDetails = <any>[]
  paymentData = <any>{}


  selectedFee$!:Observable<any>;
  loadPaymentDetails$!: Observable<any>;

  newData = <any> {}

  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  @Select(AuthState.student)
  User$!: Observable<any>;

  @Select(StudentDataState.StudentData)
  Data$!: Observable<any>;

  @Select(AuthState.isAuthenticated)
  loggedin$!: Observable<any>;
  isAuth = <boolean> false


  @Select(StudentDataState.feetype)
  feetype$!: Observable<any>;

  @Select(StudentDataState.paymentDetails)
  paymentDetails$!: Observable<any>;
  
  searchText = new Subject();

  displayedColumns: string[] = ['id', 'tnx_id', 'feetype','amount','view'];
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('edit', { static: true }) edit!: ModalContainerComponent;
  @ViewChild('add', { static: true }) add!: ModalContainerComponent;

  constructor( private _store: Store,) {

    this.loggedin$.subscribe(res =>{
      // console.log(res)
      this.isAuth = res
      // console.log(this.isAuth)

        
      })
    this.User$.subscribe(res =>{
      this.StudentUser = res
      
      console.log(this.StudentUser)
      // this.getPayments();
      
    })

    
   }

   filteredOptions!: Observable<any>;

  ngOnInit(): void {

    this.Data$.subscribe(res =>{
      this.StudentData = res.response
      
      console.log(this.StudentData)
      
      
    })
    this.filteredOptions = this.searchText
      .pipe(
        startWith(''),
        map((value:any) => typeof value === 'string' ? value : value.feetype),
        map(name => name ? this._filter(name) : this.feetypes.slice())
      );
    
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.loadPaymentsDetails()
    if(this.StudentUser != null){
      this.feetype$.subscribe(res =>{
        
        this.feetypes = res.response
        console.log(this.feetypes)
  
      })


      

     }
   },0)
    
  }

  loadPaymentsDetails(){
    
    this.paymentDetails$.subscribe(res =>{
        
      this.paymentDetails = res.response
      console.log(this.paymentDetails)
    
    this.dataSource = new MatTableDataSource(this.paymentDetails)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  
    })
  }


  // displayFn(user:any): string {
  //   return user && user.name ? user.name : '';
  // }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.feetypes.filter((item: any) => item.feetype.toLowerCase().includes(filterValue));
  }

  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPayments(){
    if(this.isAuth == true){
    this.preloader = true;
    // this._loader = true;
    const PAYMENTS_URL = 'api/payment/show';
    
   
    this._store
    .dispatch([
      new getPayments(this.StudentUser,PAYMENTS_URL),
      
    ]) 
  .subscribe(
  res=> {
  // console.log(res.adminData.course_category.response)
  console.log(res[0].studentData.studentPayments.response)
  
  
  if(res[0].studentData.studentPayments.message){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res[0].studentData.studentPayments.message + ", Login again!"
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
  this.paymentDetails = res[0].studentData.studentPayments.response 
  this.preloader = false;

  this.dataSource = new MatTableDataSource(this.paymentDetails)
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
  
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = err.statusText
    // this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
}
  
  }

  onFeeSelect(newVal:any){
    this.selectedFee$ = this._store.select(StudentDataState.getFeeAmount)
    .pipe(map(filterFn => filterFn(newVal)));

this.selectedFee$.subscribe(user => {
  // this.searchText = user.name;
  this.newData.amount= user.amount;
  this.newData.id = user._id;
// console.log(user)
})


     
    
  }

  getRandomString(length:number) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
}

//usage: getRandomString(20); // pass desired length of random string

  payFee(){
    let randomString =  this.getRandomString(11);
    this.paymentData.role = this.StudentUser.role;
    this.paymentData.student_id = this.StudentUser.user_id;
    this.paymentData.txn_id = `MU-FEES-${randomString}`;
    this.paymentData.seesion_id = this.StudentData.session._id;
    this.paymentData.level_id = this.StudentData.level._id;
    this.paymentData.department_id = this.StudentData.dept._id;
    this.paymentData.amount = this.newData.amount;
    this.paymentData.feetype = this.newData.id;

console.log(this.paymentData)

const ADD_PAYMENT_URL = 'api/payment/store';
    
   
    this._store
    .dispatch([
      new addPayment(this.paymentData,ADD_PAYMENT_URL),
      
    ]) 
  .subscribe(
  res=> {
  
  console.log(res[0].studentData.studentPayments)
  
  
  if(res[0].studentData.studentPayments.message){
          
  this.alert.title = "Successful!"
  this.alert.message = res[0].studentData.studentPayments.message 
  this.alert.icon = "success"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  this.add.hide()
  this.getPayments()
  // this.loadPaymentsDetails()
  
  }
  else {
    // this.paymentDetails = res[0].studentData.studentPayments.response 
  this.preloader = false;
  this.loadPaymentsDetails()
  this.add.hide()
  // this.getPayments()

  
  }
  
  },
  err=> {
    console.log(err)
    this.add.hide()
  if(err.status == 409){
    this.alert.title = "Not Successful!"
    this.alert.message = err.error.message
    
    this.alert.icon = "error"
  }

  else{
    this.alert.title = "Server Error!"
    this.alert.message = err.statusText
    
    this.alert.icon = "error"
  }
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
  }


}

