


<div class="container my-5 pb-5">
    <div class="row">
        

        <div class="col-md-9"> 
            <div class="card">
                <div class="d-flex flex-row justify-content-center pt-4">                    
                    <img src="assets/images/logo/mu-logo.png" alt="" height="70" class="rounded-circle z-depth-0">
                </div>

                <div class="d-flex flex-row justify-content-center pb-4">
                    <p class="title">Maduka University Hostel Application</p>
                </div>

                <div class="d-flex flex-row justify-content-center my-3">
                    <div class="col-4">Full Name</div>
                    <div class="col-4">Emmanuel Jude Ayomide</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-3">
                    <div class="col-4">Hostel Name</div>
                    <div class="col-4">Awo</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-3">
                    <div class="col-4">Block</div>
                    <div class="col-4">B</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-3">
                    <div class="col-4">Room</div>
                    <div class="col-4">5</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-3">
                    <div class="col-4">Space</div>
                    <div class="col-4">3</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-3">
                    <div class="col-4">Department</div>
                    <div class="col-4">Law</div>
                </div>

                <div class="d-flex justify-content-around my-5">
                    <div class="text-center">
                        <p class="top-border">Director</p>
                        <p>Student Affairs</p>
                    </div>
                    <div class="text-center">
                        <p class="top-border">Portal</p>
                        <p>Awo Hostel</p>
                    </div>
                </div>

                <div class="container d-flex justify-content-end py-5 px-5">
                    <button mat-raised-button color="primary" (click)='hostelPrintout();'>Continue to Print</button>
                </div>

            </div>
        </div>
    </div>
</div>


