import { getHostel } from './../../shared/actions/adminData.action';
import { getStudent } from './../../shared/actions/studentData.action';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { ModalContainerComponent } from 'angular-bootstrap-md';
import { Observable } from 'rxjs';
import { getCourseCategory,getFaculty, getSemester, getDepartment, getCourse, getSession, getStudents, getApplicants, getUtme } from 'src/app/shared/actions/adminData.action';
import { getLevel} from 'src/app/shared/actions/level/adminData.level.action';
import { AdminDataService } from 'src/app/shared/services/admin-services/admin-data.service';
import { AdminDataState } from 'src/app/shared/state/adminData.state';
import { AuthState } from 'src/app/shared/state/auth.state';
import Swal from 'sweetalert2';
import { StateReset } from 'ngxs-reset-plugin';
import { UtmeState } from 'src/app/shared/state/utme.state';
import { StudentDataState } from 'src/app/shared/state/studentData.state';
import { Router } from '@angular/router';
import { getFees } from 'src/app/shared/actions/fees/adminData.fees.action';
import { getFeePayments } from 'src/app/shared/actions/fee-payments/adminData.fee-payments.action';


export interface UserData {
  id: string;
  name: string;
  
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements  OnInit {

  preloader = false;
  _loader = false;
  _submitButton = true;
  AdminUser =<any>{}
  AdminData =<any>{}
  
  
  userDetails = <any> {}
  CourseCategoryData = <any> {}
  newData = <any> {}
  DataToDelete = <any> {}
  TotalCourseCategory = <any> ""
  
  @Select(AuthState.admin)
  User$!: Observable<any>;

  @Select(AdminDataState.adminData)
  Data$!: Observable<any>;

  @Select(AuthState.isAuthenticated)
  loggedin$!: Observable<any>;
  isAuth = <boolean> false
  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  validatingForm!: FormGroup;

  displayedColumns: string[] = ['id', 'name', 'createdAt','edit','delete'];
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('edit', { static: true }) edit!: ModalContainerComponent;
  @ViewChild('add', { static: true }) add!: ModalContainerComponent;
  constructor(
    private _store: Store,
    private _router: Router,
    
  ) {
    this.loggedin$.subscribe(res =>{
      // console.log(res)
      this.isAuth = res
      // console.log(this.isAuth)

        
      })
    this.User$.subscribe(res =>{
      this.AdminUser = res
      
      console.log(this.AdminUser)
      
    })
    this. getAllAdminData()
  }

  
  ngOnInit(): void {
    this.Data$.subscribe(res =>{
      this.AdminData = res
      
      // console.log(this.AdminData.level)
      
    })
   
    // throw new Error('Method not implemented.');
    this.validatingForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
       }/*, { updateOn: 'submit' }*/);
    
  }

  get input(){
    return this.validatingForm.controls;
  }


  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  getAllAdminData(){
    this.preloader = true;
    // this._loader = true;
    const COURSE_CATEGORY_URL = 'api/course-category';
    const COURSE_URL = 'api/course';
    const LEVEL_URL = 'api/level'
    const SEMESTER_URL = 'api/semester'
    const FACULTY_URL = 'api/faculty'
    const DEPARTMENT_URL = 'api/department'
    const SESSION_URL = 'api/session'
    const STUDENT_URL = 'api/student'
    const APPLICANT_URL = 'api/applicant'
    const UTME_URL = 'api/utme'
    const HOSTEL_URL = 'api/hostel'
    const FEES_URL = 'api/fees'
    const FEE_PAYMENT_URL = 'api/payment'
   
    this._store
    .dispatch([
      new getCourseCategory(this.AdminUser,COURSE_CATEGORY_URL),
      new getCourse(this.AdminUser,COURSE_URL),
      new getLevel(this.AdminUser,LEVEL_URL),
      new getFaculty(this.AdminUser,FACULTY_URL),
      new getSemester(this.AdminUser,SEMESTER_URL),
      new getDepartment(this.AdminUser,DEPARTMENT_URL),
      new getSession(this.AdminUser,SESSION_URL),
      new getStudents(this.AdminUser,STUDENT_URL),
      new getApplicants(this.AdminUser,APPLICANT_URL),
      new getUtme(this.AdminUser,UTME_URL),
      new getHostel(this.AdminUser,HOSTEL_URL),
      new getFees(this.AdminUser,FEES_URL),
      new getFeePayments(this.AdminUser,FEE_PAYMENT_URL),
    ]) 
  .subscribe(
  res=> {
  // console.log(res.adminData.course_category.response)
  console.log(res[0])
  
  if(res[0].adminData.course_category.message == "Authentication Failed!"){

    this.LogOut()
          
  // this.alert.title = "Session Timed out!"
  // this.alert.message = res[0].adminData.course_category.message
  // this.alert.icon = "error"
  
  // this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  // this.preloader = false;
  
  }else {
    this.TotalCourseCategory =  res[res.length - 1]
    console.log(this.TotalCourseCategory)

    // this.dataSource = new MatTableDataSource(res.adminData.course_category.response)
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    // this.TotalCourseCategory = res.adminData.course_category.response

    // this.dataSource = res.adminData.CourseCategory.response
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  
  }


  LogOut(){
    // this._auth.LogOut()
    this._store.dispatch(
      // new StateClear()
      // new StateResetAll()
     new  StateReset(AuthState,UtmeState,AdminDataState,StudentDataState)

      
    );
     this._router.navigateByUrl('/');
    // this.zone.run(() => { this._router.navigate(['/']) });
  }

}