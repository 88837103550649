import { ScreeningService } from 'src/app/shared/services/utme/screening.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/shared/state/auth.state';
import { ScreeningPaymentComponent } from '../../screening-payment/screening-payment.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  _loader = false;
  Applicant =<any>{}
  userPayload = <any> {}
  userDetails = <any> {}
  @Select(AuthState.applicant)
  User$!: Observable<any>;

  @Select(AuthState.isAuthenticated)
  loggedin$!: Observable<any>;
  isAuth = <boolean> false

  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }


  constructor(
    public getApplicantService: ScreeningService,
    private router: Router, 
    public dialog: MatDialog,
    private store: Store
    ) {
      this.loggedin$.subscribe(res =>{
        // console.log(res)
        this.isAuth = res
        // console.log(this.isAuth)
  
          
        })
     }
 
  

  ngOnInit(): void {
    this.User$.subscribe(res =>{
      this.Applicant = res
      console.log(this.Applicant)
     if (this.Applicant.user_id !=null){
      this.getApplicant()
     }
    })
    
  }


  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  getApplicant(){
    if(this.isAuth == true){
    this._loader = true;
    this.userPayload.user_id = this.Applicant.user_id
    this.userPayload.role = this.Applicant.role
this.getApplicantService.getApplicant(this.userPayload)
.subscribe(
  res=> {
console.log(res)

if(res.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.message
  this.alert.icon = "error"

  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this._loader = false;
  
 }else {
  this.userDetails = res.response
  this._loader = false;
 }

  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"

    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this._loader = false;
  }
)
    
    }

  }

  completeDate(){
    this.router.navigate(['application-form']);
  }

  apply(){
    this.dialog.open(ScreeningPaymentComponent, {
        height: '400px',
        width: '450px',
      }); 
  }

}
