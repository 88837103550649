<button mdbBtn color="default" class="waves-light float-right" (click)="add.show()" mdbWavesEffect>New
    Application</button>
<mat-form-field>
    <div class="overlay" *ngIf="preloader">
        <span class="spinner-border spinner-border-xl text-primary preloader" role="status" aria-hidden="true"></span>

    </div>


    <mat-label>Search Table</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input style="width: 50%;">
    </mat-form-field>
    <div class="mat-elevation-z8" style="overflow-x:auto;">
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- S/N Column -->
            <ng-container matColumnDef="id" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> S/N </th>
                <td mat-cell *matCellDef="let row; let i = index;"> {{i + 1}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Hostel Name </th>
                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
            </ng-container>
    <!-- Block Column -->
    <!-- <ng-container matColumnDef="block">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Block</th>
                                                            <td mat-cell *matCellDef="let row;"> {{row?.block || "null"}} </td>
                                                          </ng-container> -->
    
    <!-- Rooms Created Column -->
    <ng-container matColumnDef="rooms">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Rooms </th>
        <td mat-cell *matCellDef="let row">
            {{row?.rooms.length}}
    
        </td>
    </ng-container>
    
    <!-- Space/Room Created Column -->
    <ng-container matColumnDef="space">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Space/Room </th>
        <td mat-cell *matCellDef="let row"> {{row?.space || "null"}} </td>
    </ng-container>
    
    <!-- Total Space Created Column -->
    <ng-container matColumnDef="total-space">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Space</th>
        <td mat-cell *matCellDef="let row"> {{row?.space * row?.rooms.length || "null"}} </td>
    </ng-container>
    
    <!-- Date Created Column -->
    <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Hostel Category</th>
        <td mat-cell *matCellDef="let row"> {{row?.type || "null"}} </td>
    </ng-container>
    
    <!-- Date Created Column -->
    <!-- <ng-container matColumnDef="createdAt">
                                                                                                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </th>
                                                                                                                                            <td mat-cell *matCellDef="let row" > {{row.createdAt | date :'mediumDate'}} </td>
                                                                                                                                          </ng-container> -->
    
    <!-- Edit Column -->
    <!-- <ng-container matColumnDef="edit">
                                                                                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
                                                                                                                        <td mat-cell *matCellDef="let row">
                                                                                                                            <mdb-icon (click)="edit.show(); ;" fas icon="edit" class="prefix blue-text"></mdb-icon>
                                                                                                                        </td>
                                                                                                                    </ng-container> -->
    
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
    <div mdbModal #add="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                    <h4 class="modal-title w-100 font-weight-bold">Apply for Hostel</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="add.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body mx-3">
        
                        <div class="md-form mb-5">
                            <form>
        
                                <div class="md-form md-outline form-lg">
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>Category</mat-label>
                                        <mat-select [(ngModel)]="newData.hostel_type" name="hostel_type" (ngModelChange)="getHostelCategoty($event)">
                                        
                                            <mat-option *ngFor="let hostel of hostelType; let indexOfelement=index" value="{{hostel}}">
                                                {{hostel}}
                                            </mat-option>
                                        </mat-select>
                                        </mat-form-field>
        
                                </div>
        
                                <div class="md-form md-outline form-lg">
                                
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>Hostel Name</mat-label>
                                        <mat-select [(ngModel)]="newData.name" name="name" (ngModelChange)="getHostelNames($event)">
                                        
                                            <mat-option *ngFor="let hostelName of hostelNames; let indexOfelement=index" value="{{hostelName.name}}">
                                                {{hostelName.name}}
                                            </mat-option>
                                        </mat-select>
                                        </mat-form-field>
        
                                </div>
        
                                <div class="md-form md-outline form-lg">
                                
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>Rooms</mat-label>
                                        <mat-select [(ngModel)]="newData.room" name="room">
                                        
                                            <mat-option *ngFor="let hostelDetail of hostelDetails; let indexOfelement=index"
                                                [disabled]="hostelDetail.available == 0" value="{{hostelDetail.room_number}}">
                                                Room: {{hostelDetail.room_number}} (Capacity:{{hostelDetail.room_capacity}},
                                                Available
                                                space: {{hostelDetail.available}})
                                            </mat-option>
                                        </mat-select>
                                        </mat-form-field>
        
                                </div>

                                <div class="md-form md-outline form-lg">
                                
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>Amount</mat-label>
                                        <input matInput number appearance="outline" placeholder="NGN" [(ngModel)]="newData.amount" name="amount" readonly>
                                        </mat-form-field>
        
                                </div>
        
        </form>
                        </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-center">
                            <button mdbBtn color="default" class="waves-light" (click)="apply()" mdbWavesEffect>add</button>
                        </div>
                        </div>
                        </div>
                        </div>