
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { ModalContainerComponent } from 'angular-bootstrap-md';
import { Observable } from 'rxjs';
import { addCourse, deleteCourse, getCourse, updateCourse } from 'src/app/shared/actions/adminData.action';
import { AdminDataService } from 'src/app/shared/services/admin-services/admin-data.service';
import { AdminDataState } from 'src/app/shared/state/adminData.state';
import { AuthState } from 'src/app/shared/state/auth.state';
import Swal from 'sweetalert2';


export interface UserData {
  id: string;
  name: string;
  
}
@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  preloader = false;
  _loader = false;
  _submitButton = true;
  AdminUser =<any>{}
  AdminData =<any>{}
  userDetails = <any> {}
  CourseData = <any> {}
  newData = <any> {
    course_category: {
      name: "",
      _id: ""
    }
  }
  DataToDelete = <any> {}

departments = <any> {}
levels = <any> {}
course_category = <any> {}
selected = "test"
  
  @Select(AuthState.admin)
  User$!: Observable<any>;
  @Select(AdminDataState.adminData)
  Data$!: Observable<any>;
  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  validatingForm!: FormGroup;

  displayedColumns: string[] = ['id', 'code', 'Title', 'Category', 'unit','edit','delete'];
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('edit', { static: true }) edit!: ModalContainerComponent;
  @ViewChild('add', { static: true }) add!: ModalContainerComponent;
  constructor(
    private _store: Store,
    private adminService: AdminDataService,
    private http: HttpClient
  ) {
    
    this.User$.subscribe(res =>{
      this.AdminUser = res
      console.log(this.AdminUser)
      //  this. getAllCourse()
    })

    
  }

  
  ngOnInit(): void {
    
    
   
    // throw new Error('Method not implemented.');
    this.validatingForm = new FormGroup({
      title: new FormControl(null, [Validators.required]),
      code: new FormControl(null, [Validators.required]),
      unit: new FormControl(null, [Validators.required]),
      level_id: new FormControl(null, [Validators.required]),
      course_category_id: new FormControl(null, [Validators.required]),
      dept_id: new FormControl(null, [Validators.required]),
       }/*, { updateOn: 'submit' }*/);
    


       this.Data$.subscribe(res =>{
      
        this.AdminData = res
        
         if(res.department){
   this.departments = res.department.response
         }
         if(res.level){
   this.levels = res.level.response
         }
         if(res.course_category){
   this.course_category = res.course_category.response
         }
  //  console.log(this.department)
  if(this.AdminData.courses){
      this.dataSource = new MatTableDataSource(this.AdminData.courses.response)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } 
      })
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  
  }

  get input(){
    return this.validatingForm.controls;
  }

course_units = [
  {
    value:1,
    unit:1
  },
  {
    value:2,
    unit:2
  },
  {
    value:3,
    unit:3
  },
  {
    value:4,
    unit:4
  },
  {
    value:5,
    unit:5
  },
  {
    value:6,
    unit:6
  },
  {
    value:7,
    unit:7
  },
  {
    value:8,
    unit:8
  },
]
  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  getAllCourse(){
    this.preloader = true;
    // this._loader = true;
    const URL = 'api/course'
    this._store
    .dispatch(new getCourse(this.AdminUser,URL)) 
  .subscribe(
  res=> {
  console.log(res.adminData.courses.response)
  
  if(res.adminData.courses.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.courses.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.dataSource = new MatTableDataSource(res.adminData.courses.response)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // this.dataSource = res.adminData.Course.response
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  
  }


  updateCourse(){
    
    this._loader = true;
    const URL = 'api/course/update'
    // this.CourseData.role = this.AdminUser.role
    // this.CourseData.Course_id = this.CourseData._id
    this.CourseData.role = this.AdminUser.role
    this.CourseData.title = this.newData.title
    this.CourseData.code = this.newData.code
    this.CourseData.unit = this.newData.unit
    this.CourseData.level_id = this.newData.level
    this.CourseData.dept_id = this.newData.dept
    this.CourseData.course_id = this.newData._id
    this.CourseData.course_category_id = this.newData.course_category._id
    this._store
    .dispatch(new updateCourse(this.CourseData,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.courses.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.courses.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.edit.hide();
    this.getAllCourse()
    
    

      this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  
  }

  editCourse(data:any){
    console.log(data)
    this.newData = data

  }

  confirmDelete(data: any){
    this.CourseData = data

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteCourse()
        // Swal.fire(
        //   'Deleted!',
        //   'record has been deleted.',
        //   'success'
        // )
      }
    })
  

  }

  deleteCourse(){
      this._loader = true;
    const URL = 'api/course/delete'
    this.DataToDelete.role = this.AdminUser.role
    this.DataToDelete.course_id = this.CourseData._id
    this._store
    .dispatch(new deleteCourse(this.DataToDelete,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.courses.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.courses.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.alert.title = "Successfull!"
  this.alert.message = res.adminData.courses.message
  this.alert.icon = "success"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    
    this.getAllCourse()
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
  }

  addCourse(){
    this._loader = true;
    const URL = 'api/course/store'
    this.newData.role = this.AdminUser.role
    // this.newData.name = this.CourseData._id
    this._store
    .dispatch(new addCourse(this.newData,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.courses.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.courses.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }
  if(res.adminData.courses.message == "An instance already exist!"){
          
    this.alert.title = "Error!"
    this.alert.message = res.adminData.courses.message
    this.alert.icon = "error"
    
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
    
    }
  else {
    this.add.hide();
    this.getAllCourse()
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )


  }

}


