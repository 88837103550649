
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { ModalContainerComponent } from 'angular-bootstrap-md';
import { Observable } from 'rxjs';
import { addStudent, deleteStudent, getStudents, updateStudent } from 'src/app/shared/actions/adminData.action';
import { AdminDataService } from 'src/app/shared/services/admin-services/admin-data.service';
import { AdminDataState } from 'src/app/shared/state/adminData.state';
import { AuthState } from 'src/app/shared/state/auth.state';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit {

  preloader = false;
  _loader = false;
  _submitButton = true;
  AdminUser =<any>{}
  AdminData =<any>{}
  userDetails = <any> {}
  StudentData = <any> {}
  newData = <any> {
    Student_category: {
      name: "",
      _id: ""
    }
  }
  DataToDelete = <any> {}

departments = <any> []
levels = <any> {}
Student_category = <any> {}
selected = "test"
  
  @Select(AuthState.admin)
  User$!: Observable<any>;
  @Select(AdminDataState.adminData)
  Data$!: Observable<any>;


  @Select(AuthState.isAuthenticated)
  loggedin$!: Observable<any>;
  isAuth = <boolean> false
  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  validatingForm!: FormGroup;

  displayedColumns: string[] = ['id', 'mat_no', 'first_name', 'mid_name', 'last_name', 'edit','delete'];
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('edit', { static: true }) edit!: ModalContainerComponent;
  @ViewChild('add', { static: true }) add!: ModalContainerComponent;
  constructor(
    private _store: Store,
    private adminService: AdminDataService,
    private http: HttpClient
  ) {

    this.loggedin$.subscribe(res =>{
      // console.log(res)
      this.isAuth = res
      // console.log(this.isAuth)

        
      })
    
    this.User$.subscribe(res =>{
      this.AdminUser = res
      console.log(this.AdminUser)
       this. getAllStudent()
    })

    

    
  }

  
  ngOnInit(): void {

    
    
    
   
    // throw new Error('Method not implemented.');
    this.validatingForm = new FormGroup({
      title: new FormControl(null, [Validators.required]),
      code: new FormControl(null, [Validators.required]),
      unit: new FormControl(null, [Validators.required]),
      level_id: new FormControl(null, [Validators.required]),
      Student_category_id: new FormControl(null, [Validators.required]),
      dept_id: new FormControl(null, [Validators.required]),
       }/*, { updateOn: 'submit' }*/);
    

      
    
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    // setTimeout(()=> {


    //   this.Data$.subscribe(res =>{
      
    //     this.AdminData = res
    // if(this.AdminData.students != null){
    //   this.dataSource = new MatTableDataSource(this.AdminData.students.response)
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;
    // }
  
    //   })

    //  },0)

  
  }

  get input(){
    return this.validatingForm.controls;
  }


  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  getAllStudent(){
    if(this.isAuth == true){
    this.preloader = true;
    // this._loader = true;
    const URL = 'api/student'
    this._store
    .dispatch(new getStudents(this.AdminUser,URL)) 
  .subscribe(
  res=> {
  console.log(res.adminData.students.response)
  
  if(res.adminData.students.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.students.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.dataSource = new MatTableDataSource(res.adminData.students.response)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // this.dataSource = res.adminData.Student.response
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
}
  }


  updateStudent(){
    
    this._loader = true;
    const URL = 'api/student/update'
    // this.StudentData.role = this.AdminUser.role
    // this.StudentData.Student_id = this.StudentData._id
    this.StudentData.role = this.AdminUser.role
    this.StudentData.title = this.newData.title
    this.StudentData.code = this.newData.code
    this.StudentData.unit = this.newData.unit
    this.StudentData.level_id = this.newData.level
    this.StudentData.dept_id = this.newData.dept
    this.StudentData.Student_id = this.newData._id
    this.StudentData.Student_category_id = this.newData.Student_category._id
    this._store
    .dispatch(new updateStudent(this.StudentData,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.students.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.students.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.edit.hide();
    this.getAllStudent()
    
    

      this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  
  }

  editStudent(data:any){
    console.log(data)
    this.newData = data

  }

  confirmDelete(data: any){
    this.StudentData = data

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteStudent()
        // Swal.fire(
        //   'Deleted!',
        //   'record has been deleted.',
        //   'success'
        // )
      }
    })
  

  }

  deleteStudent(){
      this._loader = true;
    const URL = 'api/student/delete'
    this.DataToDelete.role = this.AdminUser.role
    this.DataToDelete.student_id = this.StudentData._id
    this._store
    .dispatch(new deleteStudent(this.DataToDelete,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.students.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.students.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.alert.title = "Successfull!"
  this.alert.message = res.adminData.students.message
  this.alert.icon = "success"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    
    this.getAllStudent()
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
  }

  addStudent(){
    this._loader = true;
    const URL = 'api/student/store'
    this.newData.role = this.AdminUser.role
    // this.newData.name = this.StudentData._id
    this._store
    .dispatch(new addStudent(this.newData,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.students.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.students.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }
  if(res.adminData.Students.message == "An instance already exist!"){
          
    this.alert.title = "Error!"
    this.alert.message = res.adminData.students.message
    this.alert.icon = "error"
    
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
    
    }
  else {
    this.add.hide();
    this.getAllStudent()
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )


  }

}