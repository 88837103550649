import { Router } from '@angular/router';
import { ScreeningService } from './../../shared/services/utme/screening.service';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ScreeningPaymentComponent } from './../screening-payment/screening-payment.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Store } from '@ngxs/store';
import { getUtmeUser } from 'src/app/shared/actions/utme.action';

// interface registeredResponse {
//   email: string;
// }

@Component({
  selector: 'app-screening',
  templateUrl: './screening.component.html',
  styleUrls: ['./screening.component.scss']
})
export class ScreeningComponent implements OnInit {
  _loader = false;
  _submitButton = true;
  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }
  requestData = {
    reg_no: <any> "",
  }

  form = new FormGroup({
    reg_no: new FormControl('', [Validators.required, Validators.minLength(11)] ),
  });

  get reg_no() {
    return this.form.get('reg_no');
  }

  constructor(public dialog: MatDialog, private _screeningService: ScreeningService,private _store: Store,public _router: Router) { }

  ngOnInit(): void {
  }

  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  public openPaymentProcess() {
    this._submitButton = false;
    this._loader = true;
    
    // this._screeningService.checkStatus(this.requestData)
    this._store
      .dispatch(new getUtmeUser(this.requestData))
    .subscribe(
      res =>{
        // this.alertWithSuccess()
        
        console.log(res.utme.utmeUser.response)
        if(res.utme.utmeUser.response == null){
          
          this.alert.title = "Record Not Found!"
          this.alert.message = `There is no record available for the UTME registration number ${this.requestData.reg_no} provided!`
          this.alert.icon = "error"
  
          this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
          this._loader = false;
          this._submitButton = true;
         }else {
                  // this.dialog.open(ScreeningPaymentComponent, {
                  //   height: '400px',
                  //   width: '450px',
                  // }); 
                  
                  this._loader = false;
                  this._submitButton = true;
                  this._router.navigateByUrl('applicant-details'); 
                }
        
      },
      err => {
        
        console.log(err)
        this.alert.title = "Server Error!"
        this.alert.message = `There was an error processing this request`
        this.alert.icon = "error"

        this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
        this._loader = false;
          this._submitButton = true;
      }
        
    )
    }

  //   let regno =  this.form.get('reg_no')?.value;

  //   this.http.get('https://mubackend.herokuapp.com/api/utme/show/'+ regno).subscribe(
  //     (response) => {
  //       if(response == null){
  //         Swal.fire('Record Not Found', `There is no record available for the UTME registration number (${regno}) provided!`, 'error');
  //       }else {
  //         this.dialog.open(ScreeningPaymentComponent, {
  //           height: '400px',
  //           width: '450px',
  //         });          
  //       }          
  //     },
  //     (error) => Swal.fire('Server Error', 'There was an error processing this request.', 'error')
  //   );    
  // }

}
