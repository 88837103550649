


<div class="container p-5">
    <mat-card class="card-width">
        <form class="form-layout">
            <div class="d-flex justify-content-center pr-5 pb-2">
                <p class="title pb-3 pt-5">Create Clearance Profile</p>
            </div>

            <div class="space-around">        
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Select Program</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let program of programs;" value="">{{ program }}</mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>

            <div class="space-around">        
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Select Department</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let dept of depts;" value="">{{ dept }}</mat-option>                        
                    </mat-select>
                </mat-form-field> 
            </div>

            <div class="space-around">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Matriculation Number</mat-label>
                    <input matInput>
                </mat-form-field> 
            </div>

            <div class="py-4">
                <button mat-raised-button color="primary" class="full-width py-2" (click)="submitClearanceForm();">Submit</button>
            </div>
        </form>
    </mat-card>
</div>





