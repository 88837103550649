import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ScreeningService } from 'src/app/shared/services/utme/screening.service';
import { AuthState } from 'src/app/shared/state/auth.state';
import { UtmeState } from 'src/app/shared/state/utme.state';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cert-upload',
  templateUrl: './cert-upload.component.html',
  styleUrls: ['./cert-upload.component.scss']
})
export class CertUploadComponent implements OnInit {
  preloader = false;
  _loader = false;
  _submitButton = true;
   Applicant =<any>{}
  userPayload = <any> {
    
  }
  userDetails = <any> {}
  @Select(AuthState.applicant)
  User$!: Observable<any>;

  
  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }
  reg_no = <any> '';
  fileName1 =<any> '';
  fileName2 =<any> '';
  fileName3 =<any> '';

  file1:File = <any> '';
  file2:File = <any> '';
  file3:File = <any> '';
  constructor(
    public certService: ScreeningService,
    public getApplicantService: ScreeningService,
    ) {}

  ngOnInit(): void {

    this.User$.subscribe(res =>{
      this.Applicant = res
      this.getApplicant()
    })

   
  }


  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }
  
  onFileSelected1(event:any) {

    this.file1 = event.target.files[0];
    // const file:File = event.target.files;

    if (this.file1) {

        this.fileName1 = this.file1.name;

       
        console.log(this.file1)

        // const upload$ = this.http.post("/api/thumbnail-upload", formData);

        // upload$.subscribe();
    }
}
onFileSelected2(event:any) {

  this.file2 = event.target.files[0];
  // const file:File = event.target.files;

  if (this.file2) {

      this.fileName2 = this.file2.name;

      
      console.log(this.file2)

      // const upload$ = this.http.post("/api/thumbnail-upload", formData);

      // upload$.subscribe();
  }
}
onFileSelected3(event:any) {

  this.file3 = event.target.files[0];
  // const file:File = event.target.files;

  if (this.file3) {

      this.fileName3 = this.file3.name;

      
      console.log(this.file3)

      // const upload$ = this.http.post("/api/thumbnail-upload", formData);

      // upload$.subscribe();
  }
}
uploadCredentials(){

  this._submitButton = false;
    this._loader = true;

  const formData = new FormData();

  formData.append("ssce", this.file1);
  formData.append("utme_result", this.file2);
  formData.append("fslc", this.file3);
  formData.append("reg_no", this.userDetails.reg_no);
  formData.append("role", this.Applicant.role);
  


  this.certService.uploadCert(formData)
    .subscribe(
      res=>{
        
        console.log(res)
        if(res.message == "Record not found!"){
        
          this.alert.title = "Submission Error!"
          this.alert.message = res.message
          this.alert.icon = "error"
  
          this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
          this._loader = false;
          this._submitButton = true;
         }else {
                    
          this.alert.title = "Submission Succesful!"
          this.alert.message = "Always check on the portal for addmission status"
          this.alert.icon = "success"
  
          this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
          this._loader = false;
          this._submitButton = true;
          // this._router.navigateByUrl('upload-credentials'); 
        }
      },
      err => {
        
      
        console.log(err)
        this.alert.title = "Server Error!"
        this.alert.message = `There was an error processing this request`
        this.alert.icon = "error"

        this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
        this._loader = false;
          this._submitButton = true;
      }
    )
  console.log(this.file1,this.file2,this.file3)
}
getApplicant(){
  this.preloader = true;
  // this._loader = true;
  this.userPayload.user_id = this.Applicant.user_id
  this.userPayload.role = this.Applicant.role
this.getApplicantService.getApplicant(this.userPayload)
.subscribe(
res=> {
console.log(res)

if(res.message == "Authentication Failed!"){
        
this.alert.title = "Session Timed out!"
this.alert.message = res.message
this.alert.icon = "error"

this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
this.preloader = false;

}else {
this.userDetails = res.response
this.preloader = false;
}

},
err=> {
  console.log(err)
  this.alert.title = "Server Error!"
  this.alert.message = `There was an error processing this request`
  this.alert.icon = "error"

  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
}
)
  


}

}
