


<div class="container p-5">
    <mat-card class="card-layout">
        <div>
            <p class="title pb-5">Complete Screening Application</p>
        </div>

        <div>
            <p class="sub-title pl-5 pb-3 text-muted">Carefully enter O-Level Subjects and corresponding Grades.</p>
        </div>

        <form class="form-layout" [formGroup]="formdata">
            <div class="d-flex justify-content-between pr-5 pb-2">
                <p>Select Subject</p>
                <p>Select Grade</p>
            </div>

            <div class="space-around">
                <mat-form-field appearance="outline" class="subject-width">
                    <mat-label>Mathematics</mat-label>
                    <input type="hidden" [(ngModel)]="resultDetails.reg_no" formControlName="reg_no">
                    <mat-select [(ngModel)]="resultDetails.ssce_results[0].subject" formControlName="sub1">
                        <mat-option  value="{{resultDetails.ssce_results[0].subject}}">{{resultDetails.ssce_results[0].subject}}</mat-option>
                    </mat-select>
                    <!-- <input type="text" [(ngModel)]="resultDetails.ssce_results[0].subject" formControlName="sub1" matInput > -->
                </mat-form-field> 

                <mat-form-field appearance="outline" class="grade-width">
                    <mat-label>Grade</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[0].grade" formControlName="grade1">
                        <mat-option *ngFor="let grade of gradeList;" value="{{grade}}">{{ grade }}</mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>

            <div class="space-around">
                <mat-form-field appearance="outline" class="subject-width">
                    <mat-label>English Language</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[1].subject" formControlName="sub2">
                        <mat-option  value="{{resultDetails.ssce_results[1].subject}}">{{ resultDetails.ssce_results[1].subject }}</mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field appearance="outline" class="grade-width">
                    <mat-label>Grade</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[1].grade" formControlName="grade2">
                        <mat-option *ngFor="let grade of gradeList;" value="{{grade}}">{{ grade }}</mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>

            <div class="space-around">
                <mat-form-field appearance="outline" class="subject-width">
                    <mat-label>Enter Subject</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[2].subject" formControlName="sub3">
                        <mat-option  *ngFor="let subject of subjectList;" value="{{subject}}">{{ subject }}</mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field appearance="outline" class="grade-width">
                    <mat-label>Grade</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[2].grade" formControlName="grade3">
                        <mat-option *ngFor="let grade of gradeList;" value="{{grade}}">{{ grade }}</mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>

            <div class="space-around">
                <mat-form-field appearance="outline" class="subject-width">
                    <mat-label>Enter Subject</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[3].subject" formControlName="sub4">
                        <mat-option  *ngFor="let subject of subjectList;" value="{{subject}}">{{ subject }}</mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field appearance="outline" class="grade-width">
                    <mat-label>Grade</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[3].grade" formControlName="grade4">
                        <mat-option *ngFor="let grade of gradeList;" value="{{grade}}">{{ grade }}</mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>

            <div class="space-around">
                <mat-form-field appearance="outline" class="subject-width">
                    <mat-label>Enter Subject</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[4].subject" formControlName="sub5">
                        <mat-option  *ngFor="let subject of subjectList;" value="{{subject}}">{{ subject }}</mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field appearance="outline" class="grade-width">
                    <mat-label>Grade</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[4].grade" formControlName="grade5">
                        <mat-option *ngFor="let grade of gradeList;" value="{{grade}}">{{ grade }}</mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>

            <div class="space-around">
                <mat-form-field appearance="outline" class="subject-width">
                    <mat-label>Enter Subject</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[5].subject" formControlName="sub6">
                        <mat-option  *ngFor="let subject of subjectList;" value="{{subject}}">{{ subject }}</mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field appearance="outline" class="grade-width">
                    <mat-label>Grade</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[5].grade" formControlName="grade6">
                        <mat-option *ngFor="let grade of gradeList;" value="{{grade}}">{{ grade }}</mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>

            <div class="space-around">
                <mat-form-field appearance="outline" class="subject-width">
                    <mat-label>Enter Subject</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[6].subject" formControlName="sub7">
                        <mat-option  *ngFor="let subject of subjectList;" value="{{subject}}">{{ subject }}</mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field appearance="outline" class="grade-width">
                    <mat-label>Grade</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[6].grade" formControlName="grade7">
                        <mat-option *ngFor="let grade of gradeList;" value="{{grade}}">{{ grade }}</mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>

            <div class="space-around">
                <mat-form-field appearance="outline" class="subject-width">
                    <mat-label>Enter Subject</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[7].subject" formControlName="sub8">
                        <mat-option  *ngFor="let subject of subjectList;" value="{{subject}}">{{ subject }}</mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field appearance="outline" class="grade-width">
                    <mat-label>Grade</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[7].grade" formControlName="grade8">
                        <mat-option *ngFor="let grade of gradeList;" value="{{grade}}">{{ grade }}</mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>

            <div class="space-around">
                <mat-form-field appearance="outline" class="subject-width">
                    <mat-label>Enter Subject</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[8].subject" formControlName="sub9">
                        <mat-option  *ngFor="let subject of subjectList;" value="{{subject}}">{{ subject }}</mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field appearance="outline" class="grade-width">
                    <mat-label>Grade</mat-label>
                    <mat-select [(ngModel)]="resultDetails.ssce_results[8].grade" formControlName="grade9">
                        <mat-option *ngFor="let grade of gradeList;" value="{{grade}}">{{ grade }}</mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>

            
            
            <div class="d-flex justify-content-end py-4">
                <button (click)="submitResult()" mat-raised-button class="btn-color" >
                   
                    <span *ngIf="_submitButton">Proceed</span>
                    <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-white" role="status" aria-hidden="true"></span>
                <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-secondary" role="status" aria-hidden="true"></span>
                <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-success" role="status" aria-hidden="true"></span>
       
                </button>
            </div>
        </form>


    </mat-card>
    
</div>


