import { ScreeningService } from './../services/utme/screening.service';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";

import {tap} from 'rxjs/operators';
import { getUtmeUser, UtmeStateModel } from '../actions/utme.action';


@State<UtmeStateModel>({
    name: 'utme',
    defaults: {
        utmeUser: {}
      
      
    }
  })
  @Injectable()
  export class UtmeState {
    @Selector()
    static utmeUser(state: UtmeStateModel) {
      return state.utmeUser
    }
  
   

//     @Selector()
//   static userDetails(state: UtmeStateModel) {
//     return {
//       name: state.token,
//       email: state.username
//     };
//   }
  
    constructor(
        private _screeningService: ScreeningService,
        private _router: Router) {}
  
    @Action(getUtmeUser)
    getUtme(ctx: StateContext<UtmeStateModel>, action: getUtmeUser) {
      return this._screeningService.checkStatus(action.payload).pipe(
        tap((result: {
           utmeUser: any,
           
         }) => {
          ctx.patchState({
             utmeUser: result,
            // message: result.message
            // username: action.payload.email
          });
        })
      );
    }
  
  
  }