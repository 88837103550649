import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { SideNavDirection } from './admin-sidebar-direction';


@Component({
  selector: 'app-admin-dashboard-sidebar',
  templateUrl: './admin-dashboard-sidebar.component.html',
  styleUrls: ['./admin-dashboard-sidebar.component.scss']
})
export class AdminDashboardSidebarComponent implements OnInit {

  LinkItems: any[] = [
    // {
    //   url: "home",
    //   name: "Dashboard",
    //   icon: "tachometer-alt"

    // },
    {
      url: "admin",
      name: "Admin-Dashboard",
      icon: "tachometer-alt"

    },
    {
     url: "applicants",
     name: "Applicants",
     icon: "graduation-cap"

   },
   {
     url: "utme",
     name: "UTME",
     icon: "school"

   },
   {
     url: "students",
     name: "Students",
     icon: "user-graduate"

   },
   {
     url: "level",
     name: "Level",
     icon: "shapes"

   },
   {
     url: "departments",
     name: "Departments",
     icon: "chalkboard"

   },
   {
    url: "faculties",
    name: "Shcools",
    icon: "book-open"

  },
  {
    url: "session",
    name: "Session",
    icon: "bell"

  },
  {
    url: "semester",
    name: "Semester",
    icon: "bell"

  },
  {
    url: "courses",
    name: "Courses",
    icon: "file-alt"

  },
  {
    url: "course-category",
    name: "Courses Categories",
    icon: "file-alt"

  },
  {
    url: "hostel",
    name: "Hostel",
    icon: "home"

  },
  {
    url: "fees",
    name: "Fees Management",
    icon: "money-bill"

  },
  {
    url: "payment-management",
    name: "Fees Payments",
    icon: "money-bill"

  },

  ];

 showSideNav: Observable<boolean> | undefined ;

 @Input() sidenavTemplateRef: any;
 @Input() duration: number = 0.25;
 @Input() navWidth: number = window.innerWidth;
 @Input() direction: SideNavDirection = SideNavDirection.Left;
 
 constructor(private navService: NavigationService){}

 ngOnInit(): void {
   this.showSideNav = this.navService.getShowNav();
 }

 onSidebarClose() {
   this.navService.setShowNav(false);
 }

 getSideNavBarStyle(showNav: boolean) {
   let navBarStyle: any = {};
   
   navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
   navBarStyle.width = this.navWidth + 'px';
   navBarStyle[this.direction] = (showNav ? 0 : (this.navWidth * -1)) + 'px';
   
   return navBarStyle;
 }

}
