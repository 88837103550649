
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { ModalContainerComponent } from 'angular-bootstrap-md';
import { Observable } from 'rxjs';
import { addSemester, deleteSemester, getSemester, updateSemester } from 'src/app/shared/actions/adminData.action';
import { AdminDataService } from 'src/app/shared/services/admin-services/admin-data.service';
import { AuthState } from 'src/app/shared/state/auth.state';
import Swal from 'sweetalert2';


export interface UserData {
  id: string;
  name: string;
  
}
@Component({
  selector: 'app-semester',
  templateUrl: './semester.component.html',
  styleUrls: ['./semester.component.scss']
})
export class SemesterComponent implements OnInit {

  preloader = false;
  _loader = false;
  _submitButton = true;
  AdminUser =<any>{}
  
  userDetails = <any> {}
  SemesterData = <any> {}
  newData = <any> {}
  DataToDelete = <any> {}
  
  @Select(AuthState.admin)
  User$!: Observable<any>;

  @Select(AuthState.isAuthenticated)
  loggedin$!: Observable<any>;
  isAuth = <boolean> false
  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  validatingForm!: FormGroup;

  displayedColumns: string[] = ['id', 'name', 'createdAt','edit','delete'];
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('edit', { static: true }) edit!: ModalContainerComponent;
  @ViewChild('add', { static: true }) add!: ModalContainerComponent;
  constructor(
    private _store: Store,
    private adminService: AdminDataService,
    private http: HttpClient
  ) {
    this.loggedin$.subscribe(res =>{
      // console.log(res)
      this.isAuth = res
      // console.log(this.isAuth)

        
      })
   
  }

  
  ngOnInit(): void {
    this.User$.subscribe(res =>{
      this.AdminUser = res
      console.log(this.AdminUser)
       this. getAllSemester()
    })
    // throw new Error('Method not implemented.');
    this.validatingForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
       }/*, { updateOn: 'submit' }*/);
    
  }

  get input(){
    return this.validatingForm.controls;
  }


  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  getAllSemester(){
    if(this.isAuth == true){
    this.preloader = true;
    // this._loader = true;
    const URL = 'api/semester'
    this._store
    .dispatch(new getSemester(this.AdminUser,URL)) 
  .subscribe(
  res=> {
  console.log(res.adminData.semester.response)
  
  if(res.adminData.semester.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.semester.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.dataSource = new MatTableDataSource(res.adminData.semester.response)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // this.dataSource = res.adminData.Semester.response
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
}
  }


  updateSemester(){
    
    this._loader = true;
    const URL = 'api/semester/update'
    this.SemesterData.role = this.AdminUser.role
    this.SemesterData.semester_id = this.SemesterData._id
    this._store
    .dispatch(new updateSemester(this.SemesterData,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.semester.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.semester.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.edit.hide();
    this.getAllSemester()
    
    

    // this.dataSource = new MatTableDataSource(res.adminData.Semester.response)
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;

    // this.dataSource = res.adminData.Semester.response
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  
  }

  editSemester(data:any){
    console.log(data)
    this.SemesterData = data

  }

  confirmDelete(data: any){
    this.SemesterData = data

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteSemester()
        // Swal.fire(
        //   'Deleted!',
        //   'record has been deleted.',
        //   'success'
        // )
      }
    })
  

  }

  deleteSemester(){
      this._loader = true;
    const URL = 'api/semester/delete'
    this.DataToDelete.role = this.AdminUser.role
    this.DataToDelete.semester_id = this.SemesterData._id
    this._store
    .dispatch(new deleteSemester(this.DataToDelete,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.semester.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.semester.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.alert.title = "Successfull!"
  this.alert.message = res.adminData.semester.message
  this.alert.icon = "success"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    
    this.getAllSemester()
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
  }

  addSemester(){
    this._loader = true;
    const URL = 'api/semester/store'
    this.newData.role = this.AdminUser.role
    // this.newData.name = this.SemesterData._id
    this._store
    .dispatch(new addSemester(this.newData,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.semester.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.semester.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.add.hide();
    this.getAllSemester()
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )


  }

}


