
  
  

  // FACULTY CRUD
  export class getFaculty {
    static readonly type = '[adminData] get Faculty';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateFaculty {
    static readonly type = '[adminData] update Faculty';
    constructor(
      public payload: { role: string; name: string; faculty_id:string},
      public subUrl: string
      ) {}
  }

  export class deleteFaculty {
    static readonly type = '[adminData] delete Faculty';
    constructor(
      public payload: { role: string; faculty_id: string;},
      public subUrl: string
      ) {}
  }
  export class addFaculty {
    static readonly type = '[adminData] add Faculty';
    constructor(
      public payload: { role: string; name: string;},
      public subUrl: string
      ) {}
  }

  // COURSE CATEGORY CRUD
  export class getCourseCategory {
    static readonly type = '[adminData] get CourseCategory';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateCourseCategory {
    static readonly type = '[adminData] update CourseCategory';
    constructor(
      public payload: { role: string; name: string; course_category_id:string},
      public subUrl: string
      ) {}
  }

  export class deleteCourseCategory {
    static readonly type = '[adminData] delete CourseCategory';
    constructor(
      public payload: { role: string; course_category_id: string;},
      public subUrl: string
      ) {}
  }
  export class addCourseCategory {
    static readonly type = '[adminData] add CourseCategory';
    constructor(
      public payload: { role: string; name: string;},
      public subUrl: string
      ) {}
  }

  // SEMESTER CRUD
  export class getSemester {
    static readonly type = '[adminData] get Semester';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateSemester {
    static readonly type = '[adminData] update Semester';
    constructor(
      public payload: { role: string; name: string; semester_id:string},
      public subUrl: string
      ) {}
  }

  export class deleteSemester {
    static readonly type = '[adminData] delete Semester';
    constructor(
      public payload: { role: string; semester_id: string;},
      public subUrl: string
      ) {}
  }
  export class addSemester {
    static readonly type = '[adminData] add Semester';
    constructor(
      public payload: { role: string; name: string;},
      public subUrl: string
      ) {}
  }

  // DEPARTMENTS CRUD
  export class getDepartment {
    static readonly type = '[adminData] get Department';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateDepartment {
    static readonly type = '[adminData] update Department';
    constructor(
      public payload: { role: string; name: string; department_id:string},
      public subUrl: string
      ) {}
  }

  export class deleteDepartment {
    static readonly type = '[adminData] delete Department';
    constructor(
      public payload: { role: string; department_id: string;},
      public subUrl: string
      ) {}
  }
  export class addDepartment {
    static readonly type = '[adminData] add Department';
    constructor(
      public payload: { role: string; name: string;},
      public subUrl: string
      ) {}
  }

   // COURSES CRUD
   export class getCourse {
    static readonly type = '[adminData] get Course';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateCourse {
    static readonly type = '[adminData] update Course';
    constructor(
      public payload: { },
      public subUrl: string
      ) {}
  }

  export class deleteCourse {
    static readonly type = '[adminData] delete Course';
    constructor(
      public payload: { role: string; course_id: string;},
      public subUrl: string
      ) {}
  }
  export class addCourse {
    static readonly type = '[adminData] add Course';
    constructor(
      public payload:{ },
      public subUrl: string
      ) {}
  }

   // SESSIONS CRUD
   export class getSession {
    static readonly type = '[adminData] get Session';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateSession {
    static readonly type = '[adminData] update Session';
    constructor(
      public payload: { },
      public subUrl: string
      ) {}
  }

  export class deleteSession {
    static readonly type = '[adminData] delete Session';
    constructor(
      public payload: { role: string; session_id: string;},
      public subUrl: string
      ) {}
  }
  export class addSession {
    static readonly type = '[adminData] add Session';
    constructor(
      public payload:{ },
      public subUrl: string
      ) {}
  }

  // STUSENTS CRUD
  export class getStudents {
    static readonly type = '[adminData] get Students';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateStudent {
    static readonly type = '[adminData] update Student';
    constructor(
      public payload: { },
      public subUrl: string
      ) {}
  }

  export class deleteStudent {
    static readonly type = '[adminData] delete Student';
    constructor(
      public payload: { role: string; student_id: string;},
      public subUrl: string
      ) {}
  }
  export class addStudent {
    static readonly type = '[adminData] add Student';
    constructor(
      public payload:{ },
      public subUrl: string
      ) {}
  }

  // APPLICANTS CRUD
  export class getApplicants {
    static readonly type = '[adminData] get Applicants';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateApplicant {
    static readonly type = '[adminData] update Applicant';
    constructor(
      public payload: { },
      public subUrl: string
      ) {}
  }

  export class deleteApplicant {
    static readonly type = '[adminData] delete Applicant';
    constructor(
      public payload: { role: string; applicant_id: string;},
      public subUrl: string
      ) {}
  }
  export class addApplicant {
    static readonly type = '[adminData] add Applicant';
    constructor(
      public payload:{ },
      public subUrl: string
      ) {}
  }

  // UTME CRUD
  export class getUtme {
    static readonly type = '[adminData] get Utmes';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateUtme {
    static readonly type = '[adminData] update Utme';
    constructor(
      public payload: { },
      public subUrl: string
      ) {}
  }

  export class deleteUtme {
    static readonly type = '[adminData] delete Utme';
    constructor(
      public payload: { role: string; utme_id: string;},
      public subUrl: string
      ) {}
  }
  export class addUtme {
    static readonly type = '[adminData] add Utme';
    constructor(
      public payload:{ },
      public subUrl: string
      ) {}
  }

  // HOSTEL CRUD
  export class getHostel {
    static readonly type = '[adminData] get Hostels';
    constructor(
      public payload: { role: string;},
      public subUrl: string
      ) {}
  }
  
  export class updateHostel {
    static readonly type = '[adminData] update Hostel';
    constructor(
      public payload: { },
      public subUrl: string
      ) {}
  }

  export class deleteHostel {
    static readonly type = '[adminData] delete Hostel';
    constructor(
      public payload: { role: string; hostel_id: string;},
      public subUrl: string
      ) {}
  }
  export class addHostel {
    static readonly type = '[adminData] add Hostel';
    constructor(
      public payload:{ },
      public subUrl: string
      ) {}
  }

  