import { AdminDataState } from './shared/state/adminData.state';
import { AdminGuard } from './Middleware/admin.guard';
import { SharedModule } from './shared/shared.module';
import { AdminDashboardModule } from './layouts/admin-dashboard/admin-dashboard.module';
import { CheckerState } from './shared/state/checker.state';
import { UtmeState } from './shared/state/utme.state';
import { DashboardModule } from './layouts/dashboard/dashboard.module';

import { GuestGuard } from './Middleware/guest.guard';
import { AuthGuard } from './Middleware/auth.guard';
import { AuthService } from './shared/services/auth/auth.service';

import {NgxsModule} from '@ngxs/store';
import { NgxsStoragePluginModule} from '@ngxs/storage-plugin'
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';




import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { MDBBootstrapModule } from 'angular-bootstrap-md';


import { MainModule } from './layouts/main/main.module';


import { TokenInterceptorService } from './shared/services/auth/token-interceptor.service';
import { AuthState } from './shared/state/auth.state';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { StudentDataState } from './shared/state/studentData.state';













@NgModule({
  declarations: [
    
    AppComponent,
         
          
         
          
         
         
         
          
  ],
  imports: [
    BrowserModule,
    NgxsModule.forRoot([AuthState,UtmeState,AdminDataState,StudentDataState]),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.token','auth.role', 'auth.user_id','auth.userinfo','utme.utmeUser','adminData','studentData']
      
    }),
    NgxsResetPluginModule.forRoot(),
    HttpClientModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    MainModule,
    DashboardModule,
    AdminDashboardModule,
    ReactiveFormsModule,
    FormsModule,

    HttpClientModule,
    

  ],
  providers: [
    FormsModule,
    ReactiveFormsModule,
    AuthService,
    AuthGuard, 
    GuestGuard,
    AdminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }     
  ],
  
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
