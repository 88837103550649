import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { addApplicant, addCourse, addCourseCategory, addDepartment, addFaculty, addHostel, addSemester, addSession, addStudent, addUtme,  deleteApplicant, deleteCourse, deleteCourseCategory, deleteDepartment, deleteFaculty, deleteHostel, deleteSemester, deleteSession, deleteStudent, deleteUtme, getApplicants, getCourse, getCourseCategory, getDepartment, getFaculty, getHostel, getSemester, getSession, getStudents, getUtme, updateApplicant, updateCourse, updateCourseCategory, updateDepartment, updateFaculty, updateHostel, updateSemester, updateSession, updateStudent, updateUtme } from "../actions/adminData.action";

import {tap} from 'rxjs/operators';
import { AdminDataService } from '../services/admin-services/admin-data.service';
import { getStudent } from '../actions/studentData.action';
import { AdminDataStateModel } from '../models/adminData.model';
import { addLevel, deleteLevel, getLevel, updateLevel } from '../actions/level/adminData.level.action';
import { Level } from './level/adminData.level.state';
import { Fees } from './fees/adminData.fees.state';
import { addFees, deleteFees, getFees, updateFees } from '../actions/fees/adminData.fees.action';
import { FeePayments } from './fee-payments/adminData.fee-payments.state';
import { addFeePayments, deleteFeePayments, getFeePayments, updateFeePayments } from '../actions/fee-payments/adminData.fee-payments.action';

@State<AdminDataStateModel>({
    name: 'adminData',
    defaults: {
      level: null,
      courses: null,
    session: null,
    faculty:  null,
    course_category: null,
    semester: null ,
    department: null ,
    utme: null ,
    students: null ,
    applicants: null ,
    hostel: null ,
    fees: null ,
    fee_payments: null ,
    }
  })


  @Injectable()
  export class AdminDataState {
    @Selector()
    static levelList(state: AdminDataStateModel): string | null {
      return state.level;
    }
    @Selector()


    static adminData(state: AdminDataStateModel) {
      return state
    }

   
    
    constructor(
        private adminDataService: AdminDataService,
        private _router: Router,
        // private _level: Level
        
        ) {}
_level = new Level(this.adminDataService,this._router);
_fees = new Fees(this.adminDataService,this._router);
_feePayments = new FeePayments(this.adminDataService,this._router);

  // LEVEL ACTIONS
    @Action(getLevel)
    getlevel(ctx: StateContext<AdminDataStateModel>, action: getLevel){
      return this._level.getlevel(ctx, action);
    }

    @Action(updateLevel)
    updatelevel(ctx: StateContext<AdminDataStateModel>, action: updateLevel){
      return this._level.updatelevel(ctx, action);
    }

    @Action(addLevel)
    addlevel(ctx: StateContext<AdminDataStateModel>, action: addLevel){
      return this._level.addlevel(ctx, action);
    }

    @Action(deleteLevel)
    deletelevel(ctx: StateContext<AdminDataStateModel>, action: deleteLevel){
      return this._level.deletelevel(ctx, action);
    }
// FEES ACTIONS
@Action(getFees)
getfees(ctx: StateContext<AdminDataStateModel>, action: getFees){
  return this._fees.getfees(ctx, action);
}

@Action(updateFees)
updatefees(ctx: StateContext<AdminDataStateModel>, action: updateFees){
  return this._fees.updatefees(ctx, action);
}

@Action(addFees)
addfees(ctx: StateContext<AdminDataStateModel>, action: addFees){
  return this._fees.addfees(ctx, action);
}

@Action(deleteFees)
deletefees(ctx: StateContext<AdminDataStateModel>, action: deleteFees){
  return this._fees.deletefees(ctx, action);
}


// FEE-PAYMENTS ACTIONS
@Action(getFeePayments)
getfeePayments(ctx: StateContext<AdminDataStateModel>, action: getFeePayments){
  return this._feePayments.getfeePayments(ctx, action);
}

@Action(updateFeePayments)
updatefeePayments(ctx: StateContext<AdminDataStateModel>, action: updateFeePayments){
  return this._feePayments.updatefeePayments(ctx, action);
}

@Action(addFeePayments)
addfeePayments(ctx: StateContext<AdminDataStateModel>, action: addFeePayments){
  return this._feePayments.addfeePayments(ctx, action);
}

@Action(deleteFeePayments)
deletefeePayments(ctx: StateContext<AdminDataStateModel>, action: deleteFeePayments){
  return this._feePayments.deletefeePayments(ctx, action);
}

// FACULTIES ACTIONS
    @Action(getFaculty)
    getFaculty(ctx: StateContext<AdminDataStateModel>, action: getFaculty) {
      return this.adminDataService.getAllAdminData(action.payload,action.subUrl).pipe(
        tap((result: {
        //    name: string,
        //    date: string
         }) => {
          ctx.patchState({
            faculty: result,
            
          });
        })
      );
    }

    @Action(updateFaculty)
    updateFaculty(ctx: StateContext<AdminDataStateModel>, action: updateFaculty) {
      return this.adminDataService.updateAdminData(action.payload,action.subUrl).pipe(
        tap((result: {
        //    name: string,
        //    date: string
         }) => {
          ctx.patchState({
            faculty: result,
            
          });
        })
      );
    }

    @Action(addFaculty)
    addFaculty(ctx: StateContext<AdminDataStateModel>, action: addFaculty) {
      return this.adminDataService.addAdminData(action.payload,action.subUrl).pipe(
        tap((result: {
        //    name: string,
        //    date: string
         }) => {
          ctx.patchState({
            faculty: result,
            
          });
        })
      );
    }

    @Action(deleteFaculty)
    deleteFaculty(ctx: StateContext<AdminDataStateModel>, action: deleteFaculty) {
      return this.adminDataService.deleteAdminData(action.payload,action.subUrl).pipe(
        tap((result: {
        //    name: string,
        //    date: string
         }) => {
          ctx.patchState({
            faculty: result,
            
          });
        })
      );
    }

    // SEMESTER ACTIONS

    @Action(getSemester)
    getSemester(ctx: StateContext<AdminDataStateModel>, action: getSemester) {
      return this.adminDataService.getAllAdminData(action.payload,action.subUrl).pipe(
        tap((result: {
        //    name: string,
        //    date: string
         }) => {
          ctx.patchState({
            semester: result,
            
          });
        })
      );
    }

    @Action(updateSemester)
    updateSemester(ctx: StateContext<AdminDataStateModel>, action: updateSemester) {
      return this.adminDataService.updateAdminData(action.payload,action.subUrl).pipe(
        tap((result: {
        //    name: string,
        //    date: string
         }) => {
          ctx.patchState({
            semester: result,
            
          });
        })
      );
    }

    @Action(addSemester)
    addSemester(ctx: StateContext<AdminDataStateModel>, action: addSemester) {
      return this.adminDataService.addAdminData(action.payload,action.subUrl).pipe(
        tap((result: {
        //    name: string,
        //    date: string
         }) => {
          ctx.patchState({
            semester: result,
            
          });
        })
      );
    }

    @Action(deleteSemester)
    deleteSemester(ctx: StateContext<AdminDataStateModel>, action: deleteSemester) {
      return this.adminDataService.deleteAdminData(action.payload,action.subUrl).pipe(
        tap((result: {
        //    name: string,
        //    date: string
         }) => {
          ctx.patchState({
            semester: result,
            
          });
        })
      );
    }

     // COURSE CATEGORY ACTIONS

     @Action(getCourseCategory)
     getCourseCategory(ctx: StateContext<AdminDataStateModel>, action: getCourseCategory) {
       return this.adminDataService.getAllAdminData(action.payload,action.subUrl).pipe(
         tap((result: {
         //    name: string,
         //    date: string
          }) => {
           ctx.patchState({
             course_category: result,
             
           });
         })
       );
     }
 
     @Action(updateCourseCategory)
     updateCourseCategory(ctx: StateContext<AdminDataStateModel>, action: updateCourseCategory) {
       return this.adminDataService.updateAdminData(action.payload,action.subUrl).pipe(
         tap((result: {
         //    name: string,
         //    date: string
          }) => {
           ctx.patchState({
             course_category: result,
             
           });
         })
       );
     }
 
     @Action(addCourseCategory)
     addCourseCategory(ctx: StateContext<AdminDataStateModel>, action: addCourseCategory) {
       return this.adminDataService.addAdminData(action.payload,action.subUrl).pipe(
         tap((result: {
         //    name: string,
         //    date: string
          }) => {
           ctx.patchState({
             course_category: result,
             
           });
         })
       );
     }
 
     @Action(deleteCourseCategory)
     deleteCourseCategory(ctx: StateContext<AdminDataStateModel>, action: deleteCourseCategory) {
       return this.adminDataService.deleteAdminData(action.payload,action.subUrl).pipe(
         tap((result: {
         //    name: string,
         //    date: string
          }) => {
           ctx.patchState({
             course_category: result,
             
           });
         })
       );
     }


    // DEPARTMENTS ACTIONS

    @Action(getDepartment)
    getDepartment(ctx: StateContext<AdminDataStateModel>, action: getDepartment) {
      return this.adminDataService.getAllAdminData(action.payload,action.subUrl).pipe(
        tap((result: {
        //    name: string,
        //    date: string
         }) => {
          ctx.patchState({
            department: result,
            
          });
        })
      );
    }

    @Action(updateDepartment)
    updateDepartment(ctx: StateContext<AdminDataStateModel>, action: updateDepartment) {
      return this.adminDataService.updateAdminData(action.payload,action.subUrl).pipe(
        tap((result: {
        //    name: string,
        //    date: string
         }) => {
          ctx.patchState({
            department: result,
            
          });
        })
      );
    }

    @Action(addDepartment)
    addDepartment(ctx: StateContext<AdminDataStateModel>, action: addDepartment) {
      return this.adminDataService.addAdminData(action.payload,action.subUrl).pipe(
        tap((result: {
        //    name: string,
        //    date: string
         }) => {
          ctx.patchState({
            department: result,
            
          });
        })
      );
    }

    @Action(deleteDepartment)
    deleteDepartment(ctx: StateContext<AdminDataStateModel>, action: deleteDepartment) {
      return this.adminDataService.deleteAdminData(action.payload,action.subUrl).pipe(
        tap((result: {
        //    name: string,
        //    date: string
         }) => {
          ctx.patchState({
            department: result,
            
          });
        })
      );
    }

// COURSES ACTIONS

@Action(getCourse)
getCourse(ctx: StateContext<AdminDataStateModel>, action: getCourse) {
  return this.adminDataService.getAllAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        courses: result,
        
      });
    })
  );
}

@Action(updateCourse)
updateCourse(ctx: StateContext<AdminDataStateModel>, action: updateCourse) {
  return this.adminDataService.updateAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        courses: result,
        
      });
    })
  );
}

@Action(addCourse)
addCourse(ctx: StateContext<AdminDataStateModel>, action: addCourse) {
  return this.adminDataService.addAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        courses: result,
        
      });
    })
  );
}

@Action(deleteCourse)
deleteCourse(ctx: StateContext<AdminDataStateModel>, action: deleteCourse) {
  return this.adminDataService.deleteAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        courses: result,
        
      });
    })
  );
}


// SESSIONS ACTIONS

@Action(getSession)
getSession(ctx: StateContext<AdminDataStateModel>, action: getSession) {
  return this.adminDataService.getAllAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        session: result,
        
      });
    })
  );
}

@Action(updateSession)
updateSession(ctx: StateContext<AdminDataStateModel>, action: updateSession) {
  return this.adminDataService.updateAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        session: result,
        
      });
    })
  );
}

@Action(addSession)
addSession(ctx: StateContext<AdminDataStateModel>, action: addSession) {
  return this.adminDataService.addAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        session: result,
        
      });
    })
  );
}

@Action(deleteSession)
deleteSession(ctx: StateContext<AdminDataStateModel>, action: deleteSession) {
  return this.adminDataService.deleteAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        session: result,
        
      });
    })
  );
}


// STUDENTS ACTIONS

@Action(getStudents)
getStudent(ctx: StateContext<AdminDataStateModel>, action: getStudents) {
  return this.adminDataService.getAllAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        students: result,
        
      });
    })
  );
}

@Action(updateStudent)
updateStudent(ctx: StateContext<AdminDataStateModel>, action: updateStudent) {
  return this.adminDataService.updateAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        students: result,
        
      });
    })
  );
}

@Action(addStudent)
addStudent(ctx: StateContext<AdminDataStateModel>, action: addStudent) {
  return this.adminDataService.addAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        students: result,
        
      });
    })
  );
}

@Action(deleteStudent)
deleteStudent(ctx: StateContext<AdminDataStateModel>, action: deleteStudent) {
  return this.adminDataService.deleteAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        students: result,
        
      });
    })
  );
}


// APPLICANTS ACTIONS

@Action(getApplicants)
getApplicant(ctx: StateContext<AdminDataStateModel>, action: getApplicants) {
  return this.adminDataService.getAllAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        applicants: result,
        
      });
    })
  );
}

@Action(updateApplicant)
updateApplicant(ctx: StateContext<AdminDataStateModel>, action: updateApplicant) {
  return this.adminDataService.updateAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        applicants: result,
        
      });
    })
  );
}

@Action(addApplicant)
addApplicant(ctx: StateContext<AdminDataStateModel>, action: addApplicant) {
  return this.adminDataService.addAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        applicants: result,
        
      });
    })
  );
}

@Action(deleteApplicant)
deleteApplicant(ctx: StateContext<AdminDataStateModel>, action: deleteApplicant) {
  return this.adminDataService.deleteAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        applicants: result,
        
      });
    })
  );
}


// UTME ACTIONS

@Action(getUtme)
getUtme(ctx: StateContext<AdminDataStateModel>, action: getUtme) {
  return this.adminDataService.getAllAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        utme: result,
        
      });
    })
  );
}

@Action(updateUtme)
updateUtme(ctx: StateContext<AdminDataStateModel>, action: updateUtme) {
  return this.adminDataService.updateAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        utme: result,
        
      });
    })
  );
}

@Action(addUtme)
addUtme(ctx: StateContext<AdminDataStateModel>, action: addUtme) {
  return this.adminDataService.addAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        utme: result,
        
      });
    })
  );
}

@Action(deleteUtme)
deleteUtme(ctx: StateContext<AdminDataStateModel>, action: deleteUtme) {
  return this.adminDataService.deleteAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        utme: result,
        
      });
    })
  );
}




// HOSTEL ACTIONS

@Action(getHostel)
getHostel(ctx: StateContext<AdminDataStateModel>, action: getHostel) {
  return this.adminDataService.getAllAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        hostel: result,
        
      });
    })
  );
}

@Action(updateHostel)
updateHostel(ctx: StateContext<AdminDataStateModel>, action: updateHostel) {
  return this.adminDataService.updateAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        hostel: result,
        
      });
    })
  );
}

@Action(addHostel)
addHostel(ctx: StateContext<AdminDataStateModel>, action: addHostel) {
  return this.adminDataService.addAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        hostel: result,
        
      });
    })
  );
}

@Action(deleteHostel)
deleteHostel(ctx: StateContext<AdminDataStateModel>, action: deleteHostel) {
  return this.adminDataService.deleteAdminData(action.payload,action.subUrl).pipe(
    tap((result: {
    //    name: string,
    //    date: string
     }) => {
      ctx.patchState({
        hostel: result,
        
      });
    })
  );
}


  

  }

// function getApplicant(getApplicant: any) {
//   throw new Error('Function not implemented.');
// }
