<div class="container my-5 pb-5">
    <div class="row">
       

        <div class="col-md-12">
            <div class="card">
                <div class="d-flex flex-row justify-content-around orange lighten-2 py-4">
                    <a style="font-weight: bold;" >Student Data </a>
                    <!-- <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg" alt="" height="70" class="rounded-circle z-depth-0">
                    <a class="waves-light" mdbWavesEffect>Edit Profile</a> -->
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-12 font-weight-bold text-danger">Please confirm your UTME details below before you proceed with the Application!</div>
                   
                </div>
                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Full Name</div>
                    <div class="col-4"> {{Applicant.first_name}} {{Applicant.mid_name}} {{Applicant.last_name}} </div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Matriculation No</div>
                    <div class="col-4">{{Applicant.reg_no}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Date of Birth</div>
                    <div class="col-4">{{Applicant.dob}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Email</div>
                    <div class="col-4">{{Applicant.email}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">First Choice Institution</div>
                    <div class="col-4">{{Applicant.choice_institution_1}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">First Choice Course</div>
                    <div class="col-4">{{Applicant.choice_course_1}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Second Choice Institution</div>
                    <div class="col-4">{{Applicant.choice_institution_2}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Second Choice Course</div>
                    <div class="col-4">{{Applicant.choice_course_2}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">UTME Score</div>
                    <div class="col-4">320</div>
                </div>
                <div class="d-flex flex-row justify-content-center my-4">
                   
                    <div class="col-4">
                        <button type="button" (click)="apply()" mdbBtn color="primary" mdbWavesEffect>Apply</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>