import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ScreeningService } from 'src/app/shared/services/utme/screening.service';
import { UtmeState } from 'src/app/shared/state/utme.state';
import Swal from 'sweetalert2';
import { AuthState } from 'src/app/shared/state/auth.state';

@Component({
  selector: 'app-application-upload',
  templateUrl: './application-upload.component.html',
  styleUrls: ['./application-upload.component.scss']
})
export class ApplicationUploadComponent implements OnInit {
  preloader = false;
  _loader = false;
  _submitButton = true;
   Applicant =<any>{}
  userPayload = <any> {}
  userDetails = <any> {}
  @Select(AuthState.applicant)
  User$!: Observable<any>;
  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }
  resultDetails = <any> {
    reg_no: "",
    role: "",
    "ssce_results": [
      {
        subject: "General Mathematics",
        grade: ""
      },
      {
        subject: "English Language",
        grade: ""
      },
      {
        subject: "",
        grade: ""
      },
      {
        subject: "",
        grade: ""
      },
      {
        subject: "",
        grade: ""
      },
      {
        subject: "",
        grade: ""
      },
      {
        subject: "",
        grade: ""
      },
      {
        subject: "",
        grade: ""
      },
      {
        subject: "",
        grade: ""
      }
    ]

  }
  formdata = new FormGroup({
    reg_no: new FormControl('', Validators.required),
    sub1: new FormControl('', Validators.required),
    grade1: new FormControl('', Validators.required),
    sub2: new FormControl('', Validators.required),
    grade2: new FormControl('', Validators.required),
    sub3: new FormControl('', Validators.required),
    grade3: new FormControl('', Validators.required),
    sub4: new FormControl('', Validators.required),
    grade4: new FormControl('', Validators.required),
    sub5: new FormControl('', Validators.required),
    grade5: new FormControl('', Validators.required),
    sub6: new FormControl('', Validators.required),
    grade6: new FormControl('', Validators.required),
    sub7: new FormControl('', Validators.required),
    grade7: new FormControl('', Validators.required),
    sub8: new FormControl('', Validators.required),
    grade8: new FormControl('', Validators.required),
    sub9: new FormControl('', Validators.required),
    grade9: new FormControl('', Validators.required),
   
  });
  constructor(
    public getApplicantService: ScreeningService,
    public uploadService: ScreeningService,
    public _router: Router
    ) { }

  ngOnInit(): void {

    this.User$.subscribe(res =>{
      this.Applicant = res
      console.log(this.Applicant)
      this.getApplicant()
    })
  }

  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }
  subjectList = [
    'Biology',
    'Chemistry',
    'Physics',
    'Economics',
    'Computer Science',
    'Geography',
    'Further Mathematics',
    'Financial Accounting'
  ]
  gradeList = ['A1', 'B2', 'B3', 'C4', 'C5', 'C6', 'D7', 'E8', 'F9'];

  submitResult(){
    this._submitButton = false;
    this._loader = true;
    this.resultDetails.reg_no = this.userDetails.reg_no
    this.resultDetails.role = this.Applicant.role
    
    this.uploadService.uploadResult(this.resultDetails)
    .subscribe(
      res=>{
        
        console.log(res)
        if(res.message == "Record not found!"){
        
          this.alert.title = "Submission Error!"
          this.alert.message = res.message
          this.alert.icon = "error"
  
          this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
          this._loader = false;
          this._submitButton = true;
         }else {
                    
          this.alert.title = "Submission Succesful!"
          this.alert.message = res.message
          this.alert.icon = "success"
  
          this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
          this._loader = false;
          this._submitButton = true;
          this._router.navigateByUrl('upload-credentials'); 
        }
      },
      err => {
        
      
        console.log(err)
        this.alert.title = "Server Error!"
        this.alert.message = `There was an error processing this request`
        this.alert.icon = "error"

        this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
        this._loader = false;
          this._submitButton = true;
      }
    )
  // console.log(this.resultDetails)
  }

  getApplicant(){
    this.preloader = true;
    // this._loader = true;
    this.userPayload.user_id = this.Applicant.user_id
    this.userPayload.role = this.Applicant.role
this.getApplicantService.getApplicant(this.userPayload)
.subscribe(
  res=> {
console.log(res)

if(res.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.message
  this.alert.icon = "error"

  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
 }else {
  this.userDetails = res.response
  this.preloader = false;
 }

  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"

    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
)
    


  }

}
