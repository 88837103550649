import { SharedModule } from './../../shared/shared.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { AdminDashboardComponent } from './admin-dashboard.component';
import { AdminComponent } from 'src/app/admin-dashboard/admin/admin.component';
import { AdminDashboardFooterComponent } from 'src/app/shared/component/admin-dashboard-footer/admin-dashboard-footer.component';
import { AdminDashboardSidebarComponent } from 'src/app/shared/component/admin-dashboard-sidebar/admin-dashboard-sidebar.component';
import { AdminDashboardNavbarComponent } from 'src/app/shared/component/admin-dashboard-navbar/admin-dashboard-navbar.component';
import { UtmeComponent } from 'src/app/admin-dashboard/utme/utme.component';
import { ApplicantsComponent } from 'src/app/admin-dashboard/applicants/applicants.component';
import { LevelComponent } from 'src/app/admin-dashboard/level/level.component';
import { FacultyComponent } from 'src/app/admin-dashboard/faculty/faculty.component';
import { StudentsComponent } from 'src/app/admin-dashboard/students/students.component';
import { DepartmentsComponent } from 'src/app/admin-dashboard/departments/departments.component';
import { CoursesComponent } from 'src/app/admin-dashboard/courses/courses.component';
import { SessionComponent } from 'src/app/admin-dashboard/session/session.component';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SemesterComponent } from 'src/app/admin-dashboard/semester/semester.component';
import { CourseCategoryComponent } from 'src/app/admin-dashboard/course-category/course-category.component';
import { HostelComponent } from 'src/app/admin-dashboard/hostel/hostel.component';
import { FeesComponent } from 'src/app/admin-dashboard/fees/fees.component';
import { FeePaymentsComponent } from 'src/app/admin-dashboard/fee-payments/fee-payments.component';
import { RoomSumPipe } from 'src/app/pipe/room-sum.pipe';




@NgModule({
  declarations: [
    AdminDashboardComponent,
    AdminComponent,
    AdminDashboardFooterComponent,
    AdminDashboardSidebarComponent,
    AdminDashboardNavbarComponent,
    UtmeComponent,
          ApplicantsComponent,
          LevelComponent,
          FacultyComponent,
          StudentsComponent,
          DepartmentsComponent,
          SessionComponent,
          CoursesComponent,
          HostelComponent,
          SemesterComponent,
          CourseCategoryComponent,
          FeesComponent,
          FeePaymentsComponent,
          RoomSumPipe,

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    // SharedModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule, 
    MatSortModule,
    MatPaginatorModule,
  
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class AdminDashboardModule { }
