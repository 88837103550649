import { ElearningService } from './../../shared/services/elearning.service';
import { getStudent, getStudentCourses } from './../../shared/actions/studentData.action';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/shared/state/auth.state';
import { StudentDataState } from 'src/app/shared/state/studentData.state';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-registered-courses',
  templateUrl: './registered-courses.component.html',
  styleUrls: ['./registered-courses.component.scss']
})
export class RegisteredCoursesComponent implements OnInit {
  form!: FormGroup;
  preloader = false;
  _loader = false;
  _submitButton = true;
  StudentUser =<any>{}
  StudentData =<any>{}
  Payload = <any> {}
  CourseDetails = <any> {}
  selectedCourses = <any>[]
  TotalUnit = <number> 0
  paymentDetails = <any>[]
  StudentDetails = <any>[]

  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  @Select(AuthState.student)
  User$!: Observable<any>;

  @Select(StudentDataState.StudentData)
  Data$!: Observable<any>;

  
  @Select(StudentDataState.paymentDetails)
  paymentDetails$!: Observable<any>;

  displayedColumns: string[] = ['code', 'title', 'category','unit','elearning'];
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  constructor( 
    private _http: HttpClient,
    private _store: Store,
    private fb: FormBuilder,
    private _elearnService:ElearningService) {
    this.User$.subscribe(res =>{
      this.StudentUser = res
      
      console.log(this.StudentUser)
      
      
    })

    this.paymentDetails$.subscribe(res =>{
      this.paymentDetails = res
      
      console.log(this.StudentUser)
      
      
    })

    this.form = this.fb.group({
      checkArray: this.fb.array([])
    })

    
   }

elearningCourses = <any> []


getElearning(){
  this._elearnService.getElearnCourses().subscribe(res => {
    console.log(res)
    this.elearningCourses = res
  
  },
  err=>{
    console.log(err) 
  });
  
  // console.log(this.elearningCourses)
}

  ngOnInit(): void {

    this.Data$.subscribe(res =>{
      this.StudentData = res.response.registered_courses[0].courses
      this.StudentDetails = res.response
      
      console.log(this.StudentData)      
      this.getElearning()
    })
    // this.getCourses();
  
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
   setTimeout(()=>{
    if(this.StudentUser != null){
      this.Data$.subscribe(res =>{
        
        this.StudentData = res.response.registered_courses[0].courses
        // console.log(this.AdminData.Fees.response)
        
       
  
  if(this.StudentData){
      this.dataSource = new MatTableDataSource(this.StudentData)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } 
      })
     }
   },0)
  }

  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  

  getCourses(){
    this.preloader = true;
    // this._loader = true;
    const COURSES_URL = 'api/course/student-courses';
    this.Payload.dept_id = this.StudentData.dept._id
    this.Payload.level_id = this.StudentData.level._id
    this.Payload.role = this.StudentUser.role
   
    this._store
    .dispatch([
      new getStudentCourses(this.Payload,COURSES_URL),
      // new getCourseCategory(this.AdminUser,COURSE_CATEGORY_URL),
      // new getLevel(this.AdminUser,LEVEL_URL),
      // new getFaculty(this.AdminUser,FACULTY_URL),
      // new getSemester(this.AdminUser,SEMESTER_URL),
      // new getDepartment(this.AdminUser,DEPARTMENT_URL),
    ]) 
  .subscribe(
  res=> {
  console.log(res)
  console.log(res[0].studentData.studentCourses.response)
  
  if(res[0].studentData.studentCourses.message){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res[0].studentData.studentDetails.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.dataSource = new MatTableDataSource(res[0].studentData.studentCourses.response)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  
  }

  goToElearning(data:any){
    // console.log(this.StudentDetails)
    
//     this._http.get<any>("https://mubackend.herokuapp.com/api/get-resources/37wtwz5kapkvonskl7z6ljuo10qduc")
//     .subscribe(
//       res =>{
// console.log(res)
//       },
//       err => {
//         console.log(err)
//       }
//     )
    window.location.href = `https://elearn-mu.herokuapp.com/open_course/${this.StudentDetails.mat_no}/${data}`;
  }


  

}
