
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { ModalContainerComponent } from 'angular-bootstrap-md';
import { Observable } from 'rxjs';
import { addUtme, deleteUtme, getUtme, updateUtme } from 'src/app/shared/actions/adminData.action';
import { AdminDataService } from 'src/app/shared/services/admin-services/admin-data.service';
import { AdminDataState } from 'src/app/shared/state/adminData.state';
import { AuthState } from 'src/app/shared/state/auth.state';
import Swal from 'sweetalert2';

export interface UserData {
  id: string;
  name: string;
  
}

@Component({
  selector: 'app-utme',
  templateUrl: './utme.component.html',
  styleUrls: ['./utme.component.scss']
})
export class UtmeComponent implements OnInit {

  
  preloader = false;
  _loader = false;
  _submitButton = true;
  AdminUser =<any>{}
  AdminData =<any>{}
  userDetails = <any> {}
  UtmeData = <any> {}
  newData = <any> {
    Utme_category: {
      name: "",
      _id: ""
    }
  }
  DataToDelete = <any> {}

departments = <any> []
levels = <any> {}
Utme_category = <any> {}
selected = "test"
  
  @Select(AuthState.admin)
  User$!: Observable<any>;
  @Select(AdminDataState.adminData)
  Data$!: Observable<any>;
  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }

  validatingForm!: FormGroup;

  displayedColumns: string[] = ['id', 'reg_no', 'first_name', 'mid_name', 'last_name','status','edit','delete'];
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('edit', { static: true }) edit!: ModalContainerComponent;
  @ViewChild('add', { static: true }) add!: ModalContainerComponent;
  constructor(
    private _store: Store,
    private adminService: AdminDataService,
    private http: HttpClient
  ) {
    
    this.User$.subscribe(res =>{
      this.AdminUser = res
      console.log(this.AdminUser)
      //  this. getAllUtme()
    })

    
  }

  
  ngOnInit(): void {
    
    
   
    // throw new Error('Method not implemented.');
    // this.validatingForm = new FormGroup({
    //   title: new FormControl(null, [Validators.required]),
    //   code: new FormControl(null, [Validators.required]),
    //   unit: new FormControl(null, [Validators.required]),
    //   level_id: new FormControl(null, [Validators.required]),
    //   Utme_category_id: new FormControl(null, [Validators.required]),
    //   dept_id: new FormControl(null, [Validators.required]),
    //    }/*, { updateOn: 'submit' }*/);
    

      
      
    
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  
    setTimeout(()=> {
    this.Data$.subscribe(res =>{
      
      this.AdminData = res    
  
  if(this.AdminData.utme != null){
    this.dataSource = new MatTableDataSource(this.AdminData.utme.response)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

    })

  },0)

  }

  get input(){
    return this.validatingForm.controls;
  }


  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  getAllUtme(){
    this.preloader = true;
    // this._loader = true;
    const URL = 'api/utme'
    this._store
    .dispatch(new getUtme(this.AdminUser,URL)) 
  .subscribe(
  res=> {
  console.log(res.adminData.utme.response)
  
  if(res.adminData.utme.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.utme.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.dataSource = new MatTableDataSource(res.adminData.utme.response)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // this.dataSource = res.adminData.Utme.response
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  
  }


  updateUtme(){
    
    this._loader = true;
    const URL = 'api/utme/update'
    // this.UtmeData.role = this.AdminUser.role
    // this.UtmeData.Utme_id = this.UtmeData._id
    this.UtmeData.role = this.AdminUser.role
    this.UtmeData.last_name = this.newData.last_name
    this.UtmeData.first_name = this.newData.first_name
    this.UtmeData.mid_name = this.newData.mid_name
    this.UtmeData.email = this.newData.email
    this.UtmeData.dob = this.newData.dob
    this.UtmeData.choice_course_1 = this.newData.choice_course_1
    this.UtmeData.choice_course_2 = this.newData.choice_course_2
    this.UtmeData.choice_institution_1 = this.newData.choice_institution_1
    this.UtmeData.choice_institution_2 = this.newData.choice_institution_2
    
    this.UtmeData.utme_id = this.newData._id
    this._store
    .dispatch(new updateUtme(this.UtmeData,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.utme.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.utme.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.edit.hide();
    this.getAllUtme()
    
    

      this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
    
  
  
  }

  editUtme(data:any){
    console.log(data)
    this.newData = data

  }

  confirmDelete(data: any){
    this.UtmeData = data

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteUtme()
        // Swal.fire(
        //   'Deleted!',
        //   'record has been deleted.',
        //   'success'
        // )
      }
    })
  

  }

  deleteUtme(){
      this._loader = true;
    const URL = 'api/utme/delete'
    this.DataToDelete.role = this.AdminUser.role
    this.DataToDelete.utme_id = this.UtmeData._id
    this._store
    .dispatch(new deleteUtme(this.DataToDelete,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.utme.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.utme.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    this.alert.title = "Successfull!"
  this.alert.message = res.adminData.utme.message
  this.alert.icon = "success"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    
    this.getAllUtme()
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )
  }

  addUtme(){
    this._loader = true;
    const URL = 'api/utme/store'
    this.newData.role = this.AdminUser.role
    // this.newData.name = this.UtmeData._id
    this._store
    .dispatch(new addUtme(this.newData,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.adminData.utme.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.adminData.utme.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }
  if(res.adminData.utme.message == "An instance already exist!"){
          
    this.alert.title = "Error!"
    this.alert.message = res.adminData.utme.message
    this.alert.icon = "error"
    
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
    
    }
  else {
    this.add.hide();
    this.getAllUtme()
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )


  }

}