import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ElearningService {

  constructor(
    private http: HttpClient,
    private _router: Router,
    
    ) { }

  getElearnCourses (){
    // return this.http.post<any>(this.APIUrl  + 'login.php', User)
    return this.http.get<any>('https://elearn-mu.herokuapp.com/courses')
  }

  accessElearnCourses (data: any){
    // return this.http.post<any>(this.APIUrl  + 'login.php', User)
    return this.http.get<any>('https://elearn-mu.herokuapp.com/courses')
  }
}
