<mdb-navbar SideClass="navbar navbar-expand-lg navbar-light py-8">

  <!-- Navbar brand -->

  <mdb-navbar-brand class="my-2">
    <a class="navbar-brand" routerLink="/"> <img src="assets/images/logo/erewa-logo.png" height="70" alt=""> <span
        class="h6">Tony G. Erewa</span> </a>
  </mdb-navbar-brand>

  <!-- Collapsible content -->
  <links>

    <!-- Links -->
    <ul class="navbar-nav mx-auto py-8 nav-text-typography">
      <li class="nav-item">
        <a routerLink="/" class="nav-link waves-light" mdbWavesEffect>Home</a>
      </li>
      <li class="nav-item">
        <a routerLink="/checker" class="nav-link waves-light" mdbWavesEffect>Prospective</a>
      </li>
      <li class="nav-item">
        <!-- <a class="nav-link waves-light" mdbWavesEffect>Undergraduate</a> -->
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect>Postgraduate</a>
      </li> -->
      <li class="nav-item">
        <a routerLink="/screening" class="nav-link waves-light" mdbWavesEffect>Applications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect>Results</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect>Admission Process</a>
      </li>

      <!-- Dropdown -->
      <!-- <li class="nav-item dropdown" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                Basic dropdown<span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" mdbWavesEffect href="#">Action</a>
                    <a class="dropdown-item waves-light" mdbWavesEffect href="#">Another action</a>
                    <a class="dropdown-item waves-light" mdbWavesEffect href="#">Something else here</a>
                    <div class="divider dropdown-divider"></div>
                    <a class="dropdown-item waves-light" mdbWavesEffect href="#">Separated link</a>
                </div>
            </li> -->

    </ul>
    <!-- Links -->

  </links>
  <!-- Collapsible content -->

</mdb-navbar>

<div class="justify-content-between x-padding sub-header-bg navbar navbar-expand-lg navbar-dark">
  <ng-container *ngIf="isAuth; else notauth">


    <!-- <div class="d-flex">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect routerLink="/login" > Student Login </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" routerLink="/staff-login" mdbWavesEffect> Staff Login </a>
      </li>  
    </ul>
  </div> -->

    <div class="d-flex">
      <links>

        <!-- Links -->
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link waves-light" (click)="LogOut()" mdbWavesEffect> Logout </a>
          </li>
          <!-- <li class="nav-item">
          <a [routerLink]="[ '/login']" class="nav-link waves-light" mdbWavesEffect> Student Login </a>
        </li>
        <li class="nav-item">
            <a class="nav-link waves-light" mdbWavesEffect> FAQ </a>
          </li>        -->

        </ul>
        <!-- Links -->
      </links>
    </div>

  </ng-container>
  <ng-template #notauth>
    <div class="d-flex">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect routerLink="/login"> Student Login </a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light" routerLink="/staff-login" mdbWavesEffect> Staff Login </a>
        </li>
      </ul>
    </div>

    <div class="d-flex">
      <links>

        <!-- Links -->
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link waves-light" mdbWavesEffect> Basic Rules </a>
          </li>
          <li class="nav-item">
            <a [routerLink]="[ '/login']" class="nav-link waves-light" mdbWavesEffect> Student Login </a>
          </li>
          <li class="nav-item">
            <a class="nav-link waves-light" mdbWavesEffect> FAQ </a>
          </li>

        </ul>
        <!-- Links -->
      </links>
    </div>
  </ng-template>

</div>