


<div class="container my-5 pb-5">
    <div class="row">
      

        <div class="col-md-12">
            <div class="card">

                <form class="form-layout">
                    <div class="container">
                        <div class="d-flex justify-content-center pr-5 pb-2">
                            <p class="pb-3 pt-5"><span class="text-center instructions">Instructions:</span> Carefully select your outstanding courses. Check the button against any of your outstanding course(s) and click submit button to register selected courses.</p>
                        </div>
                    </div>

                    <div class="container px-4">
                        <div class="d-flex justify-content-between">
                            <p class="title">Engineering</p>
                            <p class="title">First Semester</p>
                            <p class="title">2020/2021</p>
                        </div>
                    </div>
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
                    
                        <!-- title Column -->
                        <ng-container matColumnDef="title">
                          <th mat-header-cell *matHeaderCellDef> Course Title </th>
                          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
                        </ng-container>
                    
                        <!-- code Column -->
                        <ng-container matColumnDef="code">
                          <th mat-header-cell *matHeaderCellDef> Course Code </th>
                          <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                        </ng-container>
                    
                        <!-- unit Column -->
                        <ng-container matColumnDef="unit">
                          <th mat-header-cell *matHeaderCellDef> Unit </th>
                          <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <div class="d-flex justify-content-end px-5 py-3">
                        <p>Total Unit: total  </p>
                    </div>
                    
        
                    <div class="p-4 d-flex justify-content-end">
                        <button mat-raised-button color="gray" class="py-2 px-3" onclick="window.print();">
                            <mat-icon aria-hidden="false" aria-label="print icon">print</mat-icon> <span class="px-1"></span> Print
                        </button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>



