import { Router } from "@angular/router";
import { StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { addFees, deleteFees, getFees, updateFees } from "../../actions/fees/adminData.fees.action";
import { AdminDataStateModel } from "../../models/adminData.model";
import { AdminDataService } from "../../services/admin-services/admin-data.service";



export class Fees {

    constructor(
        private adminDataService: AdminDataService,
        private _router: Router) {}

        
    getfees(ctx: StateContext<AdminDataStateModel>, action: getFees) {
        return this.adminDataService.getAllAdminData(action.payload,action.subUrl).pipe(
          tap((result: {
          //    name: string,
          //    date: string
           }) => {
            ctx.patchState({
              fees: result,
              
            });
          })
        );
      }


      updatefees(ctx: StateContext<AdminDataStateModel>, action: updateFees) {
        return this.adminDataService.updateAdminData(action.payload,action.subUrl).pipe(
          tap((result: {
          //    name: string,
          //    date: string
           }) => {
            ctx.patchState({
              fees: result,
              
            });
          })
        );
      }

      addfees(ctx: StateContext<AdminDataStateModel>, action: addFees) {
        return this.adminDataService.addAdminData(action.payload,action.subUrl).pipe(
          tap((result: {
          //    name: string,
          //    date: string
           }) => {
            ctx.patchState({
              fees: result,
              
            });
          })
        );
      }

      deletefees(ctx: StateContext<AdminDataStateModel>, action: deleteFees) {
        return this.adminDataService.deleteAdminData(action.payload,action.subUrl).pipe(
          tap((result: {
          //    name: string,
          //    date: string
           }) => {
            ctx.patchState({
              fees: result,
              
            });
          })
        );
      }

      
}