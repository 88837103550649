import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hostel-printout',
  templateUrl: './hostel-printout.component.html',
  styleUrls: ['./hostel-printout.component.scss']
})
export class HostelPrintoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
