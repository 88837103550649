import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Store } from '@ngxs/store';
import { Login, Logout } from 'src/app/shared/actions/auth.action';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  _loader = false;
    LoginData = {
      email:  <any> "",
      password: <any> ""  
      
     }

     alert = {
      title: <any> "",
      message: <any> "",
      icon: <any> "",
    }
   public  message: any =""
    validatingForm!: FormGroup;
  
    LoginButton: boolean = true;
    constructor(
      private _auth: AuthService,
      private _store: Store,
      private _router: Router){}
    
    ngOnInit() {
      this.validatingForm = new FormGroup({
        email: new FormControl(null, [Validators.required,Validators.email]),
        password: new FormControl(null, [Validators.required]),
        // password: new FormControl(null, [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'), Validators.minLength(8)]),
      }/*, { updateOn: 'submit' }*/);
    }
  
    // get input() { return this.validatingForm.get('email'); }
  
    get input(){
      return this.validatingForm.controls;
    }
  
    alertWithMessage(title:any,message:any,icon:any){
      Swal.fire({
        title: title,
        text: message,
        icon: icon,
      })
    }

    LoginUser(): void {
      this._loader = true

      // console.log(this.LoginData)
    this._store
      .dispatch(new Login(this.LoginData))
      .subscribe(
            res => {
              if(res.auth.token == undefined){
                this.alert.title = "Failed!"
                this.alert.message = res.auth.message 
                this.alert.icon = "error"
        
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
                this._loader = false
              }

              if(res.auth.token){
                
                this.alert.title = "Successful!"
                this.alert.message = res.auth.message 
                this.alert.icon = "success"
        
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
                this._router.navigate(['/dashboard'])
              }
              // localStorage.setItem('UserToken', res.auth.token)
              
              console.log(res)},
            err => console.log(err)
          );
  }
  
  LogOut(){
    this._store.dispatch(new Logout()).subscribe(
      success => {
        // localStorage.removeItem('UserToken');
       this._router.navigate(['/']);
      },
       error => {});
  }
  testCase(){
    alert(this.message)
  }

}
