
  
// STUDENT SUMMARY
export class getStudent {
    static readonly type = '[studentData] get Student';
    constructor(
      public payload: { role: string; user_id: string;},
      public subUrl: string
      ) {}
  }


  // STUDENT COURSES 
export class getStudentCourses {
  static readonly type = '[studentData] get StudentCourses';
  constructor(
    public payload: { role: string; dept_id: string; level_id: string;},
    public subUrl: string
    ) {}
}

// REGISTER COURSES 
export class registerStudentCourses {
  static readonly type = '[studentData] register StudentCourses';
  constructor(
    public payload: { 
      role: string; 
      dept_id: string; 
      level_id: string;
      student_id: string;
      courses_ids: string[];
      total_unit: number;
    },
    public subUrl: string
    ) {}
}

  
//  FEE-TYPE
export class getFeeType {
  static readonly type = '[studentData] get FeeType';
  constructor(
    public payload: { role: string; user_id: string;},
    public subUrl: string
    ) {}
}

//  PAYMENTS
export class getPayments {
  static readonly type = '[studentData] get Payments';
  constructor(
    public payload: { role: string; payment_id: string;},
    public subUrl: string
    ) {}
}

export class addPayment {
  static readonly type = '[studentData] add Payment';
  constructor(
    public payload: { },
    public subUrl: string
    ) {}
}

// HOSTEL CRUD
export class getHostel {
  static readonly type = '[studentData] get Hostels';
  constructor(
    public payload: { role: string;},
    public subUrl: string
    ) {}
}

// HOSTEL APPLICATION
export class addHostel {
  static readonly type = '[studentData] add Hostel';
  constructor(
    public payload: { },
    public subUrl: string
    ) {}
}

// HOSTEL ALLOCATION 
export class getHostelAllocation {
  static readonly type = '[studentData] get HostelAllocation';
  constructor(
    public payload: { },
    public subUrl: string
    ) {}
}

