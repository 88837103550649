<div class="container my-5 pb-5">
    
        <div class="overlay" *ngIf="_loader">
            <span   class="spinner-border spinner-border-xl text-primary preloader" role="status" aria-hidden="true"></span>

        </div>
        <!-- <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-white preloader" role="status" aria-hidden="true"></span>
        <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-secondary preloader" role="status" aria-hidden="true"></span> -->
       
    
    <div class="row">
       

        <div class="col-md-12">
            <div class="card">
                <div class="d-flex flex-row justify-content-around orange lighten-2 py-4">
                    <a style="font-weight: bold;" >Student Data </a>
                    <!-- <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg" alt="" height="70" class="rounded-circle z-depth-0">
                    <a class="waves-light" mdbWavesEffect>Edit Profile</a> -->
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-12 font-weight-bold text-danger">Please update your details below to complete your Application Process!</div>
                   
                </div>
                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Full Name</div>
                    <div class="col-4"> {{userDetails.first_name}} {{userDetails.mid_name}} {{userDetails.last_name}} </div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Matriculation No</div>
                    <div class="col-4">{{userDetails.reg_no}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Date of Birth</div>
                    <div class="col-4">{{userDetails.dob | date :'mediumDate'}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Email</div>
                    <div class="col-4">{{userDetails.email}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Country</div>
                    <div class="col-4">{{userDetails.country || "null"}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">State</div>
                    <div class="col-4">{{userDetails.state || "null"}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">City</div>
                    <div class="col-4">{{userDetails.city || "null"}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Phone</div>
                    <div class="col-4">{{userDetails.phone || "null"}}</div>
                </div>

                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">SSCE Result</div>
                    <!-- <div class="col-4">{{userDetails.course || "null"}}</div> -->
                    <div class="col-4">
                        <button (click)="ssce.show()" mdbBtn color="secondary" mdbWavesEffect>View</button>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Uploaded Cert.</div>
                    <!-- <div class="col-4">{{userDetails.course || "null"}}</div> -->
                    <div class="col-4">
                        <button (click)="cert.show()" mdbBtn color="secondary" mdbWavesEffect>View</button>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-center my-4">
                    <div class="col-4 font-weight-bold">Course Applied</div>
                    <div class="col-4">{{userDetails.course || "null"}}</div>
                </div>
                <div class="d-flex flex-row justify-content-center my-4">
                   
                    <div class="col-4">
                        <button [disabled]="" type="button" (click)="completeDate()" mdbBtn color="primary" mdbWavesEffect>Update Data</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #ssce="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">SSCE Results</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="ssce.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div *ngFor="let userDetail of userDetails.ssce_results" class="d-flex flex-row justify-content-center my-4">
            <div class="col-4">{{userDetail.subject || "null"}}</div>
            <div class="col-4">{{userDetail.grade || "null"}}</div>
        </div>


      </div>
      
    </div>
  </div>
</div>

<div mdbModal #cert="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Certificates uploaded</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cert.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
       

      </div>
    
    </div>
  </div>
</div>