<button mdbBtn color="default" (click)="add.show();" class="waves-light float-right" mdbWavesEffect>create Utme</button>
<mat-form-field>
  <div class="overlay" *ngIf="preloader">
    <span   class="spinner-border spinner-border-xl text-primary preloader" role="status" aria-hidden="true"></span>

</div>

    
    <mat-label>Search Table</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input style="width: 50%;">
  </mat-form-field>
  
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      
  
      <!-- S/N Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> S/N </th>
        <td mat-cell *matCellDef="let row; let i = index;"> {{i + 1}} </td>
      </ng-container>
  
     
  
      <!-- Code Column -->
      <ng-container matColumnDef="reg_no">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reg. No.</th>
        <td mat-cell *matCellDef="let row"> {{row.reg_no}} </td>
      </ng-container>

       <!-- Name Column -->
       <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
        <td mat-cell *matCellDef="let row"> {{row.first_name}} </td>
      </ng-container>
  
      <!-- Date Created Column -->
      <ng-container matColumnDef="mid_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Middle Name </th>
        <td mat-cell *matCellDef="let row" > {{row.mid_name}} </td>
      </ng-container>

       <!-- last name Column -->
       <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
        <td mat-cell *matCellDef="let row" > {{row.last_name}} </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Addmission Status </th>
        <td mat-cell *matCellDef="let row" > {{row.admission_status}} </td>
      </ng-container>

       <!-- Edit Column -->
       <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
        <td mat-cell *matCellDef="let row" > <mdb-icon (click)="edit.show(); editUtme(row);" fas icon="edit" class="prefix blue-text"></mdb-icon> </td>
      </ng-container>

       <!-- Delete Column -->
       <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete</th>
        <td mat-cell *matCellDef="let row" >  <mdb-icon (click)="confirmDelete(row)" fas icon="trash" class="prefix red-text"></mdb-icon> </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>

    </table>
  
    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>

  <div style="overflow-y: auto" mdbModal #add="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Add UTME</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="add.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">

        <div class="md-form mb-5">
          <form >
           <div class="row">
            <div class="md-form md-outline form-lg col-6"> 
              <input  [(ngModel)]="newData.reg_no" name="reg_no"  mdbValidation type="text" class="form-control py-2" mdbInput>
              <label for="title">Reg No.</label>
  
                 </div>
  
              <div class="md-form md-outline form-lg col-6"> 
                <input  [(ngModel)]="newData.first_name" name="first_name"  mdbValidation type="text" class="form-control py-2" mdbInput>
                <label for="code">First Name</label>
    
                </div>
           </div>

                <div class="row">
                  <div class="md-form md-outline form-lg col-6"> 
                    <input  [(ngModel)]="newData.last_name" name="last_name"  mdbValidation type="text" class="form-control py-2" mdbInput>
                    <label for="code">Last Name</label>
        
                    </div>
                    <div class="md-form md-outline form-lg col-6"> 
                      <input  [(ngModel)]="newData.mid_name" name="mid_name"  mdbValidation type="text" class="form-control py-2"  mdbInput>
                      <label for="code">Middle Name</label>
          
                      </div>
                </div>
                   <div class="row">
                    <div class="md-form md-outline form-lg col-6"> 
                      <input  [(ngModel)]="newData.email" name="email"  mdbValidation type="text" class="form-control py-2" mdbInput>
                      <label for="code">Email</label>
          
                      </div>

                      <div class="md-form md-outline form-lg col-6"> 
                        <input  [(ngModel)]="newData.dob" name="dob"  mdbValidation type="text" class="form-control py-2" mdbInput>
                        <label for="code">Birth Of Birth</label>
            
                        </div>
                   </div>

                   <div class="row">
                    <div class="md-form md-outline form-lg col-6"> 
                      <input  [(ngModel)]="newData.choice_institution_1" name="choice_institution_1"  mdbValidation type="text" class="form-control py-2" mdbInput>
                      <label for="code">First Choice Inst.</label>
          
                      </div>

                      <div class="md-form md-outline form-lg col-6"> 
                        <input  [(ngModel)]="newData.choice_course_1" name="choice_course_1"  mdbValidation type="text" class="form-control py-2" mdbInput>
                        <label for="code">First Course </label>
            
                        </div>
                   </div>

                   <div class="row">
                    <div class="md-form md-outline form-lg col-6"> 
                      <input  [(ngModel)]="newData.choice_institution_2" name="choice_institution_2"  mdbValidation type="text" class="form-control py-2" mdbInput>
                      <label for="code">Second Choice Inst.</label>
          
                      </div>

                      <div class="md-form md-outline form-lg col-6"> 
                        <input  [(ngModel)]="newData.choice_course_2" name="choice_course_2"  mdbValidation type="text" class="form-control py-2" mdbInput>
                        <label for="code">Second Course</label>
            
                        </div>
                   </div>
  
                
  
                      
  
                    
            </form>
        </div>
        <!-- <div  class="d-flex flex-row justify-content-center my-4">
            <div class="col-4">{{.name}}</div>
            
        </div> -->


      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="default" class="waves-light" (click)="addUtme()" mdbWavesEffect>Add</button>
      </div>
      
    </div>
  </div>
</div>


<div style="overflow-y: auto" mdbModal #edit="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Edit UTME</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="edit.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">

        <div class="md-form mb-5">
          <form >
           <div class="row">
            <div class="md-form md-outline form-lg col-6"> 
              <input  [(ngModel)]="newData.reg_no" name="reg_no"  mdbValidation type="text" class="form-control py-2" mdbInput>
              <label for="title">Reg No.</label>
  
                 </div>
  
              <div class="md-form md-outline form-lg col-6"> 
                <input  [(ngModel)]="newData.first_name" name="first_name"  mdbValidation type="text" class="form-control py-2" mdbInput>
                <label for="code">First Name</label>
    
                </div>
           </div>

                <div class="row">
                  <div class="md-form md-outline form-lg col-6"> 
                    <input  [(ngModel)]="newData.last_name" name="last_name"  mdbValidation type="text" class="form-control py-2" mdbInput>
                    <label for="code">Last Name</label>
        
                    </div>
                    <div class="md-form md-outline form-lg col-6"> 
                      <input  [(ngModel)]="newData.mid_name" name="mid_name"  mdbValidation type="text" class="form-control py-2"  mdbInput>
                      <label for="code">Middle Name</label>
          
                      </div>
                </div>
                   <div class="row">
                    <div class="md-form md-outline form-lg col-6"> 
                      <input  [(ngModel)]="newData.email" name="email"  mdbValidation type="text" class="form-control py-2" mdbInput>
                      <label for="code">Email</label>
          
                      </div>

                      <div class="md-form md-outline form-lg col-6"> 
                        <input  [(ngModel)]="newData.dob" name="dob"  mdbValidation type="text" class="form-control py-2" mdbInput>
                        <label for="code">Birth Of Birth</label>
            
                        </div>
                   </div>

                   <div class="row">
                    <div class="md-form md-outline form-lg col-6"> 
                      <input  [(ngModel)]="newData.choice_institution_1" name="choice_institution_1"  mdbValidation type="text" class="form-control py-2" mdbInput>
                      <label for="code">First Choice Inst.</label>
          
                      </div>

                      <div class="md-form md-outline form-lg col-6"> 
                        <input  [(ngModel)]="newData.choice_course_1" name="choice_course_1"  mdbValidation type="text" class="form-control py-2" mdbInput>
                        <label for="code">First Course </label>
            
                        </div>
                   </div>

                   <div class="row">
                    <div class="md-form md-outline form-lg col-6"> 
                      <input  [(ngModel)]="newData.choice_institution_2" name="choice_institution_2"  mdbValidation type="text" class="form-control py-2" mdbInput>
                      <label for="code">Second Choice Inst.</label>
          
                      </div>

                      <div class="md-form md-outline form-lg col-6"> 
                        <input  [(ngModel)]="newData.choice_course_2" name="choice_course_2"  mdbValidation type="text" class="form-control py-2" mdbInput>
                        <label for="code">Second Course</label>
            
                        </div>
                   </div>
  
                
  
                      
  
                    
            </form>
        </div>
        <!-- <div  class="d-flex flex-row justify-content-center my-4">
            <div class="col-4">{{.name}}</div>
            
        </div> -->


      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="default" class="waves-light" (click)="updateUtme()" mdbWavesEffect>Update</button>
      </div>
      
    </div>
  </div>
</div>

 
  