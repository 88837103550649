import { Router } from '@angular/router';
import { Injectable, NgZone } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { applicantLogin, AuthStateModel, Login, Logout } from "../actions/auth.action";
import { AuthService } from "../services/auth/auth.service";
import {tap} from 'rxjs/operators';


@State<AuthStateModel>({
    name: 'auth',
    defaults: {
      token: null,
      user_id: null,
      message: null,
      role: null,
      userinfo:null
      
    }
  })
  @Injectable()
  export class AuthState {
    @Selector()
    static token(state: AuthStateModel): string | null {
      return state.token;
    }
  
    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
      return !!state.token;
    }

    @Selector()
  static userDetails(state: AuthStateModel) {
    return {
      
      userRole: state.userinfo.role,
      userReg: state.userinfo.user_id
    };
  }

  @Selector()
  static admin(state: AuthStateModel) {
    return {
      
      role: state.role,
      
    };
  }

  @Selector()
  static student(state: AuthStateModel) {
    return {
      
      role: state.role,
      user_id : state.user_id
      
    };
  }


  @Selector()
  static applicant(state: AuthStateModel) {
    return {
      
      role: state.role,
      user_id : state.user_id
      
    };
  }
  
    constructor(
        private authService: AuthService,
        private _router: Router,
        private zone: NgZone
        ) {}
  
    @Action(Login)
    login(ctx: StateContext<AuthStateModel>, action: Login) {
      return this.authService.UserLogin (action.payload).pipe(
        tap((result: {
           token: string,
           message: string,
           role: string,
           user_id:string
         }) => {
          ctx.patchState({
            token: result.token,
            message: result.message,
            user_id: result.user_id,
            role:result.role
          });
        })
      );
    }

    @Action(applicantLogin)
    applicantlogin(ctx: StateContext<AuthStateModel>, action: applicantLogin) {
      return this.authService.applicatLogin (action.payload).pipe(
        tap((result: {
           token: string,
           message: string,
           role: string,
           user_id:string
         }) => {
          ctx.patchState({
            token: result.token,
            message: result.message,
            user_id: result.user_id,
            role:result.role
          });
        })
      );
    }
  
    @Action(Logout)
    logout({ setState, getState }: StateContext<AuthStateModel>) {
      this.zone.run(() => { this._router.navigate(['/']) });
    const { token } = getState();
    return this.authService.LogOut().pipe(
      tap(_ => {
        setState({
            token: null,
          user_id: null,
          message: null,
          role: null,
          userinfo:null
        });
      })
    );
  }
  }