<p>{{LoginData.email}}</p>
<input [(ngModel)]="LoginData.email" type="text">


<div>
    <button (click)="showstate()">show</button>
    <p *ngIf="gallerystate">me</p>
</div>


<div>
    <div>[{{data}}]</div>
  <input [(ngModel)]="groupSize" data-test="group-size" (change)="changedData(groupSize)"> 
    <ul data-test="groups"></ul>
    <div data-test="group-totals"></div>
  </div>