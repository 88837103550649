import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { addHostel } from 'src/app/shared/actions/studentData.action';
import { AdminDataService } from 'src/app/shared/services/admin-services/admin-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-hostel-payment',
  templateUrl: './hostel-payment.component.html',
  styleUrls: ['./hostel-payment.component.scss']
})
export class HostelPaymentComponent implements OnInit {
payloadData = <any> {}
preloader = false;
  _loader = false;
alert = {
  title: <any> "",
  message: <any> "",
  icon: <any> "",
}
  constructor(
    
      private _store: Store,
      private adminService: AdminDataService,
      private http: HttpClient,
    
    private dialogRef: MatDialogRef<HostelPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.payloadData = data
    // console.log(this.payloadData);
    
   }

  ngOnInit(): void {
  }

  alertWithSuccess(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

  applyForHostel(){
    const URL = 'api/hostel-allocation/store'
    
    this._store
    .dispatch(new addHostel(this.payloadData,URL)) 
  .subscribe(
  res=> {
  console.log(res)
  
  if(res.studentData.hostelAllocation.message == "Authentication Failed!"){
          
  this.alert.title = "Session Timed out!"
  this.alert.message = res.studentData.hostelAllocation.message
  this.alert.icon = "error"
  
  this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
  this.preloader = false;
  
  }else {
    
    
    
  this.preloader = false;
  }
  
  },
  err=> {
    console.log(err)
    this.alert.title = "Server Error!"
    this.alert.message = `There was an error processing this request`
    this.alert.icon = "error"
  
    this.alertWithSuccess(this.alert.title, this.alert.message, this.alert.icon)
    this.preloader = false;
  }
  )



  }

}
