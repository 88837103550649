import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { StateClear, StateReset, StateResetAll } from 'ngxs-reset-plugin';
import { Logout } from '../../actions/auth.action';
import { AuthService } from '../../services/auth/auth.service';
import { NavigationService } from '../../services/navigation.service';
import { AdminDataState } from '../../state/adminData.state';
import { AuthState } from '../../state/auth.state';
import { StudentDataState } from '../../state/studentData.state';
import { UtmeState } from '../../state/utme.state';

@Component({
  selector: 'app-admin-dashboard-navbar',
  templateUrl: './admin-dashboard-navbar.component.html',
  styleUrls: ['./admin-dashboard-navbar.component.scss']
})
export class AdminDashboardNavbarComponent implements OnInit {

  constructor(
    private navService: NavigationService,
    private _auth: AuthService,
    private _store: Store,
    private _router: Router,
    private zone: NgZone
    ) { }

  ngOnInit(): void {
  }

  toggleSideNav() {
    this.navService.setShowNav(true);
  }
  LogOut(){
    // this._auth.LogOut()
    this._store.dispatch(
      // new StateClear()
      // new StateResetAll()
     new  StateReset(AuthState,UtmeState,AdminDataState,StudentDataState)

      
    );
     this._router.navigateByUrl('/');
    // this.zone.run(() => { this._router.navigate(['/']) });
  }

}
