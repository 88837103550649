import { ProfileComponent } from './../../dashboard/profile/profile.component';
import { DashboardFooterComponent } from './../../shared/component/dashboard-footer/dashboard-footer.component';
import { DashboardNavbarComponent } from './../../shared/component/dashboard-navbar/dashboard-navbar.component';
import { DashboardComponent } from './dashboard.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DashboardSidebarComponent } from 'src/app/shared/component/dashboard-sidebar/dashboard-sidebar.component';
import { CourseRegistrationComponent } from 'src/app/dashboard/course-registration/course-registration.component';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { CoursesSelectedComponent } from 'src/app/dashboard/courses-selected/courses-selected.component';
import { CourseFormComponent } from 'src/app/dashboard/course-form/course-form.component';
import { PaymentDialogComponent } from 'src/app/dashboard/payment-dialog/payment-dialog.component';
import { SchoolFeePaymentComponent } from 'src/app/dashboard/school-fee-payment/school-fee-payment.component';
import { HostelFeePaymentComponent } from 'src/app/dashboard/hostel/hostel-fee-payment/hostel-fee-payment.component';
import { HostelApplicationComponent } from 'src/app/dashboard/hostel/hostel-application/hostel-application.component';
import { HostelFormComponent } from 'src/app/dashboard/hostel/hostel-form/hostel-form.component';
import { HostelPreviewComponent } from 'src/app/dashboard/hostel/hostel-preview/hostel-preview.component';
import { HostelPrintoutComponent } from 'src/app/dashboard/hostel/hostel-printout/hostel-printout.component';
import { ResultRequestComponent } from 'src/app/dashboard/results/result-request/result-request.component';
import { DisplayResultsComponent } from 'src/app/dashboard/results/display-results/display-results.component';
import { ClearanceDialogComponent } from 'src/app/dashboard/clearance/clearance-dialog/clearance-dialog.component';
import { ClearanceAvailableComponent } from 'src/app/dashboard/clearance/clearance-available/clearance-available.component';
import { HostelClearanceComponent } from 'src/app/dashboard/clearance/hostel-clearance/hostel-clearance.component';
import { ClearanceApplicationComponent } from 'src/app/dashboard/clearance/clearance-application/clearance-application.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RegisteredCoursesComponent } from 'src/app/dashboard/registered-courses/registered-courses.component';
import { HostelPaymentComponent } from 'src/app/dashboard/hostel-payment/hostel-payment.component';


@NgModule({
  declarations: [
    DashboardComponent,
    DashboardNavbarComponent,
    DashboardSidebarComponent,
    DashboardFooterComponent,
    ProfileComponent,
    CourseRegistrationComponent,
    CoursesSelectedComponent,
    CourseFormComponent,
    PaymentDialogComponent,
    SchoolFeePaymentComponent,
    HostelFeePaymentComponent,
    HostelFormComponent,
    HostelApplicationComponent,
    
    HostelPreviewComponent,
    HostelPrintoutComponent,
    ResultRequestComponent,
    DisplayResultsComponent,
    ClearanceAvailableComponent,
    ClearanceDialogComponent,
    HostelClearanceComponent,
    ClearanceApplicationComponent,
    RegisteredCoursesComponent,
    HostelPaymentComponent,
    
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule, 
    MatAutocompleteModule, 
    MatSortModule,
    MatPaginatorModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class DashboardModule { }
